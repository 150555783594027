import React from 'react';
import { Checkbox, TableCell, useTheme } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import { Info } from '@material-ui/icons';

export default function CustomTableBody(props) {
	const theme = useTheme();
	return (
		<TableBody>
			{props.rows
				.slice(props.page * props.rowsPerPage, props.page * props.rowsPerPage + props.rowsPerPage)
				.map((row, i) => {
					return (
						<TableRow
							style={{ cursor: props.clickable && 'pointer' }}
							hover
							onClick={() => {
								if (props.clickable && props.onRowClick) props.onRowClick(row.id);
							}}
							role="checkbox"
							className="cursor-pointer relative"
							// style={{ background: i % 2 === 0 && lighten(theme.palette.primary.light, 0.5) }}
							tabIndex={-1}
							key={`tableBody${i}`}
						>
							{/* <TableCell align="left"> */}
							{/*	<Checkbox */}
							{/*		size="small" */}
							{/*		checked={props.checkedElements.findIndex(e => e === row.id) > -1 || false} */}
							{/*		onChange={(event, checked) => { */}
							{/*			if (checked) { */}
							{/*				const temp = [...props.checkedElements]; */}
							{/*				const index = temp.findIndex(e => e === row.id); */}
							{/*				if (index === -1) { */}
							{/*					temp.push(row.id); */}
							{/*					props.setCheckedElements(temp); */}
							{/*				} */}
							{/*			} else { */}
							{/*				const temp = [...props.checkedElements]; */}
							{/*				const index = temp.findIndex(e => e === row.id); */}
							{/*				if (index > -1) { */}
							{/*					temp.splice(index, 1); */}
							{/*					props.setCheckedElements(temp); */}
							{/*				} */}
							{/*			} */}
							{/*		}} */}
							{/*		className="small-check" */}
							{/*	/> */}
							{/* </TableCell> */}
							{props.columns.map((column, index) => {
								const value = row[column.id];
								return (
									<TableCell
										key={column.id}
										align={column.align}
										style={{
											maxWidth: column.maxWidth,
											minWidth: column.id === 'action' && 220,
											position: 'relative',
											paddingLeft: index === 0 && props.extradata && 28
										}}
									>
										{index === 0 && props.extradata && (
											<Info
												color="primary"
												onMouseEnter={e => {
													props.setAnchorEl(e.currentTarget);
													props.setPopoverData(row.extradata);
												}}
												style={{ position: 'absolute', left: 6 }}
											/>
										)}
										{column.format && typeof value === 'number' ? column.format(value) : value}
									</TableCell>
								);
							})}
						</TableRow>
					);
				})}
		</TableBody>
	);
}
