import React, { useEffect, useState } from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Button, Checkbox, TableCell, TextField, Tooltip } from '@material-ui/core';
import { DeleteOutlined } from '@material-ui/icons';
import TableSortLabel from '@material-ui/core/TableSortLabel';

export default function CustomTableHead(props) {
	const [filter, setFilter] = useState({});
	useEffect(() => {
		const temp = props.filter || {};
		setFilter(temp);
	}, [props.filter]);

	useEffect(() => {
		if (props.resetOrder) {
			props.resetOrder();
		}
		const temp = props.filter || {};
		if (window.location.hash === '#deceased' && (!temp.name || temp.name === '')) {
			temp.name = window?.history?.state?.deceasedName || filter.name || '';
		}
		if (
			window.location.pathname === '/partner-panel' &&
			window.location.hash === '#qrcode' &&
			(!temp.code || temp.code === '')
		) {
			temp.code = window?.history?.state?.deceasedIds?.toString() || filter.code || '';
		}
		setFilter(temp);
	}, [window?.history?.state?.deceasedName, window?.history?.state?.deceasedIds]);

	useEffect(() => {
		if (props.resetOrder) {
			props.resetOrder();
		}
		if (props.setFilter && !window?.history?.state?.deceasedName && !window?.history?.state?.deceasedIds) {
			const temp = {};
			setFilter(props.initFilter);
			props.setFilter(props.initFilter);
		}
	}, [window.location.hash]);
	return (
		<TableHead>
			<TableRow>
				{/* <TableCell> */}
				{/*	/!*{!props.isFilter ? (*!/ */}
				{/*	/!*	<Tooltip title="Seleziona tutti">*!/ */}
				{/*	/!*		<Checkbox*!/ */}
				{/*	/!*			className="small-check"*!/ */}
				{/*	/!*			checked={props.data?.length === props.checkedElements?.length}*!/ */}
				{/*	/!*			onChange={(event, checked) => {*!/ */}
				{/*	/!*				if (checked) {*!/ */}
				{/*	/!*					props.selectAll();*!/ */}
				{/*	/!*				} else {*!/ */}
				{/*	/!*					props.unSelectAll();*!/ */}
				{/*	/!*				}*!/ */}
				{/*	/!*			}}*!/ */}
				{/*	/!*		/>*!/ */}
				{/*	/!*	</Tooltip>*!/ */}
				{/*	/!*) : (*!/ */}
				{/*	/!*	<div className="px-4 relative min-w-24">*!/ */}
				{/*	/!*		{props.checkedElements?.length}*!/ */}
				{/*	/!*		{props.checkedElements?.length > 0 && (*!/ */}
				{/*	/!*			<DeleteOutlined*!/ */}
				{/*	/!*				onClick={props.deleteAllOperation}*!/ */}
				{/*	/!*				className="absolute right-0 top-0 cursor-pointer"*!/ */}
				{/*	/!*				color="error"*!/ */}
				{/*	/!*			/>*!/ */}
				{/*	/!*		)}*!/ */}
				{/*	/!*	</div>*!/ */}
				{/*	/!*)}*!/ */}
				{/* </TableCell> */}
				{props.columns.map(column => (
					<TableCell
						key={column.id}
						align={column.align}
						style={{ minWidth: column.minWidth, maxWidth: column.maxWidth }}
					>
						{!props.isFilter &&
							(column.id === 'id' ||
							column.id === 'active' ||
							column.id === 'action' ||
							column.id === 'checkbox' ? (
								column.label
							) : (
								<TableSortLabel
									active={props.orderBy === column.id}
									direction={props.orderBy === column.id ? props.order : 'asc'}
									onClick={props.createSortHandler(column.id)}
								>
									{column.label}
								</TableSortLabel>
							))}
						{props.isFilter &&
							column.id !== 'action' &&
							column.id !== 'active' &&
							column.id !== 'checkbox' &&
							(!props.partner || (column.id !== 'qrcode' && column.id !== 'qrcodeB')) && (
								<TextField
									className="padding-child-5 no-label"
									value={
										filter[column?.id] && filter[column?.id].toString() === '[object Object]'
											? ''
											: filter[column?.id] || ''
									}
									variant="outlined"
									label=""
									onChange={e => {
										const temp = { ...(props.filter || {}) };
										temp[column.id] = e.target.value;

										props.setFilter(temp);
									}}
									style={{ minWidth: column.minWidth, maxWidth: column.maxWidth, padding: 0 }}
									placeholder={column.label}
								/>
							)}
						{column.id === 'action' && Object.keys(props.filter || {}).length > 0 && (
							<Button
								variant="contained"
								color="primary"
								style={{ color: 'black' }}
								onClick={() => props.resetFilter()}
							>
								MOSTRA TUTTO
							</Button>
						)}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}
