import React, { useState } from 'react';
import FeedbackModal from './FeedbackModal';

const MAX_SIZE = 15000000;
const ACCEPTED_FORMAT = ['.png', '.jpeg', '.jpg', '.bmp', '.gif'];

function checkFormat(string, array) {
	let check = false;
	for (const i in array || ACCEPTED_FORMAT) {
		check = check || string.toLowerCase().includes(ACCEPTED_FORMAT[i].toLowerCase());
	}
	return check;
}

export default function PhotoInput(props) {
	const accepted = props.accept || ACCEPTED_FORMAT;
	const [modal, setModal] = useState(null);
	return (
		<>
			{modal && <FeedbackModal result="Errore" close={() => setModal(null)} label={modal} />}
			<input
				type="file"
				id={`photo-${props.name}`}
				name={`photo-${props.name}`}
				// value={this.state.file}
				onChange={event => {
					const check = checkFormat(event.target.files[0].name, accepted);
					if (check) {
						if (event.target.files[0].size > MAX_SIZE) {
							setModal('La dimensione massima della foto e` di 15MB');
							document.getElementById(`photo-${props.name}`).value = null;
							props.setFileName('');
							// this.setState({filename: ''})
						} else {
							if (props.addImage) {
								props.sendFiles();
							}
							props.setFileName(event.target.files[0].name, event.target.files[0]);
						}
					} else {
						// alert('Formato immagine non valido');
						setModal(
							<p>
								Formato immagine non valido,{' '}
								<p>
									Formati supportati: <strong>{accepted.join(', ')}</strong>
								</p>
							</p>
						);
						document.getElementById(`photo-${props.name}`).value = null;
						props.setFileName('');
					}
					// this.setState({filename: event.target.files[0].name})
				}}
				accept="image/png, image/jpeg, image/gif"
				style={{ display: 'none' }}
			/>
		</>
	);
}
