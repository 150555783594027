import React from 'react';
import AddDeceasedPoster from './PartnerPanelPages/AddDeceasedPoster';

let close = true;
export default function ModifyObituaryData(props) {
	return (
		<div
			className="bg-modal"
			style={{ overflow: 'auto', padding: 40 }}
			onClick={() => {
				close ? props.closeHandler() : (close = true);
			}}
		>
			<div
				onClick={() => {
					close = false;
				}}
				style={{ height: '100%' }}
			>
				<AddDeceasedPoster modify={props.data || true} />
			</div>
		</div>
	);
}
