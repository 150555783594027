import React, { Component } from "react";
import "../../Activate/Activate.css";
import TextField from "@material-ui/core/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete';
import FeedbackModal from "../../FormComponents/FeedbackModal";
import requests from "../../../Services/HttpServices";
import validate from "../../../formValidationService";

class AddLampadaPoster extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			loggedUser: null,
			form: {
				idDefunto: "",
				idAbbonato: ""
			},
			sDefunti: [],
			sAbbonati: [],
			formError: {},
		};
		this.searchDefunti("");
		this.searchAbbonati("");
	}

	searchDefunti(input) {
		const bodyData = {"searchInput": input};
		requests("post", "lampadaSearchDefunti", bodyData).then((result) => {
			if (result.result === "success") {
				let sDefunti = [];
				for (let defunto of result.defunti) {
					sDefunti.push({ label: `${defunto.name} ${defunto.surname}, ${defunto.ID}`, id: defunto.ID })
				}
				this.setState({ sDefunti: sDefunti });
			}
			else {
				this.setState({ sDefunti: [] });
			}
		});
	}

	searchAbbonati(input) {
		const bodyData = {"searchInput": input};
		requests("post", "lampadaSearchAbbonati", bodyData).then((result) => {
			if (result.result === "success") {
				let sAbbonati = [];
				for (let abbonato of result.lampadaAbbonati) {
					sAbbonati.push({ label: `${abbonato.nome} ${abbonato.cognome}, ${abbonato.mail}`, id: abbonato.idAbbonato })
				}
				this.setState({ sAbbonati: sAbbonati });
			}
			else {
				this.setState({ sAbbonati: [] });
			}
		});
	}

	render() {
		if (!this.state.loggedUser && sessionStorage.getItem("User")) {
			try {
				this.setState({
					loggedUser: JSON.parse(sessionStorage.getItem("User")).userdata,
				});
			} catch (e) { }
		}
		return (
			<>
				<div className="contact-container" style={{ height: "inherit" }}>
					{this.state.feedbackModal && (
						<FeedbackModal
							result={this.state.modalResult}
							close={() => {
								this.setState({ feedbackModal: false });
								if (this.state.modalResult === "Successo") {
									window.history.pushState("object or string", "Title", "/lampade-panel#lampade");
									window.location.reload();
								}
							}}
							label={this.state.modalText}
						/>
					)}

					<div className="activate-form-div" style={{ marginTop: 30, minHeight: "auto" }}>
						<form
							className="activate-form"
							onSubmit={(event) => {
								event.preventDefault();
								this.send();
							}}
						>
							<label className="death-data">Aggiungi Lampada</label>

							<Autocomplete
								className="activate-field"
								name="idDefunto"
								options={this.state.sDefunti}
								getOptionLabel={(option) => option.label || ""}
								isOptionEqualToValue={(option, value) => option.id === value}
								renderInput={(params) => <TextField {...params} error={this.state.formError.idDefunto} helperText={this.state.formError.idDefunto && "Campo obbligatorio"} label="Scegli il Defunto" />}
								onChange={(event, value) => { this.setAndValidateForm(value?.id || "", "idDefunto"); }}
								onInputChange={(event, input) => { if (this.state.form.idDefunto != undefined || input.length < 1) { this.searchDefunti(input); } }}
							/>
							<Autocomplete
								className="activate-field"
								name="idAbbonato"
								options={this.state.sAbbonati}
								getOptionLabel={(option) => option.label || ""}
								isOptionEqualToValue={(option, value) => option.id === value}
								renderInput={(params) => <TextField {...params} error={this.state.formError.idAbbonato} helperText={this.state.formError.idAbbonato && "Campo obbligatorio"} label="Scegli l'Abbonato" />}
								onChange={(event, value) => { this.setAndValidateForm(value?.id || "", "idAbbonato"); }}
								onInputChange={(event, input) => { if (this.state.form.idAbbonato != undefined || input.length < 1) { this.searchAbbonati(input); } }}
							/>

							<div className="bottom-activate-form">
								<button type="submit" onClick={(event) => { }} className="activate-submit">Procedi</button>
							</div>
						</form>
					</div>
				</div>
			</>
		);
	}

	send() {
		let { formError } = this.state;
		let error = false;
		for (const property in this.state.form) {
			formError = validate(this.state.form[property], property, formError);
			error = error || formError[property];
		}

		if (!error) {
			const bodyData = {
				"idDefunto": parseInt(this.state.form.idDefunto, 10),
				"idAbbonato": parseInt(this.state.form.idAbbonato, 10),
				"idGestore": parseInt(this.state.loggedUser.user_id, 10),
			};

			requests("post", "lampadaCreate", bodyData).then((data) => {
				if (data?.result === "success") {
					this.setState({
						feedbackModal: true,
						modalResult: "Successo",
						modalText: "Lampada aggiunta con successo, ricordati di aggiungere un pagamento per attivarla.",
					});
				}
				else {
					this.setState({
						feedbackModal: true,
						modalResult: "Successo",
						modalText: "Errore nella creazione della lampada, riprova.",
					});
				}
			});
		}
		else { this.setState({ errore: true }); }
	}

	setAndValidateForm(data, attribute) {
		const { form } = this.state;
		let { formError } = this.state;
		form[attribute] = data;
		this.setState({ form });
		formError = validate(data, attribute, formError);
		this.setState({ formError });
	}
}

export default AddLampadaPoster;
