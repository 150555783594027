/* eslint-disable react/no-access-state-in-setstate,no-shadow,jsx-a11y/label-has-associated-control,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import { Redirect } from "react-router";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Axios from "axios";
import { Spinner } from "react-activity";
import { Typography } from "@material-ui/core";
import listner from "reactjs-eventemitter";
import { RemoveCircleOutline } from "@material-ui/icons";
import withContext from "../../WithContextFile";
import validate, { locationValidation } from "../../formValidationService";
import addressServiceGetData from "../FormComponents/addressService";
import UserFormData from "../FormComponents/userFormData";
import AddressService, { buildCap } from "../FormComponents/AddressComponent";
import ContactPreferenceForm from "../FormComponents/ContactPreferencesForm";
import FormLabel from "../FormComponents/TermFormComponents";
import PaymentModal from "../PaymentModal/PaymentModal";
import VideoInput from "../FormComponents/VideoInput";
import PhotoInput from "../FormComponents/PhotoInput";
import SearchBar from "./SearchBar";
import requests, { baseURL } from "../../Services/HttpServices";
import ExistsModal from "../FormComponents/ExistsModal";
import CkEditorCustom from "../FormComponents/CkEditor";
import FeedbackModal from "../FormComponents/FeedbackModal";
import UsernameField from "../FormComponents/UsernameField";
import { readURL } from "../FormComponents/FileNamesComponent";
import AcceptBuyTerms from "../FormComponents/AcceptBuyTerms";
import DedicNotifyFrequency from "./DedicNotifyFrequency";

let closeModalFixed = false;
let regionClicked   = null;
let purchaseType    = null;
let searchParams    = null;
let label           = null;

class MemorialActivate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			buyTermsError : false,
			buyTerms      : false,
			modal         : false,
			radioValue    : "",
			loggedUser    : null,
			productPrice  : "",
			form          : {
				name             : "",
				surname          : "",
				email            : "",
				phone            : "",
				CF               : "",
				route            : "",
				civic            : "",
				city             : "",
				province         : "",
				region           : "",
				nameTo           : "",
				surnameTo        : "",
				username         : "",
				cap              : "",
				nationality      : "105",
				complete_address : "",
			},
			formError: {},
		};
	}

	componentDidMount() {
		[...document.getElementById("appBody").children].map((e) => {
			if (
				e.src?.indexOf("paypal") >= 0
				&& e.src
				!== "https://www.paypal.com/sdk/js?client-id=AVfuTYt59be7cdzYndbKA-64V-L1ALSzzwX3FRfI39COtKTP7SfOwkmrVfS2KAVTMwSGHbGDY6MNrkiF&currency=EUR"
			) {
				const temp = e;
				console.log("trovato lo script adesso lo levo");
				document.getElementById("appBody")
					.removeChild(e);
				temp.src = "https://www.paypal.com/sdk/js?client-id=AVfuTYt59be7cdzYndbKA-64V-L1ALSzzwX3FRfI39COtKTP7SfOwkmrVfS2KAVTMwSGHbGDY6MNrkiF&currency=EUR";
				document.getElementById("appBody")
					.append(temp);
			}
		});
		listner.events.upload2 = [];
		listner.events.upload2.length < 1
		&& listner.subscribe("upload2", (callback) => {
			this.setState({ percentage: callback });
		});
		getCsrf()
			.then((csrf) => {
				this.setState({ csrf_token: csrf });
			});
		searchParams = this.props.history?.location?.search?.slice(1, this.props.history.location.search.length);
		if (searchParams) {
			if (searchParams.indexOf("&") >= 0) {
				const tempPurchase = searchParams.split("&")[0];
				const tempDeceased = searchParams.split("&")[1];
				purchaseType       = tempPurchase;
				this.setState({ deceasedID: tempDeceased });
			} else {
				purchaseType = searchParams;
			}
		}
		if (purchaseType) {
			label = purchaseType.replace("-", " e ");
			requests("get", "getProductPrice", purchaseType)
				.then((data) => {
					if (data && data.data && data.data.product_details) {
						this.setState({ productPrice: data.data.product_details.price });
					} else {
						this.props.history.push("/pricing");
					}
				});
		}
		regionClicked = this.props.history.location.query && this.props.history.location.query.region;
		!purchaseType && this.props.history.push("/pricing");
	}

	setInitialData() {
		const temp = this.state.form;

		temp.name             = this.state.loggedUser.name;
		temp.username         = this.state.loggedUser.username;
		temp.surname          = this.state.loggedUser.surname || "Cognome";
		temp.email            = this.state.loggedUser.email;
		temp.phone            = this.state.loggedUser.telephone;
		temp.CF               = this.state.loggedUser.cf || this.state.loggedUser.CF || null;
		temp.route            = this.state.userAddress?.address;
		temp.civic            = this.state.userAddress?.number;
		temp.city             = this.state.userAddress?.city;
		temp.province         = this.state.userAddress?.province;
		temp.cap              = this.state.userAddress?.cap;
		temp.region           = this.state.userAddress?.region;
		temp.nationality      = this.state.userAddress?.nationality || "105";
		temp.complete_address = this.state.userAddress?.complete_address;

		let radioContact;
		try {
			radioContact = this.state.loggedUser.preferred_contact.type;
		} catch (e) {
			radioContact = "";
		}

		this.setState({
			form       : temp,
			radioValue : radioContact,
		});
	}

	setAndValidateForm(data, attribute) {
		const { form }    = this.state;
		let { formError } = this.state;
		form[attribute]   = data;
		this.setState({ form });
		formError = validate(data, attribute, formError);
		this.setState({ formError });
	}

	send(formdata) {
		let { formError } = this.state;
		let error         = false;

		for (const property in this.state.form) {
			if (this.state.loggedUser && property === "email") {
			} else if (property !== "dedic") {
				if (
					(property !== "username" || this.state.register)
					&& !this.state.formError.username
					&& (property !== "phone" || (this.state.form.phone && this.state.form.phone.length >= 9))
					&& (property !== "email" || this.state.form.email !== "")
					&& (property !== "CF" || `${this.state.form.nationality}` === "105")
				) {
					if (
						!(property === "address" || property === "location" || property === "complete_address")
						|| `${this.state.form.nationality}` !== "105"
					) {
						formError = validate(this.state.form[property], property, formError);

						error = error || formError[property];
					}
				}
			}
		}
		formError = locationValidation(this.state.form, formError);
		if (`${this.state.loggedUser?.platformAccess}` === "2") {
			formError.CF = this.state.form.CF !== "" && !(this.state.form.CF?.length === 16);
			error        = error || formError.CF;
		} else {
			formError.CF = this.state.form.CF?.length !== 16;
			error        = error || formError.CF;
		}
		if (this.state.register) {
			error = error || formError.username;
		}
		this.setState({ formError });

		if (!this.state.radioValue) {
			this.setState({ radioError: true });
		} else {
			this.setState({ radioError: false });
		}

		this.setState({ termsError: !this.state.checkBox });

		if (purchaseType.indexOf("foto") > 0) {
			error = error || !this.state.fileName;
			this.setState({ photoError: !this.state.fileName });
		}
		if (purchaseType.indexOf("video") > 0) {
			error = error || !this.state.videoName;
			this.setState({ videoError: !this.state.videoName });
		}
		let contactError = false;
		if (
			this.state.form.phone.length < 6
			&& (!this.state.form.email || this.state.form.email === "")
			&& !this.state.loggedUser
		) {
			contactError      = true;
			formError.account = true;
		}
		let buyTermError = false;
		if (!this.state.buyTerms) {
			buyTermError = true;
			this.setState({ buyTermsError: true });
		}
		if (!error && !contactError && !!this.state.radioValue && !!this.state.checkBox && !buyTermError) {
			if (!this.state.form.phone) {
				formdata.delete("phone");
				formdata.delete("buy-telephone");
			}
			getCsrf()
				.then((csrf) => {
					this.setState({ csrf_token: csrf });
					formdata.set("csrf_token", csrf);
					this.setState({ uploading: true });
					requests("sendform", "dedic", formdata)
						.then((data) => {
							this.setState({ uploading: false });
							if (data.response !== "fail") {
								this.setState({ modal: true });
								this.setState({ orderId: data.data.orderid });
							} else {
								this.setState({
									feedbackModal : true,
									modalResult   : "Errore",
									modalText     : data.message,
								});
							}
						});
				});
		} else if ((error || contactError) && !this.state.loggedUser) {
			console.log({
				error,
				contactError,
				buyTermError,
			});
			document.getElementById("user-form-data")
				.scrollIntoView();
		}
		// this.setState({modal: true})
	}

	ckEditor() {
		return (
			<CkEditorCustom
				dedic
				withPlain
				title="Dedica da lasciare"
				onChange={(editor) => {
					const temp = this.state.form;
					temp.dedic = editor.getData();
					this.setState({ form: temp });
				}}
				data={this.state.form.dedic}
				error={this.state.formError.dedic}
			/>
		);
	}

	render() {
		try {
			if (!this.state.loggedUser && sessionStorage.getItem("User")) {
				this.setState({
					loggedUser: JSON.parse(sessionStorage.getItem("User")).userdata,
				});
				this.setState({
					userAddress: JSON.parse(sessionStorage.getItem("UserAddress")),
				});
			}
		} catch (e) {

		}
		if (this.state.loggedUser && this.state.form.name == "") {
			this.setInitialData();
			this.state.userAddress?.region
			&& addressServiceGetData("province", this.state.userAddress.region)
				.then((resolve) => {
					this.setState({ provincie: resolve });
					addressServiceGetData("location", this.state.userAddress.province)
						.then((resolve) => {
							const locSelected = resolve.find(
								(x) => x.ID === (this.state.form.location || this.state.form.city),
							);
							let capArr        = [];
							if (locSelected.capList) capArr = buildCap(locSelected);
							capArr.unshift(locSelected.cap);
							this.setState({
								comuni : resolve,
								caps   : capArr,
							});
						});
				});
		}
		if (this.props.history.location.query && this.props.history.location.query.region && !regionClicked) regionClicked = this.props.history.location.query && this.props.history.location.query.region;
		// if (this.props.history.location.query && this.props.history.location.query.deceasedID && !deceasedID)
		//     deceasedID = this.props.history.location.query && this.props.history.location.query.deceasedID
		return !this.props.context.user ? (
			<>
				{this.state.uploading
					&& purchaseType
					&& (purchaseType.indexOf("foto") > 0 || purchaseType.indexOf("video") > 0) && (
						<div className="div-upload">
							<Spinner size={100} />
							<Typography className="label-upload">
								Attendi il caricamento dei files
								{" "}
								{parseInt(parseFloat(this.state.percentage) * 100, 10)}
								%
							</Typography>
						</div>
					)}
				<div className="contact-container overflow-x-hidden">
					{this.state.feedbackModal && (
						<FeedbackModal
							result={this.state.modalResult}
							close={() => {
								this.setState({ feedbackModal: false });
							}}
							label={this.state.modalText}
						/>
					)}
					<div className="memorial-container memorial-container-activate">
						&nbsp;
						<div className="activate-form-div">
							<label className="contact-title">
								Modulo d'ordine
								{purchaseType && label}
							</label>
							<form
								className="activate-form"
								onSubmit={(event) => {
									event.preventDefault();
									const formData = new FormData(event.target);
									const object   = {};
									formData.forEach((value, key) => {
										object[key] = value;
									});
									this.send(formData);
								}}
							>
								<label className="buy-form-paragraph">Dati richiedente</label>
								<UserFormData
									partnerModify={`${this.state.loggedUser?.platformAccess}` === "2"}
									notusername
									nationalityChange={() => {
										this.setState({ reload: true });
									}}
									load={() => {
										this.setState({ load: true });
									}}
									openModalEmail={(data) => {
										this.setState({
											existModal : true,
											modalname  : data,
										});
									}}
									names="buy"
									form={this.state.form}
									formError={this.state.formError}
									disable={this.state.loggedUser !== null}
								/>
								{this.state.loggedUser && (
									<div style={{ display: "none" }}>
										<UserFormData
											nationalityChange={() => {
												this.setState({ reload: true });
											}}
											names="buy"
											form={this.state.form}
											formError={this.state.formError}
											// phoneError={this.state.contactError}
										/>
									</div>
								)}
								<label className="buy-form-paragraph">Dati indirizzo</label>
								<AddressService
									nationality={this.state.form.nationality || "105"}
									names="buy"
									// loggedUser={this.state.loggedUser}
									form={this.state.form}
									formError={this.state.formError}
									province={this.state.provincie}
									comuni={this.state.comuni}
									caps={this.state.caps}
								/>
								<label className="death-data">Dati Defunto</label>
								{
									<SearchBar
										memorialData={{
											region : regionClicked,
											id     : this.state.deceasedID,
										}}
										disabled={!!this.state.deceasedID}
										setData={(selected) => {
											if (selected) {
												this.setState({ selected });
												const temp          = this.state.form;
												const { formError } = this.state;
												temp.nameTo         = selected.name;
												formError.nameTo    = false;
												formError.surnameTo = false;
												temp.surnameTo      = selected.surname;
												temp.qrCodeN        = `000${selected.ID}`;

												this.setState({
													form: temp,
													formError,
												});
											} else {
												this.setState({ selected });
												const temp          = this.state.form;
												const { formError } = this.state;
												temp.nameTo         = "";
												formError.nameTo    = true;
												formError.surnameTo = true;
												temp.surnameTo      = "";

												this.setState({
													form: temp,
													formError,
												});
											}
										}}
									/>
								}
								{this.state.formError.nameTo && !this.state.form.nameTo && (
									<label style={{ color: "red" }}>Scegli un defunto per proseguire</label>
								)}
								<TextField
									className="activate-field"
									style={{ display: "none" }}
									name="deceased-name"
									value={this.state.form.nameTo}
									onChange={(event) => {
										event.preventDefault();
										// this.setAndValidateForm(event.target.value, 'nameTo')
									}}
									helperText={this.state.formError.nameTo ? "Campo obbligatorio" : ""}
									error={this.state.formError.nameTo}
								/>
								<input style={{ display: "none" }} name="csrf_token" value={this.state.csrf_token} />
								<TextField
									className="activate-field"
									style={{ display: "none" }}
									value={this.state.form.surnameTo}
									name="deceased-surname"
									onChange={(event) => {
										event.preventDefault();
										// this.setAndValidateForm(event.target.value, 'surnameTo')
									}}
									helperText={this.state.formError.surnameTo ? "Campo obbligatorio" : ""}
									error={this.state.formError.surnameTo}
								/>
								<TextField
									className="activate-field"
									style={{ display: "none" }}
									value={this.state.form.qrCodeN}
									name="deceased-id"
								/>
								{this.ckEditor()}
								<TextField
									className="text-area"
									id="outlined-multiline-static"
									label="Dedica da lascire"
									multiline
									value={this.state.form.dedic}
									style={{ display: "none" }}
									name="dedic"
									rows={8}
									variant="outlined"
									onChange={(event) => {
										this.setAndValidateForm(event.target.value, "dedic");
									}}
									error={this.state.formError.dedic}
								/>
								<input style={{ display: "none" }} name="dedic-type" value={purchaseType} />
								<TextField
									className="activate-field"
									name="dedic-sign"
									label="Firma la tua dedica (Facoltativo)"
								/>
								{purchaseType
									&& (purchaseType.indexOf("foto") > 0 || purchaseType.indexOf("video") > 0) && (
									<div>
										{purchaseType.indexOf("foto") > 0 ? (
											<div className="file-upload">
												<label
													className="pointer"
													htmlFor="photo-dedic"
													color="blue"
													title=""
												>
													<p className="pointer-label">
														{" "}
														{this.state.fileName || "Carica foto (max 15MB per foto)"}
													</p>
												</label>
											</div>
											) : (
												<div className="file-upload">
													<label
														className="pointer"
														htmlFor="dedic-video-upload"
														color="blue"
														title=""
													>
														<p className="pointer-label">
															{" "}
															{this.state.videoName || "Carica video (Max 15MB)"}
														</p>
													</label>
												</div>
											)}
										{this.state.photo && (
											<div className="is-relative">
												<img
													alt="dono"
													style={{
														maxHeight   : 300,
														margin      : "auto",
														paddingLeft : 20,
														marginTop   : 20,
														position    : "relative",
													}}
													src={this.state.photo}
												/>
												<RemoveCircleOutline
													className="remove-photo"
													onClick={() => {
															this.setState({
																fileName : "",
																photo    : null,
																erased   : true,
															});
															document.getElementById("photo-dedic").value = null;
													}}
												/>
											</div>
										)}
										{(this.state.photoError || this.state.videoError) && (
											<label style={{ color: "red" }}>Carica almeno un file</label>
										)}
									</div>
								)}
								&nbsp;
								<ContactPreferenceForm
									handler={(event, value) => {
										this.setState({ radioValue: value });
									}}
									email={this.state.form.email}
									phone={this.state.form.phone}
									value={this.state.radioValue}
									logged={!!this.state.loggedUser}
									error={this.state.radioError}
									label="Per eventuali comunicazioni"
								/>
								&nbsp;
								<DedicNotifyFrequency
									name={this.state.form.nameTo}
									surname={this.state.form.surnameTo}
								/>
								&nbsp;
								<FormControlLabel
									className="activate-checkbox"
									value={this.state.checkBox}
									control={(
										<Checkbox
											color="primary"
											id="term-box"
											onChange={(event, checked) => this.setState({ checkBox: checked })}
										/>
									)}
									label={FormLabel()}
								/>
								{!this.state.checkBox && this.state.termsError && (
									<label
										style={{
											color      : "red",
											textAlign  : "left",
											margin     : 10,
											marginLeft : 30,
										}}
									>
										Devi accettare i termini
									</label>
								)}
								<AcceptBuyTerms
									checkBox={this.state.buyTerms}
									termsError={this.state.buyTermsError}
									setChecked={(c) => {
										this.setState({ buyTerms: c });
									}}
								/>
								{!this.state.loggedUser && (
									<FormControlLabel
										name="register-flag"
										className="activate-checkbox"
										control={<Checkbox color="primary" name="register-flag" value={1} />}
										onChange={(event, checked) => this.setState({ register: checked })}
										label={<label className="activate-checkbox">'Registrami al portale' </label>}
									/>
								)}
								{this.state.register && (
									<UsernameField
										formError={this.state.formError}
										form={this.state.form}
										names="buy"
										load={() => this.setState({ userLoad: true })}
									/>
								)}
								<div className="bottom-activate-form">
									<label>
										L'ATTIVAZIONE DI QUESTO SERVIZIO AVVERRA' SOLO DOPO LA CONFERMA DEI DATI VIA
										<b> E-MAIL</b>
										{" "}
										O
										<b>WHATSAPP</b>
									</label>
									<button className="activate-submit" type="submit">
										Procedi
									</button>
								</div>
								{purchaseType && purchaseType.indexOf("video") > 0 && (
									<VideoInput
										name="dedic"
										setVideoName={(name) => {
											this.setState({ videoName: name });
										}}
									/>
								)}
								{purchaseType && purchaseType.indexOf("foto") > 0 && (
									<PhotoInput
										name="dedic"
										setFileName={(name, photo) => {
											this.setState({ fileName: name });
											readURL(photo)
												.then((data) => this.setState({ photo: data }));
										}}
									/>
								)}
							</form>
						</div>
						<label className="technic-support">
							Per un eventuale supporto tecnico è possibile contattare il numero telefonico 389 89 96 166
							<p>
								<b>DAL LUNEDÌ AL VENERDÌ DALLE ORE 09:00 ALLE 13:00 E DALLE 15:00 ALLE 18:00</b>
							</p>
						</label>
					</div>
				</div>

				{this.state.modal && (
					<div
						onClick={() => {
							if (!closeModalFixed) {
								this.state.paymentNotError
									? this.setState({ modal: false })
									: this.props.history.push({
										pathname : "/paypal-error",
										query    : {
											orderId : this.state.orderId,
											type    : "dedic",
										},
									});
							} else {
								closeModalFixed = false;
							}
						}}
						className="modalPurchase"
					>
						<div
							onClick={() => {
								closeModalFixed = true;
							}}
						>
							<PaymentModal
								paymentType="dedic"
								setError={() => this.setState({ paymentNotError: true })}
								shippingAddress={{
									address  : this.state.form.route,
									civic    : this.state.form.civic,
									city     : this.state.form.city,
									region   : this.state.form.region,
									province : this.state.form.province,
									cap      : this.state.form.cap,
								}}
								price={this.props.history.location && this.state.productPrice}
								showAddress={false}
								successComponent={(a, b, history) => {
									history.push({
										pathname : "Conferma",
										query    : {
											data       : true,
											deceasedID : this.state.deceasedID,
										},
									});
								}}
								// isRegistering={this.state.register}
								// isAccount={!!this.state.loggedUser}
								taxAddress={this.state.fatturazione}
								userData={{
									name    : this.state.form.name,
									surname : this.state.form.surname,
									CF      : this.state.form.CF,
									email   : this.state.form.email,
								}}
								orderId={this.state.orderId}
								quantity={1}
								purchase={purchaseType}
							/>
						</div>
					</div>
				)}
				{this.state.existModal /* this.existsModal() */ && (
					<ExistsModal
						email={this.state.form.email}
						username={this.state.modalname}
						deniedModal={() => {
							this.setState({ existModal: false });
							const temp = this.state.form;
							document.getElementById("username")
								.focus();
							// temp.email = '';
							// temp.CF='';
							// temp.username = '';
							this.setState({ form: temp });
						}}
						label={
							"Un account per l' indirizzo email inserito e` gia` esistente, si prega di inserire una password"
						}
					/>
				)}
			</>
		) : (
			<Redirect to="/products" />
		);
	}
}

function getCsrf() {
	return new Promise((result, err) => Axios.get(`${baseURL}api/auth/securitytoken`)
		.then((resolve) => {
			if (resolve != null) {
				result(resolve.data._csrf);
			} else {
				result({ DATA: "NO DATA" });
			}
		})
		.catch((err) => result({ err })));
}

export default withContext(MemorialActivate);
