import React, { useEffect, useState } from 'react';
import DropDownMenuComponent from './DropDownMenuComponent';

const temparrpartner = {
	title: 'AGGIUNGI DEFUNTO',
	handler: () => (window.location.href = 'partner-panel#aggiungi-defunto')
};

export default function ProdottiNav(props) {
	const [menuItems, setMenuItems] = useState([
		{
			title: 'ACQUISTA LAPIDE DIGITALE',
			handler: () => (window.location.href = 'buy-qrCode')
		},
		{
			title: 'ATTIVA LAPIDE DIGITALE',
			handler: () => (window.location.href = 'activate-qrCode')
		},
		{
			title: 'ATTIVA MEMORIAL',
			handler: () => (window.location.href = 'memorial-activate')
		}
	]);
	useEffect(() => {
		if (props.isPartner && menuItems[menuItems.length - 1] !== temparrpartner) {
			const temp = menuItems;
			// temp.push(temparrpartner);
			setMenuItems(temp);
		}
	}, [props.isPartner]);
	return (
		<DropDownMenuComponent
			id="product"
			backgroundColorMenu="black"
			menuItem={menuItems}
			menuStyle={{
				color: 'white',
				height: 45,
				alignItems: 'center',
				display: 'flex',
				justifyContent: 'space-between',
				font: 'normal normal 300 15px/1.4em oswald-extralight, oswald, sans-serif'
			}}
			style={{
				backgroundColor: 'black',
				color: 'white',
				font: 'normal normal 300 15px/1.4em oswald-extralight, oswald, sans-serif',
				textTransform: 'uppercase',
				cursor: 'pointer'
			}}
			title="prodotti"
		/>
	);
}
