import React, { Component, Fragment } from "react";
import { Redirect } from "react-router";
import { Spinner } from "react-activity";
import {
	Create, ClearRounded, CloudUploadRounded, AddCircle, Visibility,
} from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import ReactImageVideoLightbox from "react-image-video-lightbox";
import Axios from "axios";
import Thumbnail from "react-video-thumbnail";
import { IconButton, Typography } from "@material-ui/core";
import listner from "reactjs-eventemitter";
import withContext from "../../WithContextFile";
import "./Partner-page.css";
import Azienda from "../../assets/Stab/Azienda";
import play from "../../assets/imgStore/play.png";
import requests, { baseURL } from "../../Services/HttpServices";
import { getNameFromID, getNameFromIDForAllType } from "../memorial-page/regioni";
import addressServiceGetData from "../FormComponents/addressService";
import ModalLabelModifyPartner from "./ModalLabelModifyPartner";
import ModalModifyLogo from "./ModalModifyLogo";
import PhotoInput from "../FormComponents/PhotoInput";
import SuccessToast from "../ControlPanel/helperComponent/SuccessToast";
import VideoInput from "../FormComponents/VideoInput";
import PartnerPageSocials from "../FormComponents/PartnerPageSocials";

let images;
let partnerSite;
const azienda = Azienda;
let partnerId;

class PartnerPagePanel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			radioValue    : "",
			lightBoxIndex : 0,
			lightBox      : false,
			transition    : [],
		};
		images     = azienda.photos;
	}

	componentDidMount() {
		listner.events.upload5 = [];
		listner.events.upload5.length < 1
			&& listner.subscribe("upload5", (callback) => {
				this.setState({ percentage: callback });
			});
		if (this.props.history) {
			partnerId =				this.props.history.location.search
				&& this.props.history.location.search.slice(1, this.props.history.location.search.length);
		}
		if (this.props.partnerID) partnerId = this.props.partnerID;

		if (partnerId) {
			this.setState({ loading: true });
			this.getData();
		}
	}

	getData() {
		requests("get", "getPartnerPage", partnerId).then((result) => {
			this.setState({ loading: false });
			if (result.data.response === "success") {
				azienda.sitoWeb     = result.data.data.website;
				azienda.nome        = result.data.data.name;
				azienda.ID          = result.data.data.ID;
				azienda.tipologia   = result.data.data.type;
				azienda.descrizione = result.data.data.description;
				azienda.Email       = result.data.data.email;
				azienda.header      = result.data.data.headerText;
				azienda.logo        = result.data.data.logo;
				azienda.SDI         = result.data.data.SDI;
				azienda.instagram   = result.data.data.instagram;
				azienda.facebook    = result.data.data.facebook;
				azienda.twitter     = result.data.data.twitter;
				azienda.linkedin    = result.data.data.linkedin;
				azienda.youtube     = result.data.data.youtube;
				if (result.data.data.address) {
					const addressData = result.data.data.address;
					if (addressData.nationID == "105") {
						addressServiceGetData("province", addressData.region).then((data) => {
							addressServiceGetData("location", addressData.province).then((data2) => {
								azienda.route = `${addressData.address} ${addressData.number} ${getNameFromIDForAllType(
									addressData.location,
									data2,
								)} ${addressData.cap} (${getNameFromIDForAllType(addressData.province, data)}) ${getNameFromID(
									addressData.region,
								)} `;
								this.setState({ load: !this.state.load });
							});
						});
					} else {
						azienda.route = addressData.completeAddress;
					}
				}
				azienda.PI        = result.data.data.VAT;
				azienda.CF        = result.data.data.CF || result.data.data.VAT;
				azienda.cellulare = result.data.data.mobile;
				azienda.telefono  = result.data.data.telephone;
				images            = [];
				result.data.data.media.map((e, i) => {
					const immagine = {
						url    : e.url,
						altTag : "photo",
						type   : e.type == "image" ? "photo" : "video",
					};
					e.type == "image" ? images.push(immagine) : images.unshift(immagine);
				});
				// images = result.data.data.media
				this.setState({ load: !this.state.load });
			}
		});
	}

	handleModifyLabel(fieldName, valueModify, ckeditor) {
		this.setState({
			fieldName, openModifyModal: true, valueModify, ckeditor,
		});
	}

	handleModifyLogo(fieldName) {
		this.setState({ openModifyLogo: true });
	}

	getPartnerSite(site) {
		let partnerSite;
		if (site.indexOf("http") < 0) {
			partnerSite = `https://${site}`;
		} else partnerSite = site;
		return partnerSite;
	}

	render() {
		return !this.props.context.user ? (
			<>
				{this.state.uploading && (
					<div className="div-upload">
						<Spinner size={100} />
						<Typography className="label-upload">
							Attendi il caricamento dei files
							{" "}
							{parseInt(parseFloat(this.state.percentage) * 100)}
							%
						</Typography>
					</div>
				)}
				<div className="contact-container bg-white">
					{!this.state.loading ? (
						<div
							className="partner-page-container"
							style={{
								position     : "relative",
								padding      : this.props.type === "modal" && 20,
								borderRadius : this.props.type === "modal" && 5,
							}}
						>
							<Tooltip title="Visualizza pagina">
								<IconButton
									style={{
										cursor   : "pointer",
										position : "absolute",
										top      : window.innerWidth > 600 ? 30 : 0,
										right    : window.innerWidth > 600 ? 30 : 0,
									}}
									onClick={() => {
										const win = window.open(`/partner-page?${azienda.ID}`, "_blank");
										win.focus();
									}}
								>
									<Visibility color="primary" />
								</IconButton>
							</Tooltip>
							<label className="partner-page-title">
								{azienda.nome}
								<Create
									style={{ cursor: "pointer" }}
									onClick={() => {
										this.handleModifyLabel("name", azienda.nome);
									}}
								/>
							</label>
							<label className="partner-page-title partner-page-subtitle">
								{azienda.tipologia}
								<Create
									onClick={() => {
										this.handleModifyLabel("type", azienda.tipologia);
									}}
									style={{ cursor: "pointer" }}
								/>
							</label>
							{azienda.logo && azienda.logo !== "https://digitalmemorial.dlgtek.com/api/" ? (
								<div
									style={{
										display        : "flex",
										justifyContent : "center",
										alignItems     : "center",
									}}
								>
									<img
										style={{
											width        : 150,
											marginBottom : 40,
											alignSelf    : "center",
										}}
										alt="logo"
										src={azienda.logo}
									/>
									<Create
										onClick={() => {
											this.handleModifyLogo();
										}}
										style={{ cursor: "pointer" }}
									/>
								</div>
							) : (
								<Tooltip title="Aggiungi logo">
									<label style={{ height: "max-content", marginTop: 10 }}>
										<div
											style={{ margin: "0 auto" }}
											onClick={() => {
												this.handleModifyLogo();
											}}
											className="add-image-panel"
										>
											Aggiungi Logo
										</div>
									</label>
								</Tooltip>
							)}
							<div className="partner-foto-viewer" id="photo-container">
								<div
									className="partner-foto-container"
									style={{
										display        : "flex",
										flexDirection  : "row",
										flexWrap       : "wrap",
										justifyContent : "center",
										width          : parseInt(images.length / 2 + 0.9) * 240 + 40,
										minWidth       : 960,
									}}
								>
									{images.map((e, i) => {
										if (e.type === "photo") {
											return (
												<div
													className="partner-panel-image"
													onMouseLeave={() => {
														if (!this.state.closeDiv) {
															const temp = this.state.transition;
															temp[i]    = false;
															this.setState({ transition: temp });
														}
													}}
												>
													<img
														style={{
															height     : 200,
															width      : 200,
															objectFit  : "cover",
															marginLeft : 20,
														}}
														onMouseEnter={() => {
															const temp = this.state.transition;
															temp[i]    = true;
															this.setState({ transition: temp });
														}}
														onClick={() => {
															this.setState({
																lightBoxIndex : i,
																lightBox      : true,
															});
														}}
														key={e.altTag + i}
														src={e.url}
														alt={e.altTag}
													/>

													{this.state.transition
														&& this.state.transition.length > 0
														&& this.state.transition[i] && (
															<div className="partner-panel-icons">
																<Tooltip title="Rimuovi foto">
																	<ClearRounded
																		style={{ color: "red", cursor: "pointer" }}
																		onClick={() => {
																			this.removeImage(e.url, "remove-image");
																		}}
																	/>
																</Tooltip>
															</div>
														)}
													{/*  <div className={'partner-panel-icons bottomI'}> <CloudUploadRounded style={{ color: 'red' }} /></div> */}
												</div>
											);
										}
									})}
								</div>
							</div>
							{images.length < 50 && (
								<Tooltip title="Aggiungi immagine">
									<label
										htmlFor="photo-partner-image"
										style={{ height: "max-content", marginTop: 45 }}
									>
										<div
											style={{ margin: "0 auto" }}
											onClick={() => {
												this.setState({ editType: "add-image" });
											}}
											className="add-image-panel"
										>
											Aggiungi Immagine
										</div>
									</label>
								</Tooltip>
							)}
							&nbsp;
							{images && images[0] && images[0].type !== "photo" ? (
								<div
									style={{ width: 240, margin: "auto" }}
									onMouseLeave={() => {
										if (!this.state.closeDiv) {
											const temp = this.state.transition;
											temp[0]    = false;
											this.setState({ transition: temp });
										}
									}}
									key={`video${0}`}
									className="partner-panel-image"
								>
									<label style={{ fontWeight: "bold" }}>Video azienda</label>
									<div style={{ width: 200, height: 200, display: "none" }}>
										<Thumbnail
											snapshotAtTime={10}
											videoUrl={images[0].url}
											thumbnailHandler={(thumbnail) => this.setState({ thumbnailData: thumbnail })}
										/>
									</div>
									{!this.state.thumbnailData ? (
										<img
											onMouseEnter={() => {
												const temp = this.state.transition;
												temp[0]    = true;
												this.setState({ transition: temp });
											}}
											style={{
												width     : 150,
												height    : 150,
												objectFit : "cover",
												padding   : 5,
												marginTop : 20,
											}}
											alt="placeholder"
											onClick={() => {
												alert(
													"Errore nella visualizzazione del video, si consiglia di eliminarlo e ricaricarlo",
												);
											}}
											src={play}
											className=""
										/>
									) : (
										<img
											onMouseEnter={() => {
												const temp = this.state.transition;
												temp[0]    = true;
												this.setState({ transition: temp });
											}}
											style={{
												width     : 200,
												height    : 200,
												objectFit : "cover",
												padding   : 5,
											}}
											alt="thumbnail"
											onClick={() => {
												this.setState({ lightBoxIndex: 0, lightBox: true });
											}}
											src={this.state.thumbnailData}
											className=""
										/>
									)}
									{this.state.transition
										&& this.state.transition.length > 0
										&& this.state.transition[0] && (
											<div className="partner-panel-icons">
												<Tooltip title="Rimuovi video">
													<ClearRounded
														onClick={() => {
															this.removeImage(images[0].url, "remove-video");
														}}
														style={{ color: "red", cursor: "pointer" }}
													/>
												</Tooltip>
											</div>
										)}
								</div>
							) : (
								<Tooltip title="Aggiungi video">
									<label
										htmlFor="partner-video-upload"
										style={{ height: "max-content", margin: "20px 0" }}
									>
										<div
											style={{ margin: "0 auto" }}
											onClick={() => {
												this.setState({ editType: "add-video" });
											}}
											className="add-image-panel"
										>
											Aggiungi Video (max 200MB)
										</div>
									</label>
								</Tooltip>
							)}
							<div
								style={{
									display        : "flex",
									flexDirection  : "row",
									justifyContent : "center",
								}}
							>
								{azienda.descrizione ? (
									<div dangerouslySetInnerHTML={{ __html: azienda.descrizione }} />
								) : (
									"Inserisci una descrizione"
								)}
								<Create
									style={{ cursor: "pointer" }}
									onClick={(event) => {
										this.handleModifyLabel("description", azienda.descrizione, true);
									}}
								/>
							</div>
							&nbsp;
							<div className="divider" />
							<SuccessToast label="caricato" successToastOpacity={this.state.successToastOpacity} />
							<form
								onSubmit={(event) => {
									event.preventDefault();
									const formData = new FormData(event.target);
									const object   = {};
									formData.forEach((value, key) => {
										object[key] = value;
									});
									this.setState({ uploading: true });
									this.send(formData);
								}}
							>
								<PhotoInput
									name="partner-image"
									addImage
									setFileName={(name) => {}}
									sendFiles={() => {
										document.getElementById("submit-add").click();
									}}
								/>
								<VideoInput
									name="partner"
									maxSize={200000000}
									setVideoName={(name) => {
										document.getElementById("submit-add").click();

										this.setState({ fileVideoName: name });
									}}
								/>
								<button id="submit-add" style={{ display: "none" }} />
							</form>
							<label className="partner-page-footer text-center uppercase">
								<p>{azienda.nome}</p>
								&nbsp;
								<p>Sede legale e operativa:</p>
								<p>{azienda.route}</p>
								<p>
									P.I.
									{" "}
									{azienda.PI}
									{" "}
									C.F.
									{" "}
									{azienda.CF}
								</p>
								&nbsp;
								{(azienda.telefono || azienda.cellulare) && (
									<p>
										{azienda.telefono
											&& `Tel./Fax: ${azienda.telefono} ${
												azienda.cellulare && `Mobile: ${azienda.cellulare}`
											}`}
									</p>
								)}
								<div style={{ display: "flex", flexDirection: "column" }}>
									{(azienda.SDI || azienda.sdi) && (
										<label>
											{" "}
											SDI:
											{` ${azienda.SDI || azienda.sdi}`}

										</label>
									)}
									{azienda.sitoWeb && (
										<label>
											{" "}
											Web site:
											{" "}
											<a
												className="lowercase"
												target="blank"
												href={this.getPartnerSite(azienda.sitoWeb)}
											>
												{" "}
												{azienda.sitoWeb}
											</a>
										</label>
									)}
									{azienda.Email && (
										<label>
											{" "}
											E-mail:
											{" "}
											<a className="lowercase" href={`mailto:${azienda.Email}`}>
												{azienda.Email}
											</a>
										</label>
									)}
								</div>
							</label>
							<PartnerPageSocials azienda={azienda} />
						</div>
					) : (
						<Spinner />
					)}
					&nbsp;
					{this.videoImageViewer()}
				</div>

				{this.state.openModifyModal && (
					<ModalLabelModifyPartner
						ID={partnerId}
						onSuccess={() => {
							this.setState({ openModifyModal: false });
							this.getData();
						}}
						close={() => this.setState({ openModifyModal: false })}
						ckeditor={this.state.ckeditor}
						field={this.state.fieldName}
						value={this.state.valueModify}
					/>
				)}
				{this.state.openModifyLogo && (
					<ModalModifyLogo
						close={() => this.setState({ openModifyLogo: false })}
						ID={partnerId}
						onSuccess={() => {
							this.setState({ openModifyLogo: false });
							this.getData();
						}}
						field={this.state.fieldName}
					/>
				)}
			</>
		) : (
			<Redirect to="/products" />
		);
	}

	showSuccessTost() {
		this.setState({ successToastOpacity: 100 });
		setTimeout(() => {
			const interval = setInterval(() => {
				this.setState({
					successToastOpacity: this.state.successToastOpacity - 4,
				});
				if (this.state.successToastOpacity <= 0) clearInterval(interval);
			}, 25);
		}, 500);
	}

	send(formData) {
		const formdata = new FormData();
		formData.forEach((value, key) => {
			if (value.length <= 0) {
			} else if (value.size === 0) {
			} else {
				formdata.set(key, value);
			}
		});
		getCsrf().then((csrf) => {
			formdata.set("csrf_token", csrf);
			formdata.set("edit-type", this.state.editType);
			formdata.set("partnerid", partnerId);
			requests("sendform", "partnerEdit", formdata).then((data) => {
				this.setState({ uploading: false });
				if (data.response === "success") {
					this.getData();
					this.showSuccessTost();
					document.getElementById("photo-container").scrollLeft = parseInt(images.length / 2 + 0.9) * 240;
				} else {
					data.errorCode === "ACCESS_DENIED"
						&& alert("Devi essere loggato come partner o amministratore per poter effettuare l'operazione");
					data.errorCode === "INVALID_PARTNER" && alert("l'id del partner non e` valido");

					data.errorCode === "INVALID_EDIT_TYPE" && alert("Operazione non consentita");

					data.errorCode === "CANNOT_DELETE_FILE" && alert("Errore durante l'eliminazione del file");
					data.errorCode === "UPLOADING_ERROR" && alert("Errore durante l'upload del file");

					data.errorCode === "MAX_IMAGES_REACHED" && alert("Hai superato il limite delle 50 foto");
				}
			});
		});
	}

	removeImage(url, type) {
		const bodydata        = {
			partnerid : partnerId,
			content   : url,
		};
		bodydata["edit-type"] = type;
		requests("post", "partnerEdit", bodydata).then((data) => {
			if (data.response === "success") {
				this.getData();
			} else {
				data.errorCode === "ACCESS_DENIED"
					&& alert("Devi essere loggato come partner o amministratore per poter effettuare l'operazione");
				data.errorCode === "INVALID_PARTNER" && alert("l'id del partner non e` valido");

				data.errorCode === "INVALID_EDIT_TYPE" && alert("Operazione non consentita");

				data.errorCode === "CANNOT_DELETE_FILE" && alert("Errore durante l'eliminazione del file");
				data.errorCode === "UPLOADING_ERROR" && alert("Errore durante l'upload del file");

				data.errorCode === "MAX_IMAGES_REACHED" && alert("Hai superato il limite delle 10 foto");
			}
		});
	}

	videoImageViewer() {
		if (this.state.lightBox) {
			return (
				<div className="light-box">
					<ReactImageVideoLightbox
						data={images}
						startIndex={this.state.lightBoxIndex}
						showResourceCount
						onCloseCallback={() => this.setState({ lightBox: false })}
					/>
				</div>
			);
		}
		return null;
	}
}

function getCsrf() {
	return new Promise((result, err) => Axios.get(`${baseURL}api/auth/securitytoken`)
			.then((resolve) => {
				if (resolve != null) {
					result(resolve.data._csrf);
				} else result({ DATA: "NO DATA" });
			})
			.catch((err) => {
				console.log(err);
				return result({ err });
			}));
}

export default withContext(PartnerPagePanel);
