/* eslint-disable react/no-access-state-in-setstate,no-shadow,react/no-did-update-set-state,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,jsx-a11y/label-has-associated-control,guard-for-in,no-restricted-syntax,jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/control-has-associated-label,eqeqeq */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import "./Home.css";
import Icon from "@material-ui/icons/SearchRounded";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FilledInput from "@material-ui/core/FilledInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCross } from "@fortawesome/free-solid-svg-icons";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Back from "@material-ui/icons/ArrowLeft";
import Forward from "@material-ui/icons/ArrowRight";
import {
	ArrowDownward, ArrowDropDown, ArrowDropUp, ArrowUpward, InfoRounded,
} from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {
	Button, CircularProgress, Collapse, Tooltip,
} from "@material-ui/core";
import moment from "moment";
import ObituaryNote from "./ObituaryNote";
import requests from "../../Services/HttpServices";
import LampadePopup from "./../ControlPanel/ClientPanelPages/LampadePopup";
import { IconButton } from '@material-ui/core';
import addressServiceGetData from "../FormComponents/addressService";
import book from "../../assets/book.gif";
import bookgif from "../../assets/bookgif.gif";
import "react-phone-number-input/style.css";
import { buildCap } from "../FormComponents/AddressComponent";
import SinglePhotoViewer from "../FormComponents/singlePhotoViewer";
import withContext from "../../WithContextFile";
import DMCard, { DMModal } from "../FormComponents/DMCard";
import InfoModal from "../FormComponents/InfoModal";
import { MEMORIAL_BOOK_DELAY } from "../../Services/formServices";
import FeedbackModal from "../FormComponents/FeedbackModal";
import { isMobile } from "react-device-detect";
import HomeSingleCardWizard from "./HomeWizard";

const ITEM_HEIGHT              = 48;
const ITEM_PADDING_TOP         = 8;
let allData                    = [];
const MAX_DATA_PER_PAGE        = 10;
let searchData;
let data                       = allData.slice(0, MAX_DATA_PER_PAGE);
let userid = null;
const MAX_WINDOW_WIDTH         = 970;
const Crucifix                 = <FontAwesomeIcon style={{ fontSize: 20 }} icon={faCross} />;
const MenuProps                = {
	PaperProps: {
		style: {
			maxHeight : ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width     : 250,
		},
	},
};
const NAME_SEARCH_DEFAULT      = "";
const FILTER_SEARCH_DEFAULT    = "-1";
const ORDER_SEARCH_DEFAULT     = "insertAndType";
const ORDER_DIR_SEARCH_DEFAULT = "disc";
let regionParam;
let provinceParam;
let locationParam;
let capParam;
let searchTextParam;
let searchid;
let filterParam;
let orderParam;
let orderDirParam;
let nameParam;
let pageParam;
const filteredMap              = {
	"-1" : "all",
	1    : "necro",
	2    : "page",
};

class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			page         : 0,
			region       : "",
			formValidate : true,
			province     : "",
			city         : "",
			search       : "",
			paginator    : true,
			cardModal    : false,
			regionOpen   : false,
			provinceOpen : false,
			locationOpen : false,
			regOpen      : true,
			prOpen       : true,
			locOpen      : true,
			collapse     : false,
			orderDir     : ORDER_DIR_SEARCH_DEFAULT,
			filterValue  : FILTER_SEARCH_DEFAULT,
			orderID      : ORDER_SEARCH_DEFAULT,
		};
	}

	componentDidMount() {
		requests("get", "getBookLimit")
			.then((data) => {
				if (data.data.response === "success") {
					this.setState({ bookLimit: parseInt(data.data.data.condolenceLimit, 10) });
				} else {
					this.setState({ bookLimit: MEMORIAL_BOOK_DELAY });
				}
			});
		const { search } = window.location;
		const params     = new URLSearchParams(search);
		regionParam      = params.get("region");
		provinceParam    = params.get("province");
		locationParam    = params.get("location");
		capParam         = params.get("cap");
		searchTextParam  = params.get("name");
		searchid         = params.get("searchid");
		filterParam      = params.get("filter") || FILTER_SEARCH_DEFAULT;
		nameParam        = params.get("name");
		pageParam        = params.get("page");
		orderParam       = params.get("order") || ORDER_SEARCH_DEFAULT;
		orderDirParam    = params.get("orderDir") || ORDER_DIR_SEARCH_DEFAULT;
		searchData       = {};
		if (orderParam) {
			this.setState({ orderID: orderParam });
		}
		if (orderDirParam) {
			this.setState({ orderDir: orderDirParam });
		}
		if (capParam) {
			this.setState({ caps: ["-1", capParam] });
		}
		let letsSearch = false;
		if (
			regionParam === null
			|| provinceParam === null
			|| locationParam === null
			|| capParam === null
			|| searchTextParam === null
		) {
			let link = `/home?name=${searchTextParam || NAME_SEARCH_DEFAULT}&region=${regionParam || ""}&province=${
				provinceParam || ""
			}&location=${locationParam || ""}&cap=${capParam || ""}&page=0`;
			if (searchid) link += `&searchid=${searchid}`;
			link += `&filter=${filterParam || FILTER_SEARCH_DEFAULT}`;
			link += `&order=${orderParam || ORDER_SEARCH_DEFAULT}`;
			link += `&orderDir=${orderDirParam || ORDER_DIR_SEARCH_DEFAULT}`;
			this.props.history.push(link);
		} else {
			letsSearch = true;
		}
		let doit;
		window.onresize = () => {
			clearTimeout(doit);
			doit = setTimeout(() => {
				this.setState({ resized: !this.state.resized });
			}, 100);
		};
		this.setState({ searching: false });
		addressServiceGetData("region")
			.then((data) => {
				this.setState({ regioni: data });
			});
		if (searchTextParam || searchTextParam === "") {
			if (!searchData) searchData = {};
			searchData.text = searchTextParam || "";
			this.setState({ search: searchTextParam });
		}
		// controllo se nell'url ci sta la ricerca in caso contrario la prendo dalla sessione
		if (regionParam || regionParam === "") {
			if (!searchData) searchData = {};
			searchData.region   = regionParam;
			searchData.province = null;
			searchData.region   = null;
			searchData.location = null;
			searchData.city     = null;
			searchData.cap      = null;
			if (provinceParam !== "") {
				searchData.province = provinceParam;
			} else {
				searchData.province = "";
			}
			if (locationParam !== "") {
				searchData.city = locationParam;
			} else {
				searchData.city = "";
			}
			if (capParam !== "") {
				searchData.cap = capParam;
			} else {
				searchData.city = "";
			}
		}

		if (searchid) {
			if (!searchData) searchData = {};
			searchData.searchid = searchid;
		}
		if (searchData) {
			if (searchData.region !== "") {
				this.setState({
					region: searchData.region,
				});
				addressServiceGetData("province", searchData.region)
					.then((result) => {
						const provincie = result;
						this.setState({ provincie });
					});
			}
			if (searchData.province !== "") {
				this.setState({
					province: searchData.province,
				});
				addressServiceGetData("location", searchData.province)
					.then((result) => {
						const comuni = result;
						this.setState({ comuni });
						if (searchData.city !== "") {
							const locSelected = comuni?.find(
								(x) => x.ID?.toLowerCase() === searchData?.location?.toLowerCase(),
							);
							let capArr        = [];
							if (locSelected.capList) capArr = buildCap(locSelected);
							capArr.unshift("-1", locSelected.cap);
							this.setState({
								caps: capArr,
							});
						}
					});
			}
			if (searchData.city !== "") {
				this.setState({
					city: searchData.city || searchData.location,
				});
			}
			if (searchData.cap !== "") {
				this.setState({ searchCap: searchData.cap });
			}
		} else {
			searchData          = {};
			searchData.region   = " ";
			searchData.province = " ";
			searchData.city     = " ";
			searchData.text     = " ";
		}
		if (filterParam) {
			searchData.filter = filteredMap[filterParam];
		}

		if (letsSearch) this.searchRequest(pageParam);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { search } = window.location;
		const params     = new URLSearchParams(search);
		regionParam      = params.get("region");
		provinceParam    = params.get("province");
		locationParam    = params.get("location");
		capParam         = params.get("cap");
		searchTextParam  = params.get("name");
		searchid         = params.get("searchid");
		filterParam      = params.get("filter") || FILTER_SEARCH_DEFAULT;
		nameParam        = params.get("name");
		pageParam        = params.get("page");
		let haveToSearch = false;
		orderDirParam    = params.get("orderDir") || ORDER_DIR_SEARCH_DEFAULT;
		orderParam       = params.get("order") || ORDER_SEARCH_DEFAULT;
		if (
			(searchData.text !== nameParam
				|| (searchData.region || "") !== regionParam
				|| (searchData.province || "") !== provinceParam
				|| (searchData.city || "") !== locationParam
				|| (searchData.cap || "") !== capParam
				|| searchData.filter !== filteredMap[filterParam]
				|| searchData.orderDir !== orderDirParam
				|| orderParam !== searchData.order)
			&& regionParam !== null
			&& provinceParam !== null
			&& orderParam !== null
			&& orderDirParam !== null
			&& nameParam !== null
			&& filterParam !== null
			&& capParam !== null
			&& locationParam !== null
		) {
			haveToSearch        = true;
			searchData.text     = nameParam;
			searchData.region   = regionParam;
			searchData.province = provinceParam;
			searchData.city     = locationParam;
			searchData.cap      = capParam;
			searchData.filter   = filteredMap[filterParam];
			searchData.order    = orderParam;
			searchData.orderDir = orderDirParam;
			searchData.page     = pageParam;
			this.setState({
				region      : regionParam,
				province    : provinceParam,
				city        : locationParam,
				search      : nameParam,
				searchCap   : capParam,
				filterValue : filterParam || FILTER_SEARCH_DEFAULT,
				orderID     : orderParam,
				orderDir    : orderDirParam,
				page        : pageParam,
			});
		}
		if (searchid && searchid !== searchData.searchid) {
			searchData.searchid = searchid;
			haveToSearch        = true;
		} else if (!searchid) {
			if (searchData.searchid !== searchid) haveToSearch = true;
			searchData.searchid = searchid;
		}
		if (filterParam && filteredMap[filterParam] !== searchData.filter) {
			searchData.filter = filteredMap[filterParam];
		}

		if (haveToSearch || pageParam !== this.state.page) {
			this.searchRequest(pageParam);
		}
	}

	handleSearchResultData(result) {
		if (result?.data?.result === "success") {
			allData = result.data.searchResult;
			data    = allData;

			result.data.searchResult.forEach((e, i) => {
				result.data.searchResult[i].dateOfDeathValue = e.dateOfDeath && moment(e.dateOfDeath, "DD/MM/YYYY")
					.toDate()
					.getTime();
				result.data.searchResult[i].funeralDateValue = e.funeralDate
					? moment(e.funeralDate, "YYYY-MM-DD")
						.toDate()
						.getTime()
					: moment(e.dateOfDeath, "DD/MM/YYYY")
						.toDate()
						.getTime();
				result.data.searchResult[i].insertTime       = e.time && moment(e.time)
					.toDate()
					.getTime();
			});

			//Leggi i dati della Lampada
			try { userid = JSON.parse(sessionStorage.getItem("User")).userdata.user_id; } catch (e) {}
			data.forEach(async (e, i) => {
				let requestLamp = await requests("get", "lampadaData", parseInt(data[i].ID, 10));
				if (requestLamp?.data?.result === "success") {
					data[i].lampadaData = requestLamp.data.lampadaData;
					data[i].lampadaTrovata = true;
					data[i].lampadaStato = parseInt(data[i].lampadaData.statoLampadaEffettivo, 10);
					data[i].lampadaModalita = parseInt(data[i].lampadaData.statoLampadaImpostato, 10);

					//Leggi i dati delle LampadeCollegate
					const bodydata = {};
					bodydata["seriale"] = parseInt(data[i].lampadaData.serialeLampada, 10);
					data[i].lampadaData.lampadeCollegate = [];
					let requestLampCollegate = await requests("post", "lampadeCollegateLoad", bodydata);
					if (requestLampCollegate?.result === "success") { data[i].lampadaData.lampadeCollegate = requestLampCollegate?.lampadeCollegate; }
				} else { data[i].lampadaData = null; }

				this.setState({
					searching : false,
					nextPage  : result.data?.nextPage,
				});
			});
		} else {
			allData = ["error"];
			data    = [];
			this.setState({ searching: false });
		}
	}

	setUrlLocation = (filter, page, order, orderDir, name, region, province, location, cap) => {
		let toSearchRegion   = region || regionParam || "";
		let toSearchProvince = province || provinceParam || "";
		let toSearchLocation = location || locationParam || "";
		let toSearchCap      = cap || capParam || "";
		let toSearchPage     = page || pageParam;
		let toSearchName     = name || searchTextParam;
		if (name === "") toSearchName = "";
		if (page === 0) toSearchPage = 0;

		if (region === -1) {
			toSearchRegion   = "-1";
			toSearchProvince = "";
			toSearchCap      = "";
			toSearchLocation = "";
		}
		if (province === -1) {
			toSearchProvince = "-1";
			toSearchCap      = "";
			toSearchLocation = "";
		}
		if (location === -1) {
			toSearchCap      = "";
			toSearchLocation = "-1";
		}
		if (cap == -1 || cap === "Tutti") {
			toSearchCap = "-1";
		}
		let newurl = `/home?name=${toSearchName}&region=${toSearchRegion}&province=${toSearchProvince}&location=${toSearchLocation}&cap=${toSearchCap}&page=${toSearchPage}`;
		if (searchid) newurl += `&searchid=${searchid}`;
		newurl += `&filter=${filter || filterParam || FILTER_SEARCH_DEFAULT}`;
		newurl += `&order=${order || orderParam || ORDER_SEARCH_DEFAULT}`;
		newurl += `&orderDir=${orderDir || orderDirParam || ORDER_DIR_SEARCH_DEFAULT}`;
		this.props.history.push(newurl);
	};

	card(data, i, isObituary) {
		let extraInfo;
		let extraInfoLabel;
		if (isObituary) {
			extraInfo = !data.graveyardName
				&& (data.obituaryNote === "NULL"
					|| !data.obituaryNote
					|| data.obituaryNote == "undefined"
					|| data.obituaryNote === "<p></p>");
			if (!extraInfo) {
				extraInfoLabel = "Visualizza informazioni funerale";
			} else {
				extraInfoLabel = "Nessuna informazione sul funerale disponibile";
			}
		} else {
			extraInfo = !data.graveyardName && !data.graveAddress;
			if (!extraInfo) {
				extraInfoLabel = "Visualizza informazioni luogo di sepoltura";
			} else {
				extraInfoLabel = "Nessuna informazione sul luogo di sepoltura disponibile";
			}
		}

		const haveToPulse = !(
			data.time
			&& moment(new Date(data.time))
				.add(this.state.bookLimit || MEMORIAL_BOOK_DELAY, "days")
				.toDate()
				.getTime() < new Date().getTime()
		);
		return (
			<div
				style={{
					cursor   : !isObituary && "pointer",
					color    : "#4a4a4a",
					position : "relative",
					overflow : "hidden",
				}}
				key={data.name + i}
				className="search-result"
			>
				<HomeSingleCardWizard searchID={searchid} />
				{/* Nome se sta in visualizzazione mobile esce in alto */}
				{window.innerWidth < 601 && (
					<label className="label-fix" style={{ cursor: "pointer" }}>
						<span style={{ maxWidth: window.innerWidth >= 600 ? 410 : "100%" }} id="card-content-span">
							{`${data.name} ${data.surname} `}
						</span>
					</label>
				)}

				{/* Info button start */}
				<div className="absolute-book">
					<Tooltip title={extraInfoLabel}>
						<InfoRounded
							style={{
								fontSize : 35,
								cursor   : "pointer",
								color    : !extraInfo ? "#ffaa00" : "grey",
							}}
							onClick={() => {
								if (!extraInfo) {
									if (!isObituary) {
										this.setState({
											graveName    : data.graveyardName,
											isLapidNote  : true,
											graveAddress : data.graveAddress,
											// obituary: data.obituaryNote,
											showObituary : true,
										});
									} else {
										this.setState({
											obituary     : data.obituaryNote,
											isLapidNote  : false,
											videoCerimonia : data.videoCerimonia,
											showObituary : true,
										});
									}
								}
							}}
						/>
					</Tooltip>
				</div>
				{/* Info button end */}
					
				{!isObituary && data.lampadaTrovata && data.lampadaStato == 0 &&  (
				 	<div className="absolute-book lampIcon">
				 		<Tooltip title="Lampada Votiva">
					 		<IconButton
						 		size="small"
						 		onClick={e => {
							 		e.stopPropagation();
							 		if (userid == data.lampadaData.idGestore) { this.setState({ lampadaDataState: data.lampadaData, lampadeCollegateState: data.lampadaData.lampadeCollegate, showLampadePopupNome: `${data.name} ${data.surname}`, showLampadePopup: true }); }
								 }}
						 		className="icon-button"
					 		>
							<img
								className="home-lamp-icon"
						 		src={"/api/images/lamp-spenta.gif"}
					 		/>
					 		</IconButton>
				 		</Tooltip>
			 		</div>
				)}

				{!isObituary && data.lampadaTrovata && data.lampadaStato == 1 && (data.lampadaModalita == 1 || data.lampadaModalita == 0) &&  (
				 	<div className="absolute-book lampIcon">
				 		<Tooltip title="Lampada Votiva">
					 		<IconButton
						 		size="small"
						 		onClick={e => {
							 		e.stopPropagation();
							 		if (userid == data.lampadaData.idGestore) { this.setState({ lampadaDataState: data.lampadaData, lampadeCollegateState: data.lampadaData.lampadeCollegate, showLampadePopupNome: `${data.name} ${data.surname}`, showLampadePopup: true }); }
								 }}
						 		className="icon-button"
					 		>
							<img
								className="home-lamp-icon"
						 		src={"/api/images/lamp-accesa-sempre.gif"}
					 		/>
					 		</IconButton>
				 		</Tooltip>
			 		</div>
				)}

				{!isObituary && data.lampadaTrovata && data.lampadaStato == 1 && data.lampadaModalita == 2 &&  (
				 	<div className="absolute-book lampIcon">
				 		<Tooltip title="Lampada Votiva">
					 		<IconButton
						 		size="small"
						 		onClick={e => {
							 		e.stopPropagation();
							 		if (userid == data.lampadaData.idGestore) { this.setState({ lampadaDataState: data.lampadaData, lampadeCollegateState: data.lampadaData.lampadeCollegate, showLampadePopupNome: `${data.name} ${data.surname}`, showLampadePopup: true }); }
								 }}
						 		className="icon-button"
					 		>
							<img
								className="home-lamp-icon"
						 		src={"/api/images/lamp-accesa-fiamma.gif"}
					 		/>
					 		</IconButton>
				 		</Tooltip>
			 		</div>
				)}

				{!isObituary && data.lampadaTrovata && data.lampadaStato == 1 && data.lampadaModalita == 3 &&  (
				 	<div className="absolute-book lampIcon">
				 		<Tooltip title="Lampada Votiva">
					 		<IconButton
						 		size="small"
						 		onClick={e => {
							 		e.stopPropagation();
							 		if (userid == data.lampadaData.idGestore) { this.setState({ lampadaDataState: data.lampadaData, lampadeCollegateState: data.lampadaData.lampadeCollegate, showLampadePopupNome: `${data.name} ${data.surname}`, showLampadePopup: true }); }
								 }}
						 		className="icon-button"
					 		>
							<img
								className="home-lamp-icon"
						 		src={"/api/images/lamp-accesa-evanescenza.gif"}
					 		/>
					 		</IconButton>
				 		</Tooltip>
			 		</div>
				)}

				{!isObituary && data.lampadaTrovata && data.lampadaStato > 1 &&  (
				 	<div className="absolute-book lampIcon">
				 		<Tooltip title="Lampada Votiva">
					 		<IconButton
						 		size="small"
						 		onClick={e => {
							 		e.stopPropagation();
							 		if (userid == data.lampadaData.idGestore) { this.setState({ lampadaDataState: data.lampadaData, lampadeCollegateState: data.lampadaData.lampadeCollegate, showLampadePopupNome: `${data.name} ${data.surname}`, showLampadePopup: true }); }
								 }}
						 		className="icon-button"
					 		>
							<img
								className="home-lamp-icon"
						 		src={"/api/images/lamp-spenta.gif"}
					 		/>
					 		</IconButton>
				 		</Tooltip>
			 		</div>
				)}
				

				<div
					className="absolute-book chip chip-style"
					onClick={() => (data.pageID
						? this.props.history.push(
							`?name=&region=&province=&location=&cap=&page=0&searchid=${
								data.pageID || data.necroID
							}&filter=-1&order=insertAndType&orderDir=disc`,
						)
						: isObituary
							? this.setState({
								preError      : true,
								preErrorLabel : isObituary
									? "Pre-Lapide non disponibile"
									: "Pre-Necrologio non disponibile",
							})
							: document.getElementById(`cardClick${i}`)
								.click())}
					style={{
						backgroundColor : isObituary ? "black" : "#e1802b",
						cursor          : (data.pageID || data.necroID) && "pointer",
					}}
				>
					{isObituary ? "Mostra Pre-Lapide" : "Mostra Lapide Digitale"}
				</div>
				{!isObituary && data.necroID && (
					<div
						className="absolute-book chip chip-style chip2"
						onClick={(e) => {
							e.stopPropagation();
							if (data.necroID) {
								this.props.history.push(
									`?name=&region=&province=&location=&cap=&page=0&searchid=${data.necroID}&filter=-1&order=insertAndType&orderDir=disc`,
								);
							}
						}}
					>
						Mostra necrologio
					</div>
				)}
				{(!isObituary || data.pageID) && (
					<img
						src={haveToPulse ? bookgif : book}
						alt="libro"
						className={`absolute-book-partner ${haveToPulse && " pulse-anim"} ${isObituary && "obituary"}`}
						style={{
							height : "auto",
							width  : 45,
							zIndex : 2,
						}}
					/>
				)}
				{data.partner && (
					<div>
						<img
							alt="logo"
							src={data.partner.logo}
							style={{
								objectFit    : "contain",
								paddingRight : window.innerWidth < 360 && 5,
							}}
							className="absolute-book-partner-bottom partner-memorial safari-fix"
						/>
						<Link
							to={{
								pathname : "./partner-page",
								search   : `?${data.partner.ID}`,
							}}
							className="absolute-book-partner-bottom partner-memorial"
						/>
					</div>
				)}

				{(!isObituary || data.pageID) && (
					<Link
						to={{
							pathname : "./memorial-book",
							search   : `?000${data.pageID || data.ID}`,
						}}
						className="absolute-book-partner zindex2"
					/>
				)}

				{!isObituary ? (
					<Link
						id={`cardClick${i}`}
						to={{
							pathname : "./memorial",
							search   : `?000${data.ID}`,
						}}
						style={{ cursor: "pointer" }}
						className="inner-search"
					>
						<img
							className="result-img oval"
							alt={`result: ${data.name}`}
							src={
								data.photoLapideThumbnail
								|| "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
							}
						/>

						{window.innerWidth >= 600 && <div style={{ cursor: "pointer" }} className="vertical-divider" />}
						{this.cardContent(data, i, isObituary)}
					</Link>
				) : (
					<div className="inner-search noHeight">
						<img
							className="result-img"
							style={{ cursor: "pointer" }}
							alt={`result: ${data.name}`}
							onClick={() => {
								data.photoLapide
								&& this.setState({
									photoViewer  : true,
									imageClicked : data.photoLapide,
									showObituary : false,
									obituary     : null,
								});
							}}
							src={
								data.photoLapideThumbnail
								|| "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
							}
						/>
						{window.innerWidth >= 600 && <div style={{ cursor: "pointer" }} className="vertical-divider" />}
						{this.cardContent(data, i, isObituary)}
					</div>
				)}
				{window.innerWidth < 600 && (
					<p
						style={{
							fontSize  : 15,
							marginTop : 2,
						}}
					>
						{`${data.graveLocation} (${data.graveProvince}) ${data.graveRegion}`}
					</p>
				)}

				{window.innerWidth < MAX_WINDOW_WIDTH && (
					<div
						className="divider2"
						style={{
							height : window.innerWidth < MAX_WINDOW_WIDTH && 55,
							width  : "100%",
						}}
					/>
				)}
			</div>
		);
	}

	// eslint-disable-next-line class-methods-use-this
	_calculateAge(birthday, deathDay) {
		// birthday is a date
		const ageDifMs = new Date(deathDay).getTime() - new Date(birthday).getTime();
		const ageDate  = new Date(ageDifMs); // miliseconds from epoch
		return Math.abs(ageDate.getUTCFullYear() - 1970);
	}

	cardContent(data, i, isObituary) {
		let show                   = true;
		show = !(data.dateOfBirth == false || data.dateOfDeath == false || data.dateOfDeath == data.dateOfBirth);
		const dateMomentObject     = moment(data.dateOfBirth, "DD/MM/YYYY");
		const dateMomentObject2    = moment(data.dateOfDeath, "DD/MM/YYYY");
		const dateOfBirthComponent = () => (
			<label className="labelDate">
				<p style={{
					marginBottom : 3,
					fontSize     : 28,
				}}
				>
					✶&nbsp;
				</p>
				<p style={{ cursor: "pointer" }} className="font-size-19">
					{`${data.dateOfBirth}`}
				</p>
			</label>
		);
		const dateOfDeathComponent = () => (
			<label className="labelDate">
				{Crucifix}
				&nbsp;
				<p
					style={{
						cursor   : !isObituary && "pointer",
						fontSize : window.innerWidth < 600 && 20,
					}}
					className="font-size-19"
				>
					{`${data.dateOfDeath}`}
				</p>
			</label>
		);
		return (
			<div style={{ cursor: !isObituary && "pointer" }} className="search-result-data">
				<label
					className="flex flex-column "
					style={{
						justifyContent : window.innerWidth > 400 ? "space-between" : "center",
						minHeight      : 85,
						cursor         : !isObituary && "pointer",
						height         : "100%",
					}}
				>
					{window.innerWidth >= 600 && (
						<span style={{ maxWidth: window.innerWidth >= 600 ? 410 : "100%" }} id="card-content-span">
							{`${data.name} ${data.surname} `}
						</span>
					)}
					{!data.staticAge && show && `${data.showPage}` === "0" && (
						<p style={{ cursor: "pointer" }} className="font-size-19">
							Di anni:
							{" "}
							{this._calculateAge(dateMomentObject.toDate(), dateMomentObject2.toDate())}
						</p>
					)}
					{data.staticAge && `${data.showPage}` === "0" && (
						<p style={{ cursor: "pointer" }} className="font-size-19">
							Di anni
							{" "}
							{data.staticAge}
						</p>
					)}

					{window.innerWidth >= 600 ? (
						show ? (
							<label
								style={{
									flexDirection  : "row",
									justifyContent : "flex-start",
									alignItems     : "center",
									height         : "auto",
									marginBottom   : 30,
								}}
							>
								{dateOfBirthComponent()}
								{dateOfDeathComponent()}
							</label>
						) : (
							data.dateOfDeath && (
								<label
									style={{
										flexDirection  : "row",
										justifyContent : "flex-start",
										alignItems     : "baseline",
										height         : "auto",
										marginBottom   : 30,
									}}
								>
									<b
										style={{
											marginRight : window.innerWidth > 600 ? 8 : 4,
											marginLeft  : window.innerWidth < 600 && 2,
											fontSize    : window.innerWidth < 600 && 20,
										}}
									>
										{Crucifix}
									</b>
									<p style={{ cursor: !isObituary && "pointer" }} className="font-size-19">
										{data.dateOfDeath}
									</p>
								</label>
							)
						)
					) : show ? (
						<div
							style={{
								cursor        : !isObituary && "pointer",
								fontSize      : window.innerWidth < 600 && 20,
								display       : "flex",
								flexDirection : "column",
								alignItems    : "center",
							}}
						>
							<label
								style={{
									flexDirection  : "row",
									justifyContent : "flex-start",
									alignItems     : "center",
									height         : "auto",
								}}
							>
								✶
								{" "}
								<p className="font-size-19">{data.dateOfBirth}</p>
							</label>
							<label className="labelDate">
								<b
									style={{
										marginRight : window.innerWidth > 600 ? 8 : 4,
										marginLeft  : window.innerWidth < 600 && 2,
										fontSize    : window.innerWidth < 600 && 20,
									}}
								>
									{Crucifix}
								</b>
								<p className="font-size-19">{data.dateOfDeath}</p>
							</label>
						</div>
					) : (
						data.dateOfDeath && (
							<label className="toMobile text-row-props">
								{window.innerWidth > 600 ? (
									<p>✶ -&nbsp;</p>
								) : (
									<p style={{
										marginLeft : -4.5,
										alignSelf  : "flex-start",
									}}
									>
										✶
									</p>
								)}
								{dateOfDeathComponent()}
							</label>
						)
					)}
					{window.innerWidth >= 600 && (
						<p style={{ fontSize: 16 }}>
							{!data.graveyardName || `${data.showPage}` === "1"
								? `${data.graveLocation} (${data.graveProvince}) ${data.graveRegion}`
								: data.graveyardName}
							{/* {data.graveLocation + ' (' + data.graveProvince + ') ' + data.graveAddress + ' ' + data.graveRegion} */}
						</p>
					)}
				</label>
			</div>
		);
	}

	changePage(i) {
		if (!this.state.searching) {
			this.send(i);
			let newurl = `/home?name=${searchTextParam || ""}&region=${regionParam || ""}&province=${
				provinceParam || ""
			}&location=${locationParam || ""}&cap=${capParam}&page=${parseInt(pageParam, 10) + i}`;
			newurl    += `&filter=${filterParam || FILTER_SEARCH_DEFAULT}`;
			newurl    += `&order=${orderParam || ORDER_SEARCH_DEFAULT}`;
			newurl    += `&orderDir=${orderDirParam || ORDER_DIR_SEARCH_DEFAULT}`;
			this.props.history.push(newurl);
		}
	}

	send(i) {
		this.setState({ formValidate: true });
		const nextPage   = i || 0;
		const searchData = {
			region     : this.state.region,
			province   : this.state.province,
			location   : this.state.city,
			text       : this.state.search,
			cap        : this.state.searchCap,
			filter     : filterParam ? filteredMap[filterParam] : "all",
			page       : parseInt(pageParam, 10) + parseInt(nextPage, 10) + 1,
			// searchid: this.state.serchid || '',
			maxResults : "",
			order      : orderParam,
			orderDir   : orderDirParam,
		};
		this.setState({
			searching : true,
			submit    : false,
		});

		requests("search", null, searchData)
			.then((result) => this.handleSearchResultData(result));
		localStorage.setItem("search", JSON.stringify(searchData));
	}

	searchRequest(page) {
		this.setState({
			searching : true,
			page,
			submit    : false,
		});
		requests("search", null, {
			region   : searchData.region,
			province : searchData.province || " ",
			location : searchData.city || " ",
			cap      : searchData.cap || " ",
			text     : searchData.search || searchData.text || " ",
			searchid : searchData.searchid,
			filter   : searchData.filter,
			order    : orderParam,
			orderDir : orderDirParam,
			page     : parseInt(pageParam, 10) + 1,
			// maxResults: searchData.maxResults || ' ',
		})
			.then((result) => this.handleSearchResultData(result));
	}

	searchClickHandler(event, state) {
		event.preventDefault();
		searchData.searchid = null;
		try {
			document.activeElement.blur();
		} catch (e) {
			console.log(e);
		}
		const {
			search,
			region,
			province,
		} = state;
		const city = state.location || state.city;
		const cap  = state.searchCap || "";

		const newurl = `/home?name=${search || ""}&region=${region || ""}&province=${province || ""}&location=${
			city || ""
		}&cap=${cap}&page=0`;
		this.props.history.push(newurl);
		// document.getElementById("formB").click();
	}

	render() {
		if (this.state.photoViewer) {
			document.documentElement.style.overflow = "hidden";
		} else {
			document.documentElement.style = {};
		}
		const startPage = MAX_DATA_PER_PAGE * pageParam;
		data            = [...allData];
		return !this.props.context.user ? (
			<>	
				{this.state.showLampadePopup && (
					<LampadePopup
						lampadaData = {this.state.lampadaDataState}
						lampadeCollegate = {this.state.lampadeCollegateState}
						nomeDefunto = {this.state.showLampadePopupNome}
						close={() => this.setState({ showLampadePopup: null })}
					/>
				)}

				{this.state.showObituary && (this.state.obituary || this.state.graveName || this.state.graveAddress) && (
					<ObituaryNote
						close={() => this.setState({
							showObituary : null,
							graveName    : null,
							graveAddress : null,
							videoCerimonia : null,
							isLapidNote  : false,
						})}
						isLapid={this.state.isLapidNote}
						vidCerimonia={this.state.videoCerimonia}
						graveyardName={this.state.graveName}
						graveyardAddress={this.state.graveAddress}
						note={this.state.obituary}
					/>
				)}
				{this.state.preError && (
					<FeedbackModal
						result="Attenzione"
						close={() => this.setState({ preError: false })}
						label={this.state.preErrorLabel}
					/>
				)}
				<div className="home-background no-x-auto">
					{this.state.photoViewer && (
						<SinglePhotoViewer
							img={this.state.imageClicked}
							handleClose={() => this.setState({ photoViewer: false })}
						/>
					)}
					<div className="search-box-div">
						{this.state.cardModal && (
							<InfoModal
								label="Aggiungendo il nostro contatto alla rubrica, Whatsapp renderà cliccabili i link che riceverai nelle nostre notifiche"
								handleClose={() => this.setState({ cardModal: false })}
							/>
						)}
						<DMModal
							open={this.state.DMCardInputModal}
							close={() => this.setState({ DMCardInputModal: false })}
						/>
						<div
							className="subtitle"
							style={{
								position : "relative",
								display  : "flex",
							}}
						>
							<label style={{ marginTop: -12 }}>
								Ricerca
								{window.innerHeight <= 850
									&& (!this.state.collapse ? (
										<ArrowDropDown style={{ marginLeft: 20 }} />
									) : (
										<ArrowDropUp style={{ marginLeft: 20 }} />
									))}
							</label>
							
							{window.innerHeight <= 850 && (
								<div
									style={{
										position : "absolute",
										cursor   : "pointer",
										top      : 0,
										left     : 0,
										width    : "100%",
										height   : 80,
									}}
									onClick={() => {
										this.setState({ collapse: !this.state.collapse });
									}}
								/>
							)}
							<Collapse in={this.state.collapse || window.innerHeight > 850} className="search-box">
								<form
									id="myForm"
									onSubmit={(event) => {
										event.preventDefault();
										this.setState({
											collapse : false,
											submit   : true,
										});
										searchData.searchid = null;
										searchid            = null;
										this.setUrlLocation(
											this.state.filterValue,
											0,
											this.state.orderID,
											this.state.orderDir,
											this.state.search,
											this.state.region,
											this.state.province,
											this.state.city,
											this.state.searchCap,
										);

										// this.searchClickHandler(event, this.state);
										// !(this.state.region == "" && this.state.search == "") &&
										// this.send();
									}}
								>
									<div style={{ marginBottom: 12 }}>
										<FormControl
											className="field w-100perc"
											style={{
												height             : 48,
												borderEndEndRadius : 0,
												background         : "white",
											}}
											variant="filled"
										>
											<InputLabel>Cerca il tuo caro...</InputLabel>
											<FilledInput
												value={this.state.search}
												name="search-text"
												style={{ background: "white" }}
												onChange={(e) => {
													this.setState({ search: e.target.value });
												}}
											/>
										</FormControl>
									</div>
									<div className="search-first-row" style={{ marginTop: 8 }}>
										<FormControl
											variant="filled"
											color="primary"
											style={{ backgroundColor: "white" }}
											className="field"
										>
											<InputLabel color="primary" variant="filled" id="mutiple-name-label">
												Regione
											</InputLabel>
											<Select
												labelId="demo-mutiple-name-label"
												id="demo-mutiple-name"
												variant="outlined"
												name="search-region"
												open={this.state.regionOpen}
												onFocus={() => {
													if (this.state.regOpen) this.setState({ regionOpen: true });
												}}
												onOpen={() => {
													this.setState({ regionOpen: true });
												}}
												onClose={() => {
													this.setState({
														regionOpen : false,
														regOpen    : false,
													});
												}}
												onBlur={() => {
													this.setState({ regOpen: true });
												}}
												value={this.state.region}
												onChange={(e) => {
													this.setState({
														region  : e.target.value,
														regOpen : false,
													});
													if (e.target.value !== "" && `${e.target.value}` !== "-1") {
														addressServiceGetData("province", e.target.value)
															.then((data) => {
																const provincie = data;
																this.setState({ provincie });
																this.setState({
																	province  : -1,
																	city      : "",
																	searchCap : "",
																	caps      : null,
																});
															});
													} else {
														this.setState({
															provincie : "",
															comuni    : "",
															province  : "",
															city      : "",
															searchCap : "",
															caps      : null,
														});
													}
												}}
												input={<Input color="primary" />}
												MenuProps={MenuProps}
											>
												<MenuItem
													key="noregion"
													onClick={() => {
														this.setState({ regOpen: false });
													}}
													value={-1}
												>
													Tutte
												</MenuItem>
												{this.state.regioni
													&& this.state.regioni.map((e) => (
														<MenuItem
															key={e.ID}
															onClick={() => this.setState({ regOpen: false })}
															value={e.ID}
														>
															{e.name}
														</MenuItem>
													))}
											</Select>
										</FormControl>
										<FormControl
											variant="filled"
											color="primary"
											style={{
												backgroundColor : "white",
												zIndex          : 0,
												width           : 170,
											}}
											className="field ml-13"
										>
											<InputLabel variant="filled" color="primary" id="demo-mutiple-name-label">
												Provincia
											</InputLabel>
											<Select
												labelId="demo-mutiple-name-label"
												id="demo-mutiple-name"
												variant="outlined"
												name="search-province"
												open={this.state.provinceOpen}
												onFocus={() => {
													this.state.prOpen && this.setState({ provinceOpen: true });
												}}
												onOpen={() => {
													this.setState({ provinceOpen: true });
												}}
												onClose={() => {
													this.setState({
														provinceOpen : false,
														prOpen       : false,
													});
												}}
												onBlur={() => {
													this.setState({ prOpen: true });
												}}
												value={this.state.province}
												onClick={() => {
													if (!this.state.provincie) alert("Devi prima selezionare una regione");
												}}
												disabled={!this.state.provincie}
												onChange={(e) => {
													this.setState({
														province : e.target.value,
														prOpen   : false,
													});
													if (`${e.target.value}` !== "-1") {
														addressServiceGetData("location", e.target.value)
															.then((data) => {
																const comuni = data;

																this.setState({
																	comuni,
																	city      : -1,
																	searchCap : "",
																	caps      : null,
																});
															});
													} else {
														this.setState({
															city      : "",
															searchCap : "",
															caps      : null,
														});
													}
												}}
												input={<Input color="primary" />}
												MenuProps={MenuProps}
											>
												<MenuItem
													key="not-pr"
													value={-1}
													onClick={() => this.setState({ prOpen: false })}
												>
													Tutte
												</MenuItem>
												{this.state.provincie
													&& this.state.provincie.map((e) => (
														<MenuItem
															key={e.ID}
															onClick={() => {
																this.setState({ prOpen: false });
															}}
															value={e.ID}
														>
															{e.name}
														</MenuItem>
													))}
											</Select>
										</FormControl>

										<FormControl
											variant="filled"
											color="primary"
											style={{
												backgroundColor : "white",
												height          : 53,
											}}
											className="field ml-13"
										>
											<InputLabel color="primary" variant="filled" id="demo-mutiple-name-label">
												Città
											</InputLabel>
											<Select
												labelId="demo-mutiple-name-label"
												id="demo-mutiple-name"
												variant="outlined"
												value={this.state.city}
												name="search-location"
												open={this.state.locationOpen}
												onFocus={() => {
													this.state.locOpen && this.setState({ locationOpen: true });
												}}
												onOpen={() => {
													this.setState({ locationOpen: true });
												}}
												onClose={() => {
													this.setState({
														locationOpen : false,
														locOpen      : false,
													});
												}}
												onBlur={() => {
													this.setState({ locOpen: true });
												}}
												onClick={(event) => {
													if (
														this.state.province === ""
														|| this.state.province === -1
														|| this.state.province === "-1"
													) {
														alert("Devi prima selezionare una provincia");
													}
												}}
												disabled={
													this.state.province === ""
													|| this.state.province === -1
													|| this.state.province === "-1"
												}
												onChange={(e) => {
													if (e.target.value !== -1) {
														const locSelected = this.state.comuni.find(
															(x) => x.ID === e.target.value,
														);
														let capArr        = [];
														if (locSelected?.capList) capArr = buildCap(locSelected);
														capArr.unshift("-1", locSelected.cap);
														this.setState({
															searchCap : -1,
															caps      : capArr,
															city      : e.target.value,
															locOpen   : false,
														});
													} else {
														this.setState({
															searchCap : "",
															city      : e.target.value,
														});
													}
												}}
												input={<Input color="primary" />}
												MenuProps={MenuProps}
											>
												<MenuItem
													key="not-loc"
													value={-1}
													onClick={() => {
														this.setState({ locOpen: false });
													}}
												>
													Tutte
												</MenuItem>
												{this.state.comuni
													&& this.state.comuni.map((e) => (
														<MenuItem
															onClick={() => {
																this.setState({ locOpen: false });
															}}
															className="overflow-none"
															style={{
																paddingLeft  : 0,
																paddingRight : 0,
															}}
															key={e.ID}
															value={e.ID}
														>
															{e.name}
														</MenuItem>
													))}
											</Select>
										</FormControl>
										<Autocomplete
											// style={{width: 300}}
											style={{
												paddingRight    : 0,
												display         : "flex",
												alignItems      : "flex-end",
												alignContent    : "flex-end",
												width           : 100,
												height          : 53,
												marginLeft      : 13,
												backgroundColor : "white",
											}}
											className="home-cap"
											onChange={(event, value, reason, details) => {
												this.setState({
													searchCap: value,
												});
											}}
											disabled={!(this.state.caps?.length > 0) && !this.state.searchCap}
											name="cap"
											options={this.state.caps}
											value={this.state.searchCap || ""}
											autoHighlight
											getOptionLabel={(option) => (`${option}` === "-1" ? "Tutti" : option)}
											renderOption={(option) => (
												<>
													<span>{`${option}` === "-1" ? "Tutti" : option}</span>
												</>
											)}
											renderInput={(params) => (
												<TextField
													style={{
														paddingRight : 0,
														display      : "flex",
														alignItems   : "flex-end",
														alignContent : "flex-end",
													}}
													color="primary"
													inputProps={{ style: { paddingLeft: 10 } }}
													name="cap"
													{...params}
													label="Cap"
												/>
											)}
										/>
									</div>
									<div
										style={{
											marginTop     : window.innerWidth >= 960 && -4,
											flexDirection : window.innerWidth < 960 && "column",
										}}
										className="is-flex"
									>
										<FormControl
											variant="filled"
											color="primary"
											style={{
												backgroundColor : "white",
												borderRadius    : window.innerWidth < 960 ? "4px" : "0 0 0 4px",
												height          : 53,
												width           : window.innerWidth < 960 ? "100%" : "50%",
												margin          : window.innerWidth < 960 && "10px 0 24px 0",
											}}
											className={`${window.innerWidth > 681 && " field "} `}
										>
											<InputLabel color="primary" variant="filled" id="demo-mutiple-name-label">
												Visualizza
											</InputLabel>
											<Select
												labelId="filter"
												id="filter-select"
												value={parseInt(this.state.filterValue, 10)}
												variant="outlined"
												name="filter"
												onChange={(e) => {
													this.setState({ filterValue: e.target.value });
												}}
												input={<Input color="primary" />}
												MenuProps={MenuProps}
											>
												<MenuItem value={-1}>Tutto</MenuItem>
												<MenuItem value={1}>Solo necrologi</MenuItem>
												<MenuItem value={2}>Solo lapidi digitali</MenuItem>
											</Select>
										</FormControl>
										<div
											style={{
												display    : "flex",
												width      : window.innerWidth < 960 ? "100%" : "50%",
												alignItems : "baseline",
											}}
										>
											<FormControl
												variant="filled"
												color="primary"
												style={{
													backgroundColor : "white",
													borderRadius    : window.innerWidth < 960 ? "4px" : "0 0 4px 0px",
													height          : 53,
													width           : "100%",
													marginLeft      : window.innerWidth >= 960 && 13,
												}}
												className={`${window.innerWidth > 681 && " field "} ${
													this.state.orderID && " no-drop-icon "
												}`}
											>
												<InputLabel
													color="primary"
													variant="filled"
													id="demo-mutiple-order-label"
												>
													Ordina
												</InputLabel>
												<Select
													labelId="order"
													id="order-select"
													variant="outlined"
													value={this.state.orderID || ORDER_SEARCH_DEFAULT}
													name="order"
													onChange={(e) => {
														const toSet = {
															orderID: e.target.value,
														};
														this.setState(toSet);
														// this.setUrlLocation(null, null, e.target.value);
													}}
													input={<Input color="primary" />}
													MenuProps={MenuProps}
												>
													<MenuItem value="insertAndType">
														Per data di inserimento e tipologia
													</MenuItem>
													<MenuItem value="funeralDateValue">Per data del funerale</MenuItem>
													<MenuItem value="dateOfDeathValue">Per data di morte</MenuItem>
													<MenuItem value="insertTime">Per data di inserimento</MenuItem>
													<MenuItem value="name">Per nome</MenuItem>
													<MenuItem value="surname">Per cognome</MenuItem>
												</Select>
											</FormControl>
											{this.state.orderDir === "disc" ? (
												<ArrowDownward
													onClick={() => {
														this.setState({ orderDir: "asc" });
														// this.setUrlLocation(null, null, null, "asc");
													}}
													style={{
														color  : "#fa0",
														cursor : "pointer",
														width  : 50,
													}}
													className={!this.state.orderID && "display-none"}
												/>
											) : (
												<ArrowUpward
													onClick={() => {
														this.setState({ orderDir: "disc" });
														// this.setUrlLocation(null, null, null, "disc");
													}}
													style={{
														color  : "#fa0",
														cursor : "pointer",
														width  : 50,
													}}
													className={!this.state.orderID && "display-none"}
												/>
											)}
										</div>
									</div>
									<div className="row-between">
										<div
											style={{
												height         : 49,
												display        : "flex",
												justifyContent : "center",
												alignItems     : "center",
											}}
											onClick={() => {
												const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?name=&region=&province=&location=&cap=&page=0`;
												window.history.pushState({ path: newurl }, "", newurl);
												this.setState({
													region      : "",
													search      : "",
													city        : "",
													province    : "",
													provincie   : "",
													comuni      : "",
													showNecro   : false,
													showPages   : false,
													filterValue : FILTER_SEARCH_DEFAULT,
													orderDir    : ORDER_DIR_SEARCH_DEFAULT,
													orderID     : ORDER_SEARCH_DEFAULT,
													searchCap   : "",
													caps        : null,
													submit      : false,
												});
												localStorage.removeItem("search");
												localStorage.removeItem("filter");
												localStorage.removeItem("order");
												searchData = {};

												requests("search", null, {
													region   : "",
													province : "",
													location : "",
													text     : "",
													searchid : "",
													// maxResults: 40
												})
													.then((result) => this.handleSearchResultData(result));
											}}
											className="search-button has-text-white"
											id="reset"
										>
											Reset filtri
										</div>
										<button type="submit" id="formB" style={{ display: "none" }} />
										{/* eslint-disable-next-line react/button-has-type */}
										<button
											style={{
												border : "none",
												width  : "max-content",
												height : 48,
											}}
											type="button"
											onTouchStart={() => this.setState({ submitStart: true })}
											onTouchEnd={(event) => {
												event.preventDefault();
												if (this.state.submitStart) {
													document.getElementById("formB")
														.click();
													this.setState({ submitStart: false });
												}
											}}
											onClick={() => {
												if (!isMobile) {
													document.getElementById("formB")
														.click();
												}
											}}
											className="search-button has-text-white"
										>
											<Icon style={{ fontSize: 38 }} />
										</button>
									</div>
								</form>
							</Collapse>
						</div>
					</div>

					{this.state.paginator && (
						<div
							style={{
								paddingBottom : 0,
								position      : "relative",
							}}
							className="memorial-book-paginator margin-paginator-fix paginator-fix"
						>
							<div
								style={{ display: `${pageParam}` === "0" && "none" }}
								className="paginator-arrow"
								onClick={() => {
									this.changePage(-1);
								}}
							>
								<Back className="memorial-book-back" />
								Indietro
							</div>

							<div
								style={{
									position  : "absolute",
									left      : "50%",
									top       : "50%",
									transform : "translate(-50%,-50%)",
								}}
							>
								<DMCard
									onClick={() => this.setState({ DMCardInputModal: true })}
									setModal={(value) => this.setState({ cardModal: value })}
								/>
							</div>
							{this.state.nextPage && (
								<div
									style={{ marginLeft: "auto" }}
									className="paginator-arrow"
									onClick={() => {
										this.changePage(+1);
									}}
								>
									Avanti
									<Forward className="memorial-book-back" />
								</div>
							)}
						</div>
					)}
					{
						<div
							className="third-div"
							style={{
								minHeight     : 300,
								paddingBottom : searchid && 60,
							}}
						>
							{this.state.formValidate
								// !this.state.searching &&
								&& (data[0] != "error" && data.length > 0
									? data.map((e, i) => {
										if (e.showPage && `${e.showPage}` === "1") return this.card(e, i, false);
										return this.card(e, i, true);
									})
									: !this.state.searching && (
										<div className="no-elem">
											<label>Nessun elemento corrispondente ai criteri di ricerca</label>
										</div>
									))}
						</div>
					}
					{this.state.paginator && (
						<div className="memorial-book-paginator margin-paginator-fix paginator-fix">
							<div
								style={{ display: `${pageParam}` === "0" && "none" }}
								className="paginator-arrow"
								onClick={() => {
									this.changePage(-1);
									window.scrollTo(0, 300);
								}}
							>
								<Back className="memorial-book-back" />
								Indietro
							</div>
							{this.state.nextPage && (
								<div
									style={{ marginLeft: "auto" }}
									className="paginator-arrow"
									onClick={() => {
										this.changePage(+1);
										window.scrollTo(0, 300);
									}}
								>
									Avanti
									<Forward className="memorial-book-back" />
								</div>
							)}
						</div>
					)}
					<div className="bottom bottom-fix-margin" style={{ height: 90 }}>
						<div className="contact">
							<label className="bottom-label">
								Digitalmemorial.it è un portale che permette di ricordare i propri cari
							</label>
							<a href={`mailto:${"info@digitalmemorial.it"}`} className="bottom-label email">
								info@digitalmemorial.it
							</a>
						</div>
					</div>
					{searchid && (
						<Button
							variant="contained"
							onClick={() => document.getElementById("reset")
								.click()}
							className="show-all-home-button"
							style={{
								color           : "black",
								fontWeight      : "bold",
								backgroundColor : "#fa0",
							}}
						>
							MOSTRA TUTTO
						</Button>
					)}
				</div>
			</>
		) : (
			<Redirect to="/products" />
		);
	}
}

export default withContext(Home);
