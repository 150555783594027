import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from '@material-ui/lab/Autocomplete';
import addressServiceGetData from './addressService';
import validate from '../../formValidationService';
import RegionData from '../../assets/RegionData';
import { buildCap } from './AddressComponent';

const gettingRegion = false;

function AddressServiceNew(props) {
	const { names } = props;
	const { form } = props;
	const { formError } = props;
	const [regOpen, setRegOpen] = useState(true);
	const [prOpen, setPrOpen] = useState(true);
	const [locOpen, setLocOpen] = useState(true);
	const [regioni, setRegioni] = useState(null);
	const [province, setProvince] = useState(props.provincie);
	const [comuni, setComuni] = useState(props.comuni);
	const [loaded, setLoaded] = useState(false);
	const [regionOpen, setRegionOpen] = useState(false);
	const [provinceOpen, setProvinceOpen] = useState(false);
	const [locationOpen, setLocationOpen] = useState(false);
	const [reload, setReload] = useState(false);
	const [cap, setCap] = useState('');
	const [multipleFix, setMultipleFix] = useState(false);
	const [caps, setCaps] = useState(props.caps || []);
	const [selectedLocation, setSelectedLocation] = useState(false);
	props.province && !province && setProvince(props.province);
	props.comuni && !comuni && setComuni(props.comuni);
	useEffect(() => {
		if (props.caps) {
			const temp = [];
			props.caps.forEach(e => {
				if (`${e}` !== '0') temp.push(`${e}`);
			});
			temp.length > 0 && temp.unshift('-1');
			setCaps(temp);
		}
		if (selectedLocation) {
			setCap(selectedLocation.cap);
			let arrCap = [];
			if (selectedLocation.capList) {
				const tempArr = buildCap(selectedLocation);
				if (tempArr?.length > 0) arrCap = tempArr;
			}
			arrCap.unshift(`${selectedLocation.cap}`);
			if (selectedLocation.capList) {
				arrCap.unshift('-1');
			}
			setCaps(arrCap);
		}
	}, [props.caps, props.caps?.length]);

	if (!regioni && !gettingRegion && !multipleFix) {
		let regions;
		try {
			regions = JSON.parse(sessionStorage.getItem('region'));
		} catch (e) {}
		regions ? setRegioni(regions) : setRegioni(RegionData);
		setMultipleFix(true);
		props.forceLoad();
	}
	useEffect(() => {
		multipleOnChange({
			cap: form.cap
		});
	}, [form.cap]);

	function setAndValidateForm(data, attribute, isNumeric, form, formError, exactLength) {
		form[attribute] = data;
		formError = validate(data, attribute, formError, isNumeric);
		if (exactLength && form[attribute].length !== exactLength) formError[attribute] = true;
		setLoaded(!loaded);
	}

	const handleRegionOpen = () => {
		setRegionOpen(true);
	};
	const handleRegionClose = () => {
		setRegionOpen(false);
	};
	const handleProvinceOpen = () => {
		setProvinceOpen(true);
	};
	const handleProvinceClose = () => {
		setProvinceOpen(false);
	};
	const handleLocationOpen = () => {
		setLocationOpen(true);
	};
	const handleLocationClose = () => {
		setLocationOpen(false);
	};
	function multipleOnChange() {
		if (!form.province) form.province = '';
		if (!form.location) form.location = '';
		if (!form.city) form.city = '';
		props.setToSend();
	}
	const handleRegionChange = event => {
		setRegOpen(false);
		setRegionOpen(false);
		setAndValidateForm(event.target.value, 'region', false, form, formError);
		multipleOnChange({ region: event.target.value });
		if (props.form.region) {
			addressServiceGetData('province', event.target.value).then(data => {
				form.province = '';
				form.city = '';
				form.cap = '';

				setProvince(data);
				setComuni(null);
				setCap('');
				setCaps([]);
				setSelectedLocation(false);
				document.activeElement.blur();
				setPrOpen(true);
				// document.getElementById('regionField').blur()
			});
		} else setProvince(props.province);
	};
	const handleProvinceChange = event => {
		setAndValidateForm(event.target.value, 'province', false, form, formError);
		setPrOpen(false);
		setProvinceOpen(false);
		multipleOnChange({ province: event.target.value });
		if (props.form.province) {
			addressServiceGetData('location', event.target.value).then(data => {
				form.city = '';
				form.cap = '';
				setComuni(data);
				setCap('');
				setCaps([]);
				setSelectedLocation(false);
				document.activeElement.blur();
			});
		} else setComuni(props.comuni);
	};
	const handleComuniChange = event => {
		setCaps([]);
		setSelectedLocation(false);
		setLocOpen(false);
		setLocationOpen(false);
		setAndValidateForm(event.target.value, 'city', false, form, formError);
		multipleOnChange({ location: event.target.value });
		const locationSelected = comuni.find(x => x.ID === form.city);
		setSelectedLocation(locationSelected);
		setAndValidateForm(locationSelected.cap, 'cap', true, form, formError);
		setCap(locationSelected.cap);
		let arrCap = [];
		if (locationSelected.capList) {
			const tempArr = buildCap(locationSelected);
			if (tempArr?.length > 0) arrCap = tempArr;
		}
		arrCap.unshift(`${locationSelected.cap}`);
		if (locationSelected.capList) {
			arrCap.unshift('-1');
		}
		if (props.index === 0 && props.addRow && props.length <= 1) props.addRow();
		setCaps(arrCap);
		document.activeElement.blur();
	};
	return (
		<div id="address" style={{ width: props.fullWidth && '100%' }}>
			<div
				className="Partner-row-fields"
				style={{ flexDirection: 'column' }}
				onClick={() => {
					setPrOpen(false);
					setRegOpen(false);
					setLocOpen(false);
				}}
			>
				<div className={`Partner-row-fields toColumn ${(props.index === 0 || !props.index) && 'req'}`}>
					<div className="region-city-fix w-full">
						<TextField
							className="partner-field field180"
							id="regionField"
							error={!props.forceErrorWithReq ? props.error : formError.region}
							label={props.index === 0 ? 'Regione *' : 'Regione'}
							onBlur={() => {
								setPrOpen(true);
								setRegOpen(true);
								setLocOpen(true);
							}}
							value={form.region || ''}
							name={`${names}-region`}
							onFocus={(event, a) => {
								regOpen && setRegionOpen(true);
							}}
							SelectProps={{
								open: regionOpen,
								onOpen: handleRegionOpen,
								onClose: handleRegionClose,
								onChange: handleRegionChange
							}}
							helperText={formError.region ? 'Campo obbligatorio' : ''}
							disabled={props.forceDisable}
							select
							variant={props.variant || 'standard'}
						>
							{regioni ? (
								regioni.map(e => (
									<MenuItem
										key={e.ID}
										onClick={() => {
											setRegOpen(false);
										}}
										value={e.ID}
									>
										{e.name}
									</MenuItem>
								))
							) : (
								<MenuItem>No connection</MenuItem>
							)}
						</TextField>
						<TextField
							className="partner-field field180 ml-20"
							disabled={!province || props.forceDisable}
							error={!props.forceErrorWithReq ? props.error : formError.province}
							label={props.index === 0 ? 'Provincia *' : 'Provincia'}
							id={`${names}-province`}
							onBlur={() => {
								setPrOpen(true);
								setRegOpen(true);
								setLocOpen(true);
							}}
							SelectProps={{
								open: provinceOpen,
								onOpen: handleProvinceOpen,
								onClose: handleProvinceClose,
								onChange: handleProvinceChange
							}}
							onFocus={(event, a) => {
								prOpen && setProvinceOpen(true);
							}}
							value={form.province || ''}
							name={`${names}-province`}
							helperText={formError.province ? 'Campo obbligatorio' : ''}
							select
							variant={props.variant || 'standard'}
						>
							{province ? (
								province.map(e => (
									<MenuItem
										key={e.ID}
										onClick={() => {
											setPrOpen(false);
										}}
										value={e.ID}
									>
										{e.name}
									</MenuItem>
								))
							) : (
								<MenuItem>No connection</MenuItem>
							)}
						</TextField>
					</div>
					<div className="region-city-fix">
						<TextField
							className="partner-field small minField100"
							onBlur={() => {
								setPrOpen(true);
								setRegOpen(true);
								setLocOpen(true);
							}}
							SelectProps={{
								open: locationOpen,
								onOpen: handleLocationOpen,
								onClose: handleLocationClose,
								onChange: handleComuniChange
							}}
							onFocus={() => {
								locOpen && setLocationOpen(true);
							}}
							error={!props.forceErrorWithReq ? props.error : formError.city}
							disabled={!comuni || props.forceDisable}
							name={`${names}-location`}
							value={form.city || ''}
							label={props.index === 0 ? 'Città *' : 'Città'}
							id={`${names}-location`}
							helperText={formError.city ? 'Campo obbligatorio' : ''}
							select
							variant={props.variant || 'standard'}
						>
							{comuni ? (
								comuni.map(e => (
									<MenuItem key={e.ID} onClick={() => setLocOpen(false)} value={e.ID}>
										{e.name}
									</MenuItem>
								))
							) : (
								<MenuItem>No connection</MenuItem>
							)}
						</TextField>
						<Autocomplete
							style={{
								paddingRight: 0,
								display: 'flex',
								alignItems: 'flex-end',
								alignContent: 'flex-end',
								width: 100,
								minWidth: 80,
								marginLeft: 20
							}}
							onChange={(event, value, reason, details) => {
								form.cap = value;
								multipleOnChange({ cap: value });
								setCap(value);
							}}
							// debug
							disabled={props.forceDisable || !(caps?.length > 0)}
							debug
							name={`${names}-cap`}
							options={caps}
							value={form.cap || cap}
							autoHighlight
							getOptionLabel={option => (option !== '-1' ? option : 'Tutti')}
							renderOption={option => (
								<>
									<span>{option !== '-1' ? option : 'Tutti'}</span>
								</>
							)}
							renderInput={params => (
								<TextField
									style={{
										paddingRight: 0,
										display: 'flex',
										alignItems: 'flex-end',
										alignContent: 'flex-end'
									}}
									name={`${names}-cap`}
									error={formError.cap || (!props.forceErrorWithReq ? props.error : formError.city)}
									helperText={
										formError.cap || (!props.forceErrorWithReq ? props.error : formError.city)
											? 'Obbligatorio'
											: ''
									}
									{...params}
									label="Cap"
								/>
							)}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AddressServiceNew;
