/* eslint-disable jsx-a11y/label-has-associated-control,react/no-access-state-in-setstate,no-shadow,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,react/no-unused-state */
import React, { Component, useEffect } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import "./Memorial-book.css";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCross } from "@fortawesome/free-solid-svg-icons";
import { CircularProgress, Collapse } from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp, Refresh } from "@material-ui/icons";
import withContext from "../../WithContextFile";
import requests from "../../Services/HttpServices";
import Dedics from "../../assets/Stab/demo-demo-book-dedic";
import Aforisms from "../../assets/Stab/Aforism";
import stab from "../../assets/Stab/defunto";
import PhoneNumber from "../FormComponents/PhoneNumber";
import FeedbackModal from "../FormComponents/FeedbackModal";
import AcceptTerms from "../FormComponents/AcceptTerms";
import { MEMORIAL_BOOK_DELAY, suggestUsername } from "../../Services/formServices";
import ModifyAccountNotification from "../Auth/ModfyAccountNotification/ModifyAccountNotification";
import HttpServices from "../../Services/HttpServices";

let bgHeight;
const aforism      = Aforisms;
let data           = Dedics.concat(Dedics)
	.concat(Dedics)
	.concat(Dedics)
	.concat(Dedics);
let dedics;
let taboffset      = 20;
let stabLink       = "./partner-page";
let deceasedID;
let timerUserCheck = null;
let accountData    = {};
const isSafari     = /constructor/i.test(window.HTMLElement)
	|| (function (p) {
		return p.toString() === "[object SafariRemoteNotification]";
	}(
		// eslint-disable-next-line no-undef
		!window.safari || (typeof safari !== "undefined" && safari.pushNotification),
	));

class MemorialBook extends Component {
	constructor(props) {
		super(props);
		this.state       = {
			loggedUser         : "",
			selectedValue      : 1,
			page               : 0,
			activeNotification : true,
			number             : "",
			secureCode         : "",
			feedbackModal      : false,
			modalText          : "",
			modalResult        : "",
		};
		stab.thereIsBook = true;
		dedics           = data;
		accountData      = {};
	}

	componentDidMount() {
		try {
			const notJsonData = (sessionStorage.getItem("User"));
			let jsonData      = null;
			if (notJsonData)jsonData = JSON.parse(sessionStorage.getItem("User"));
			this.setState({
				loggedUser: sessionStorage.getItem("User") && jsonData?.userdata,
			});
		} catch (e) {
			console.log("catch", e);
		}
			requests("get", "getBookLimit")
				.then((data) => {
					if (data.data.response === "success") {
						this.setState({ bookLimit: parseInt(data.data.data.condolenceLimit, 10) });
					} else {
						this.setState({ bookLimit: MEMORIAL_BOOK_DELAY });
					}
				});

			if (this.props.history.location.search) {
				this.setState({ searching: true });
				deceasedID = this.props.history.location.search.slice(1, this.props.history.location.search.length);
				this.getData();
			} else {
				data   = Dedics.concat(Dedics)
					.concat(Dedics)
					.concat(Dedics)
					.concat(Dedics);
				dedics = data;
			}
			window.addEventListener("resize", this.updateDimensions);
	}

	handleNameSurnameUpdate = (e) => {
		this.setState({ [e.target.name]: e.target.value });
		accountData[e.target.name] = e.target.value;
		if (accountData.accountName && accountData.accountSurname) {
			if (timerUserCheck) {
				clearTimeout(timerUserCheck);
				timerUserCheck = null;
			}
			timerUserCheck = setTimeout(
				() => suggestUsername(
					accountData.accountName.replace(/[^a-zA-Z0-9]/g, ""),
					accountData.accountSurname.replace(/[^a-zA-Z0-9]/g, ""),
				)
					.then((result) => {
						this.setState({
							username: result.replace(/[^a-zA-Z0-9]/g, ""),
						});
					}),
				500,
			);
		}
	};

	getData() {
		requests("get", "deceasedData", deceasedID)
			.then((resolve) => {
				if (resolve.data.result === "success") {
					const tempZone = {
						region   : resolve.data.deceasedData.graveRegionID,
						province : resolve.data.deceasedData.graveProvinceID,
						location : resolve.data.deceasedData.graveLocationID,
						cap      : resolve.data.deceasedData.graveCap,
					};
					this.setState({
						contactData             : { zone: [tempZone] },
						notificationList        : [tempZone],
						initialNotificationList : [tempZone],
					});
					stab.hasPhoto  = true;
					stab.azienda   = null;
					stab.name      = resolve.data.deceasedData.name;
					stab.surname   = resolve.data.deceasedData.surname;
					stab.bornDate  = resolve.data.deceasedData.dateOfBirth;
					stab.deathDate = resolve.data.deceasedData.dateOfDeath;
					stab.mainPhoto = resolve.data["photo-lapide"];
					stab.time      = resolve.data.deceasedData.time;
					if (
						moment(stab.time)
							.add(this.state.bookLimit || MEMORIAL_BOOK_DELAY, "days")
							.toDate()
							.getTime() < new Date().getTime()
						&& parseInt(deceasedID, 10) !== 1000
					) {
						this.setState({ disabled: true });
					}
					if (resolve.data.partner) {
						stab.azienda = resolve.data.partner.name;
						stabLink     = `/partner-page?${resolve.data.partner.ID}`;
					}
					this.getBookData();
				} else {
					alert(`Defunto con id ${deceasedID} non trovato, verra\` reinderizzatto alla home`);
					this.props.history.push("./home");
				}
			});
	}

	getBookData() {
		this.setState({ gettingData: true });
		requests("get", "getBookMessages", deceasedID)
			.then((messages) => {
				if (messages.data && (messages.data.result !== "success" || messages.data.condolences === false)) {
					dedics = [];
					data   = [];
				} else {
					data   = messages.data.condolences;
					dedics = data;
				}
				this.setState({ gettingData: false });
				this.setState({
					searching : false,
					loaded    : true,
					page      : 0,
				});
			});
	}

	updateDimensions = () => {
		this.setState(() => ({ resizing: true }));
	};

	send(formData) {
		let error = false;
		if (!this.state.other) {
			error = true;
			this.setState({
				feedbackModal : true,
				modalText     : "Inserisci una frase per continuare",
				modalResult   : "Errore",
			});
		}
		const bodyData = {
			name           : this.state.name,
			text           : this.state.other,
			qrcode         : deceasedID,
			accountName    : this.state.accountName,
			accountSurname : this.state.accountSurname,
		};
		if (this.state.number && this.state.number.length >= 8) {
			bodyData.number = this.state.number;
		}
		let termError = false;
		if (!this.state.checkBox && !this.state.loggedUser) {
			this.setState({ termsError: true });
			termError = true;
		}
		if (!this.state.loggedUser) {
			if (!this.state.accountName) {
				error = true;
				this.setState({ accountNameError: true });
			}
			if (!this.state.accountSurname) {
				error = true;
				this.setState({ accountSurnameError: true });
			}
			if (this.state.activeNotification) {
				if (!this.state.notificationList?.length > 0) {
					error = true;
				this.setState({ errorNotification: true });
				}
			}
		}
		if (this.state.name && this.state.selectedValue && !termError && !error) {
			error = !this.state.other;

			if (!bodyData.number) {
				if (!error && !termError) {
					this.setState({
						allFormError : false,
						termError    : false,
					});
					this.setState({ sendLoading: true });
					requests("post", "book", bodyData)
						.then((data) => {
							if (data.result === "success" || data.response === "success") {
								this.setState({
									number                : "",
									resetNotificationList : !this.state.resetNotificationList,
									activeNotification    : false,
									phoneExist            : true,
								});
								this.setState({
									sendLoading   : false,
									feedbackModal : true,
									other         : "",
									modalText     : (
										<span style={{ textAlign: "center" }}>
											Condoglianze aggiunte con successo
											{" "}
											<br />
											<strong>Sentitamente si ringrazia per la vicinanza dimostrata</strong>
										</span>
									),
									modalResult   : "Successo",
									name          : "",
									selectedValue : 1,
								});
							} else {
								this.setState({
									sendLoading   : false,
									feedbackModal : true,
									modalText     : data.message,
									modalResult   : "Errore",
								});
							}
						});
				} else {
					this.setState({
						feedbackModal : true,
						modalText     : "Inserisci una frase per continuare",
						modalResult   : "Errore",
					});
				}
			} else {
				this.setState({
					allFormError : false,
					termError    : false,
				});
				this.setState({ sendLoading: true });
				if (this.state.activeNotification) {
					const toSend     = {
						...bodyData,
						...formData,
						notificationList: JSON.stringify(this.state.notificationList),
					};
					toSend.name      = this.state.accountName;
					toSend.surname   = this.state.accountSurname;
					toSend.telephone = toSend.number;
					HttpServices("post", "registration", toSend)
						.then((data) => {
							if (data.response === "success") {
								this.setState({
									number                : "",
									activeNotification    : true,
									phoneExist            : false,
									resetNotificationList : !this.state.resetNotificationList,
									sendLoading           : false,
									linkModal             : true,
									other                 : "",
									name                  : "",
									selectedValue         : 1,
									accountNameError      : false,
									accountSurnameError   : false,

								});
							} else {
								this.setState({
									feedbackModal : true,
									modalText     : data.message,
									sendLoading   : false,
									modalResult   : "Errore",
								});
							}
						});
				} else {
					this.setState({ sendLoading: true });
					requests("post", "book", bodyData)
						.then((data) => {
							if (data.response === "success") {
								this.setState({
									number             : "",
									activeNotification : true,
									phoneExist         : false,
									linkModal          : true,
									other              : "",
									sendLoading        : false,
									name               : "",
									selectedValue      : 1,
								});
							} else {
								this.setState({
									feedbackModal : true,
									sendLoading   : false,
									modalText     : data.message,
									modalResult   : "Errore",
								});
							}
						});
				}
			}
		} else {
			this.setState({ allFormError: true });
			this.setState({
				feedbackModal : true,
				modalText     : "Compila il form e accetta i termini per inviare una dedica",
				modalResult   : "Errore",
			});
		}
	}

	ModalVerify() {
		let closeModal = false;
		return (
			<div
				className="bg-modal"
				onClick={() => {
					closeModal ? this.setState({
						showModalVerify : false,
						collapse        : false,
					}) : (closeModal = true);
				}}
			>
				<div
					id="info-container"
					style={{
						display       : "flex",
						flexDirection : "column",
						maxWidth      : 360,
					}}
					onClick={() => {
						closeModal = false;
					}}
				>
					<div
						className="buy-form-paragraph"
						style={{
							textAlign    : "center",
							marginTop    : 0,
							marginBottom : 12,
						}}
					>
						Verifica
					</div>
					<label style={{
						textAlign    : "center",
						marginBottom : 20,
					}}
					>
						Inserisci il codice che le abbiamo inviato via whatsapp
					</label>
					<form
						style={{
							display       : "flex",
							flexDirection : "column",
						}}
						onSubmit={(e) => {
							e.preventDefault();
							const bodyData = {
								deceasedID,
								smsCode: this.state.secureCode,
							};
							requests("post", "bookCode", bodyData)
								.then((result) => {
									if (result.response === "success") {
										this.setState({
											modal        : true,
											insertResult : "Successo",
											insertLabel  : (
												<span style={{ textAlign: "center" }}>
													Condoglianze aggiunte con successo
													{" "}
													<br />
													<strong>Sentitamente si ringrazia per la vicinanza dimostrata</strong>
												</span>
											),
										});
									} else {
										this.setState({
											modal        : true,
											insertResult : "Errore",
											insertLabel  : result.message,
										});
									}
								});
						}}
					>
						<TextField
							name="token"
							value={this.state.secureCode}
							onChange={(event) => {
								this.setState({ secureCode: event.target.value });
							}}
							variant="outlined"
							label="Codice di verifica"
						/>
						<div
							style={{
								display        : "flex",
								alignSelf      : "flex-end",
								flexDirection  : "row-reverse",
								justifyContent : "space-between",
								width          : "100%",
							}}
						>
							<button type="submit" disabled={this.state.sendLoading} style={{ alignSelf: "flex-end" }}>
								Invia
							</button>
							<button
								type="button"
								onClick={(e) => {
									e.preventDefault();
									this.setState({ showModalVerify: false });
								}}
							>
								Chiudi
							</button>
						</div>
					</form>
				</div>
			</div>
		);
	}

	render() {
		taboffset = window.innerWidth < 1005 ? 0 : 20;
		bgHeight  = window.innerWidth > 915 ? 70 * (10 - 1) + 935 + taboffset : 70 * (dedics.length - 1) + 1135;
		if (this.state.loggedUser && this.state.activeNotification) this.setState({ activeNotification: false });
		return (
			<>
				<div className="memorial-page-main-div home-background">
					{this.state.feedbackModal && (
						<FeedbackModal
							result={this.state.modalResult}
							close={() => {
								this.setState({
									feedbackModal : false,
									collapse      : this.state.modalResult === "Errore",
								});
								if (this.state.modalResult  !== "Errore") this.getData();
								// this.setState({ name: "", number: "", selectedValue: "" });
							}}
							label={this.state.modalText}
						/>
					)}
					{this.state.linkModal && (
						<FeedbackModal
							result="Attenzione"
							close={() => {
								this.setState({
									linkModal : false,
									collapse  : false,
								});
								this.getData();
							}}
							label={(
								<span>
									<p>
										Riceverai un link per confermare la tua identità e pubblicare le condoglianze:
									</p>
									&nbsp;
									<p>Se non riesci a cliccare sul link, devi aggiungere il numero ai tuoi contatti</p>
								</span>
							)}
						/>
					)}
					<div className="memorial-page-marble bottom-shadow">
						{!this.state.searching && (
							<div className="memorial-page-container-resized" style={{ justifyContent: "center" }}>
								{stab.hasPhoto && (
									<img
										className="memorial-page-photo-resized"
										style={{ marginLeft: 0 }}
										src={stab.mainPhoto}
										alt="caro"
									/>
								)}
								<div
									className="memorial-data-div memorial-book-centered-data"
									style={{ marginLeft: !stab.hasPhoto && 0 }}
								>
									<div className="memorial-anagraphics">
										<label className="memorial-name-label">{`${stab.name} ${stab.surname}`}</label>
										{window.innerWidth > 941 && stab.hasPhoto ? (
											<div
												style={{
													marginTop    : 8,
													marginBottom : 8,
												}}
												className="flex-column"
											>
												<label
													className="memorial-name-label font-size-25"
													style={{
														display        : "flex",
														justifyContent : "space-between",
														width          : 150,
														alignSelf      : "center",
													}}
												>
													<p
														style={{
															fontSize    : 28,
															marginRight : -7,
															marginLeft  : isSafari ? 3 : 1,
														}}
													>
														✶&nbsp;
													</p>
													<p>{stab.bornDate?.indexOf("-0") < 0 && stab.bornDate}</p>
												</label>
												<label
													className="memorial-name-label font-size-25"
													style={{
														display        : "flex",
														justifyContent : "space-between",
														width          : 150,
														alignSelf      : "center",
														alignItems     : "center",
													}}
												>
													<FontAwesomeIcon
														style={{
															fontSize     : 22,
															marginLeft   : 5,
															marginRight  : stab.deathDate?.indexOf("-0") < 0 ? 8 : 4,
															marginBottom : 1,
														}}
														icon={faCross}
													/>
													{stab.deathDate?.indexOf("-0") < 0 && stab.deathDate}
												</label>
											</div>
										) : (
											<label
												className="memorial-name-label font-size-25"
												style={{
													display        : "flex",
													minWidth       : 280,
													justifyContent : "center",
												}}
											>
												<p
													style={{
														textAlign: "center",
													}}
												>
													✶&nbsp;
													{stab.bornDate?.indexOf("-0") < 0 && stab.bornDate}
													{" "}
												</p>
												&nbsp; &nbsp; &nbsp;
												<p
													style={{
														textAlign: "center",
													}}
												>
													<FontAwesomeIcon
														style={{
															fontSize     : 18,
															marginRight  : stab.deathDate?.indexOf("-0") < 0 ? 8 : 4,
															marginBottom : 1,
														}}
														icon={faCross}
													/>
													{stab.deathDate?.indexOf("-0") < 0 && stab.deathDate}
												</p>
											</label>
										)}
										{stab.azienda && (
											<Link to={stabLink} className="memorial-azienda">
												<p>
													Questo servizio è stato distribuito da
													{` ${stab.azienda}`}
												</p>
											</Link>
										)}
									</div>
								</div>
							</div>
						)}
					</div>
					{!this.props.history.location.search || this.state.loaded ? (
						<div className="z1 memorial-book-inner-div" style={{ height: "auto" }}>
							<label style={{ cursor: "pointer" }} className="memorial-book-title book-page-label">
								Libro delle condoglianze
							</label>
							<div className="memorial-book-form bg-white" style={{ position: "relative" }}>
								<div
									onClick={() => this.setState({ collapse: !this.state.collapse })}
									style={{
										width   : "100%",
										cursor  : "pointer",
										padding : "0 20px",
									}}
								>
									<label
										className="memorial-book-form-label"
										style={{
											marginBottom : 15,
											width        : "100%",
											textAlign    : "center",
											cursor       : "pointer",
										}}
									>
										Clicca qui per lasciare le tue Condoglianze
									</label>
								</div>
								{this.state.collapse ? (
									<ArrowDropUp
										onClick={() => this.setState({ collapse: !this.state.collapse })}
										style={{
											position : "absolute",
											right    : 8,
											top      : 16,
											cursor   : "pointer",
										}}
									/>
								) : (
									<ArrowDropDown
										onClick={() => this.setState({ collapse: !this.state.collapse })}
										style={{
											position : "absolute",
											right    : 8,
											top      : 16,
											cursor   : "pointer",
										}}
									/>
								)}
								<Collapse
									in={this.state.collapse}
									className="memorial-book-form-fields"
									style={{
										width    : window.innerWidth - 60,
										maxWidth : 860,
									}}
								>
									<form
										style={{
											width   : "100%",
											display : "inline",
										}}
										onSubmit={(event) => {
											event.preventDefault();
											const formData = new FormData(event.target);
											const object   = {};
											// formData.set('editor', this.state.editor)
											formData.forEach((value, key) => {
												object[key] = value;
											});
											if (!this.state.sendLoading) this.send(object);
										}}
									>
										{!this.state.loggedUser && (
											<>
												&nbsp;
												<PhoneNumber
													memorialBook
													name="memorial-book-number"
													onBlur={(length) => {
														if (length > 4) {
															requests("post", "checkPhone", {
																telephone: this.state.number,
															})
																.then((response) => {
																	if (response.response === "success") {
																		this.setState({
																			activeNotification : false,
																			phoneExist         : true,
																		});
																	} else {
																		this.setState({
																			activeNotification : true,
																			phoneExist         : false,
																			notificationList   : this.state.initialNotificationList,
																		});
																	}
																});
														} else {
															this.setState({
																activeNotification : true,
																phoneExist         : false,
															});
														}
													}}
													error={this.state.allFormError && !(this.state.number?.length > 8)}
													infoLabel="Il numero di telefono sarà usato per confermare l’identità"
													bookValue={this.state.number}
													setValue={(value) => {
														this.setState({ number: value });
													}}
												/>
												&nbsp;
												{!this.state.loggedUser && (
													<div
														style={{
															display        : "flex",
															justifyContent : "center",
															alignItems     : "center",
															flexWrap       : "wrap",
															marginTop      : this.state.notificationList ? -20 : -25,
															marginBottom   : 40,
															width          : "100%",
														}}
													>
														<TextField
															style={{ flex: "1 1 290px" }}
															label="Nome"
															variant="outlined"
															name="accountName"
															error={
																this.state.accountNameError && !this.state.accountName
															}
															value={this.state.accountName || ""}
															onChange={this.handleNameSurnameUpdate}
														/>
														{window.innerWidth > 600 && <>&nbsp;</>}
														<TextField
															label="Cognome"
															variant="outlined"
															name="accountSurname"
															error={
																this.state.accountSurnameError
																&& !this.state.accountSurname
															}
															style={{
																flex      : "1 1 290px",
																marginTop : window.innerWidth <= 600 && 12,
															}}
															value={this.state.accountSurname || ""}
															onChange={this.handleNameSurnameUpdate}
														/>
														<input
															name="username"
															style={{ display: "none" }}
															value={this.state.username}
														/>
													</div>
												)}
											</>
										)}
										<div id="book-form" className="select-fix" style={{ flexDirection: "column" }}>
											<TextField
												className="memorial-book-form-field"
												id="standard-select-currency"
												select
												style={{
													width: "100%",
												}}
												label="Scegli una frase"
												value={this.state.selectedValue || 1}
												error={
													this.state.allFormError
													&& (!this.state.selectedValue
														|| (this.state.selectedValue && !this.state.other))
												}
												helperText={
													this.state.allFormError
													&& !this.state.selectedValue
													&& "Campo obbligatorio"
												}
												defaultValue="Scegli una frase"
												variant="outlined"
												onChange={(option, value) => {
													if (!this.state.loggedUser) {
														this.setState({
															selectedValue : value.props.value,
															other         :
																`${value.props.value}` === "1"
																	? ""
																	: value.props.value.replace("(nome)", stab.name),
														});
													} else {
														this.setState({
															selectedValue : option.target.value,
															other         :
																`${value.props.value}` === "1"
																	? ""
																	: option.target.value.replace("(nome)", stab.name),
														});
													}
												}}
											>
												<MenuItem key="other" value={1}>
													Scegli una frase...
												</MenuItem>

												{aforism.map((option, index) => (
													<MenuItem key={option + index} value={option}>
														{option.replace("(nome)", stab.name)}
													</MenuItem>
												))}
											</TextField>
											<TextField
												label="Personalizza la frase"
												className="textCenterChild"
												value={this.state.other || ""}
												multiline
												rows={4}
												onChange={(event) => {
													this.setState({ other: event.target.value });
												}}
												variant="outlined"
												style={{
													width     : "100%",
													marginTop : window.innerWidth > 941 && 20,
												}}
											/>
										</div>
										&nbsp;
										<TextField
											name="condolences-book-name"
											value={this.state.name}
											style={{
												width        : "100%",
												marginBottom : this.state.loggedUser && window.innerWidth > 941 && 20,
											}}
											variant="outlined"
											placeholder="Es. Antonio Rossi e famiglia"
											error={!this.state.name && this.state.allFormError}
											helperText={
												!this.state.name && this.state.allFormError && "Campo obbligatorio"
											}
											onChange={(event) => {
												this.setState({ name: event.target.value });
											}}
											label="Firmato da"
										/>
										{!this.state.loggedUser && !this.state.phoneExist && (
											<div
												style={{
													margin         : "auto",
													display        : "flex",
													justifyContent : "center",
													alignItems     : "center",
													flexDirection  : "column",
													marginTop      : 20,
													marginBottom   : 8,
												}}
											>
												<ModifyAccountNotification
													registration
													reset={this.state.resetNotificationList}
													sessionData={
														this.state?.contactData?.zone?.length > 0
														&& this.state.contactData.zone
													}
													submit={this.state.submit}
													onChangeFlag={(change) => {
														console.log({ change });
														this.setState({
															activeNotification : change,
															notificationList   : null,
															errorNotification  : true,
														});
													}}
													centered
													sendArray={(data) => {
														console.log({ data });
														this.setState({
															notificationList  : data,
															errorNotification : data.length <= 0,
														});
													}}
													type="registration"
												/>
												<input style={{ display: "none" }} name="condolenceFlag" value="1" />
											</div>
										)}
										{!this.state.disabled && (
											<div
												style={{
													display        : "flex",
													width          : "100%",
													justifyContent : "space-between",
													gap            : 8,
													flexDirection  : "column",
													marginTop      : 15,
												}}
											>
												{!this.state.loggedUser && (
													<AcceptTerms
														checkBox={this.state.checkBox || false}
														termsError={this.state.termsError || false}
														setChecked={(data) => this.setState({ checkBox: data })}
													/>
												)}
												<button
													className="memorial-book-submit"
													type="submit"
													style={{
														display  : this.state.disabled && "none",
														minWidth : 150,
														// marginTop: 20,
													}}
												>
													{" "}
													Invia
												</button>
											</div>
										)}
										&nbsp;
										{this.state.disabled && (
											<div
												style={{
													position        : "absolute",
													padding         : 20,
													display         : "flex",
													top             : 0,
													bottom          : 0,
													left            : 0,
													height          : "100%",
													width           : "100%",
													backgroundColor : "rgba(0,0,0,0.8)",
												}}
											>
												<label
													style={{
														textAlign : "center",
														color     : "white",
														fontSize  : 25,
														margin    : "auto",
														zIndex    : 300,
													}}
												>
													Le Condoglianze possono essere lasciate entro
													{` ${this.state.bookLimit || MEMORIAL_BOOK_DELAY}`}
													{" "}
													giorni dalla
													data di data di pubblicazione &nbsp;
													<a href={`memorial?${deceasedID}`}>
														Clicca qui per visitare la lapide digitale
													</a>
												</label>
											</div>
										)}
										<p className="underline font-size-14 italic" style={{ marginTop: 10 }}>
											La I.M.E. di Iacobucci Mimmo non è responsabile dei contenuti pubblicati ad
											opera dei visitatori o utenti registrati sul sito www.digitalmemorial.it
										</p>
									</form>
								</Collapse>
							</div>
							<div className="memorial-book-paginator" style={{ position: "relative" }}>
								<div
									className="memorial-book-submit refresh-button"
									onClick={() => {
										!this.state.gettingData && this.getBookData();
										this.setState({ collapse: false });
									}}
								>
									{!this.state.gettingData ? (
										<div style={{
											display    : "flex",
											alignItems : "center",
										}}
										>
											Aggiorna
											{" "}
											<Refresh />
										</div>
									) : (
										<CircularProgress style={{ color: "white" }} />
									)}
								</div>
								{/* {this.state.page !== 0 && ( */}
								{/*  <Back */}
								{/*    className="memorial-book-back" */}
								{/*    onClick={() => { */}
								{/*      this.changePage(-1); */}
								{/*    }} */}
								{/*  /> */}
								{/* )} */}
								{/* {this.state.page < parseInt(`${data.length / 10}`, 10) && ( */}
								{/*  <Forward */}
								{/*    style={{ marginLeft: "auto" }} */}
								{/*    onClick={() => { */}
								{/*      this.changePage(+1); */}
								{/*    }} */}
								{/*    className="memorial-book-back" */}
								{/*  /> */}
								{/* )} */}
							</div>

							{data && data.length > 0 && (
								<div
									className="memorial-book-dedics bg-white"
									style={{
										// maxWidth: window.innerWidth - 50,
										position: "relative",
									}}
								>
									<div className="memorial-book-table">
										{dedics.map((e) => (
											<div
												key={e.text + e.authorName + e.date + Math.random()}
												className="memorial-book-single-dedic"
											>
												<div
													style={{
														display       : "flex",
														flexDirection : "column",
														minWidth      : 130,
													}}
												>
													<label className="memorial-book-name">
														{" "}
														{e.authorName}
													</label>
													{window.innerWidth <= 830 && (
														<label className="memorial-book-name">
															{moment(e.time)
																.format("DD/MM/YYYY HH:mm:ss")}
														</label>
													)}
												</div>
												<label className="memorial-book-dedic" style={{ fontWeight: 100 }}>
													{" "}
													{e.text.replace("(nome)", stab.name)}
												</label>
												{window.innerWidth > 830 && (
													<label className="memorial-book-name" style={{ minWidth: 145 }}>
														{moment(e.time)
															.format("DD/MM/YYYY HH:mm:ss")}
													</label>
												)}
											</div>
										))}
									</div>
								</div>
							)}
						</div>
					) : (
						<div style={{
							height          : 400,
							backgroundColor : "white",
						}}
						/>
					)}
				</div>
				{/* <div className={'video-container-container'}> */}
				{/*    <div className={'memorial-page-container-resized video-book'} */}
				{/*         style={{position: "fixed", height: bgHeight}}> */}
				{/*        <video className={'memorial-book-bg'} */}
				{/*               src={'https://video.wixstatic.com/video/11062b_2042bb7351284f0f982e4787d37415e6/1080p/mp4/file.mp4'} */}
				{/*               loop={true} autoPlay={true} muted={true}/> */}

				{/*    </div> */}
				{/* </div> */}
				<OnCollapseChange
					collapse={this.state.collapse}
					onChange={() => {
						accountData = {};
						this.setState({
							accountName    : "",
							accountSurname : "",
							accountData    : {},
						});
					}}
				/>
				{this.state.showModalVerify && this.ModalVerify()}
				{this.state.modal && (
					<FeedbackModal
						close={() => {
							if (this.state.insertResult === "Successo") {
								this.setState({
									modal           : false,
									showModalVerify : false,
									collapse        : false,
								});
								window.location.reload();
							} else {
								this.setState({
									modal    : false,
									collapse : false,
								});
							}
						}}
						result={this.state.insertResult}
						label={this.state.insertLabel}
					/>
				)}
			</>
		);
	}
}

const OnCollapseChange = ({
	collapse,
	onChange,
}) => {
	useEffect(() => {
		console.log({ collapse });
		if (!collapse) onChange();
	}, [collapse]);
	return <div style={{ display: "none" }} />;
};
export default withContext(MemorialBook);
