import React, { Component, Fragment } from "react";
import { Redirect } from "react-router";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import DateFnsIt from "date-fns/locale/it";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Axios from "axios";
import { Button, MenuItem, Typography } from "@material-ui/core";
import withContext from "../../WithContextFile";
import "./Activate.css";
import qrCode from "../../assets/qrCode.png";
import validate, { locationValidation } from "../../formValidationService";
import AddressService, { buildCap } from "../FormComponents/AddressComponent";
import addressServiceGetData from "../FormComponents/addressService";
import UserFormData from "../FormComponents/userFormData";
import ContactPreferenceForm from "../FormComponents/ContactPreferencesForm";
import FormLabel from "../FormComponents/TermFormComponents";
import PhotoInput from "../FormComponents/PhotoInput";
import requests, { baseURL } from "../../Services/HttpServices";
import { Spinner } from "react-activity";
import listner from "reactjs-eventemitter";
import ExistsModal from "../FormComponents/ExistsModal";
import CKEditor from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import SearchBarMemorial from "../Memorial-activate/SearchBarAccount";
import CkEditorCustom from "../FormComponents/CkEditor";
import FeedbackModal from "../FormComponents/FeedbackModal";
import { readURL } from "../FormComponents/FileNamesComponent";
import { RemoveCircleOutline } from "@material-ui/icons";
import { Link } from "react-router-dom";
import viewToPlainText from "@ckeditor/ckeditor5-clipboard/src/utils/viewtoplaintext";

let disable      = false;
let previewData  = false;
let forceDisable = false;
let globalData;
let change       = false;

class Activate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loggedUser : null,
			radioValue : "",
			checkBox   : "",
			form       : {
				name              : "",
				surname           : "",
				CF                : "",
				phone             : "",
				email             : "",
				username          : "",
				city              : "",
				province          : "",
				route             : "",
				cap               : "",
				civic             : "",
				qrCode            : "",
				secureCode        : "",
				nameTo            : "",
				surnameTo         : "",
				bornDate          : null,
				deathDate         : null,
				region            : "",
				graveyardCity     : "",
				graveyardRegion   : "",
				graveyardProvince : "",
				nationality       : "105",
			},
			aphorism         : "",
			isPartner        : false,
			graveyardName    : "",
			graveyardAddress : "",
			formError        : {},
		};
	}

	componentDidMount() {
		listner.events.upload = [];
		listner.events.upload.length < 1
			&& listner.subscribe("upload", (callback) => {
				this.setState({ percentage: callback });
			});
		getCsrf().then((csrf) => {
			this.setState({ csrf_token: csrf });
		});
		requests("get", "getAphorism", "").then((data) => {
			if (data.data?.response === "success") {
				this.setState({ selectedSentence: data.data.data.aphorisms });
			}
		});
		previewData = this.props.location && this.props.location.query;
		if (previewData) {
			disable         = true;
			const temp      = this.state.form;
			temp.qrCode     = previewData.data.secondField || previewData.data.calories;
			temp.secureCode = previewData.data.type;
			this.setState({
				form: temp,
			});
			forceDisable = true;
			this.setState({ disable });
		}
	}

	setInitialData() {
		const temp = this.state.form;

		temp.name             = this.state.loggedUser.name;
		temp.surname          = this.state.loggedUser.surname || "Cognome";
		temp.email            = this.state.loggedUser.email;
		temp.username         = this.state.loggedUser.username;
		temp.phone            = this.state.loggedUser.telephone;
		temp.CF               = this.state.loggedUser.cf;
		temp.route            = this.state.userAddress.address;
		temp.civic            = this.state.userAddress.number;
		temp.city             = this.state.userAddress.city;
		temp.province         = this.state.userAddress.province;
		temp.cap              = this.state.userAddress.cap;
		temp.region           = this.state.userAddress.region;
		temp.nationality      = this.state.userAddress?.nationality || "105";
		temp.complete_address = this.state.userAddress.complete_address;
		let radioContact;
		try {
			radioContact = this.state.loggedUser.preferred_contact.type;
		} catch (e) {
			radioContact = "";
		}
		if (this.state.loggedUser.permissions && this.state.loggedUser.permissions.platformAccess == 2) {
			this.setState({ isPartner: true });
		}
		this.setState({ form: temp, radioValue: radioContact });
	}

	render() {
		if (!this.state.loggedUser && sessionStorage.getItem("User")) {
			try {
				this.setState({
					loggedUser: JSON.parse(sessionStorage.getItem("User")).userdata,
				});
				this.setState({
					userAddress: JSON.parse(sessionStorage.getItem("UserAddress")),
				});
			} catch (e) {

			}
		}
		if (this.state.loggedUser && this.state.form.name == "") {
			this.setInitialData();
			this.state.userAddress?.region
				&& addressServiceGetData("province", this.state.userAddress.region).then((resolve) => {
					this.setState({ provincie: resolve });
					addressServiceGetData("location", this.state.userAddress.province).then((resolve) => {
						const locSelected = resolve.find(
							(x) => x.ID === (this.state.form.location || this.state.form.city),
						);
						let capArr        = [];
						if (locSelected.capList) capArr = buildCap(locSelected);
						capArr.unshift(locSelected.cap);
						const temp = this.state.form;
						temp.cap   = this.state.userAddress?.cap;
						this.setState({ comuni: resolve, caps: capArr, form: temp });
					});
				});
		}
		return !this.props.context.user ? (
			<>
				{this.state.existModal /* this.existsModal() */ && (
					<ExistsModal
						email={this.state.form.email}
						username={this.state.modalname}
						deniedModal={() => {
							this.setState({ existModal: false });
							const temp = this.state.form;
							// temp.email = ''
							// temp.CF = ''
							document.getElementById("username").focus();

							this.setState({ form: temp });
						}}
						label={
							"Un account per l' indirizzo email inserito e` gia` esistente, si prega di inserire una password"
						}
					/>
				)}
				{this.state.feedbackModal && (
					<FeedbackModal
						result={this.state.modalResult}
						close={() => {
							this.state.modalFunction && this.state.modalFunction();
							this.setState({ feedbackModal: false });
						}}
						label={this.state.modalText}
					/>
				)}
				{this.state.uploading && (
					<div className="div-upload">
						<Spinner size={100} />
						<Typography className="label-upload">
							Attendi il caricamento dei files
							{" "}
							{parseInt(parseFloat(this.state.percentage) * 100)}
							%
						</Typography>
					</div>
				)}
				{this.state.openModalPhoto && this.photoLapideModal(globalData)}
				<div className="contact-container">
					&nbsp;
					<img alt="qrCode" src={qrCode} />
					<label className="activate-title">Attiva Lapide Digitale</label>
					<label className="activate-subtitle">
						<p>Questo servizio è dedicato al proprietario del QRCode.</p>
						Con l'attivazione del QrCode potrai creare una lapide digitale con i dati del defunto cioè
						cognome, nome, data di nascita e di morte, foto lapide, aforisma e luogo di sepoltura. Tale
						lapide sarà pubblicata sul portale e sarà disponibile a chiunque la vorrà visitare.
					</label>
					&nbsp;
					<div style={{ position: "relative" }} className="activate-form-div">
						<label className="death-data"> Dati del Proprietario della Lapide Digitale</label>
						<form
							className="activate-form"
							onSubmit={(event) => {
								event.preventDefault();
								const formData = new FormData(event.target);
								const object   = {};
								formData.set("activate-editor", this.state.editor);
								formData.forEach((value, key) => {
									object[key] = value;
								});
								formData.set("deceased-code", this.state.form.qrCode);
								globalData = formData;
								this.send(formData);
							}}
						>
							{!this.state.loggedUser && (
								<div className="no-register-overlay">
									<Link to="/login">Effettua l'accesso per continuare</Link>
								</div>
							)}
							<UserFormData
								partnerModify={this.state.loggedUser?.platformAccess == "2"}
								nationalityChange={() => this.setState({ reload: true })}
								openModalEmail={(data) => {
									this.setState({ existModal: true, modalname: data });
								}}
								names="owner"
								load={() => {
									this.setState({ load: true });
								}}
								form={this.state.form}
								formError={this.state.formError}
								disable={this.state.loggedUser !== null}
							/>
							{this.state.loggedUser && (
								<div style={{ display: "none" }}>
									<UserFormData
										nationalityChange={() => this.setState({ reload: true })}
										names="owner"
										form={this.state.form}
										formError={this.state.formError}
									/>
								</div>
							)}
							<label className="buy-form-paragraph">Dati indirizzo</label>
							<AddressService
								nationality={this.state.form.nationality || "105"}
								names="owner"
								state={this.state}
								form={this.state.form}
								formError={this.state.formError}
								province={this.state.provincie}
								comuni={this.state.comuni}
								caps={this.state.caps}
								forceDisable={forceDisable}
							/>
							{this.state.isPartner && (
								<FormControlLabel
									className="activate-checkbox"
									style={{ marginTop: 20 }}
									label="Spunta per prendere i dati da un necrologio presente"
									value={this.state.isObituary}
									control={(
										<Checkbox
											id="term-box"
											onChange={(event, checked) => this.setState({ isObituary: checked })}
											color="primary"
											name="is-obituary"
										/>
									)}
								/>
							)}
							<label className="death-data">Dati Defunto</label>
							<input style={{ display: "none" }} name="csrf_token" value={this.state.csrf_token} />
							<TextField
								className="activate-field"
								label="Codice QrCode *"
								name="deceased-code"
								value={this.state.form.qrCode}
								onChange={(event) => this.setAndValidateForm(event.target.value, "qrCode")}
								error={this.state.formError.qrCode}
								helperText={this.state.formError.qrCode && "Campo obbligatorio"}
								disabled={disable}
							/>
							{previewData && (
								<TextField
									className="activate-field"
									label="Codice QrCode *"
									name="deceased-code"
									style={{ display: "none" }}
									value={this.state.form.qrCode}
									onChange={(event) => this.setAndValidateForm(event.target.value, "qrCode")}
									error={this.state.formError.qrCode}
									helperText={this.state.formError.qrCode && "Campo obbligatorio"}
									// disabled={disable}
								/>
							)}
							<TextField
								className="activate-field"
								label="Codice di Verifica *"
								name="security-code"
								value={this.state.form.secureCode}
								onChange={(event) => this.setAndValidateForm(event.target.value, "secureCode")}
								error={this.state.formError.secureCode}
								helperText={this.state.formError.secureCode && "Campo obbligatorio"}
							/>
							{this.state.isObituary && (
								<SearchBarMemorial
									// emailAccount={
									//   !this.state.formError.email && this.state.form.email
									// }
									owner={this.state.loggedUser && this.state.loggedUser.user_id}
									name="obituary"
									setData={(data) => {
										if (data) {
											const temp                  = this.state.form;
											temp.obituaryID             = data.ID;
											temp.surnameTo              = data.surname || "";
											temp.nameTo                 = data.name || "";
											temp.bornDate               = data.dateOfBirth;
											temp.deathDate              = data.dateOfDeath;
											const { formError }         = this.state;
											formError.surnameTo         = false;
											formError.nameTo            = false;
											formError.bornDate          = false;
											formError.deathDate         = false;
											formError.graveyardCity     = false;
											formError.graveyardProvince = false;
											formError.graveyardRegion   = false;
											formError.graveyardName     = false;
											change                      = true;
											this.setState({
												formError,
												form             : temp,
												choichedObituary : true,
												editor           : data.obituaryNote || "",
												graveyardName    : data.graveyardName,
												graveyardAddress : data.graveAddress,
											});

											if (data.graveRegion) {
 addressServiceGetData("province", data.graveRegion).then((provincie) => {
													this.setState({ graveyardProvincie: provincie });
													if (data.graveProvince) {
 addressServiceGetData("location", data.graveProvince).then(
 	(comuni) => {
																this.setState({
																	graveyardComuni : comuni,
																	change          : true,
																});
 	},
 );
													}
 });
											}
										} else {
											const temp                  = this.state.form;
											temp.surnameTo              = "";
											temp.nameTo                 = "";
											temp.bornDate               = "";
											temp.deathDate              = "";
											temp.graveyardCity          = "";
											temp.graveyardProvince      = "";
											temp.graveyardRegion        = "";
											temp.graveyardName          = "";
											const { formError }         = this.state;
											formError.surnameTo         = true;
											formError.nameTo            = true;
											formError.bornDate          = true;
											formError.deathDate         = true;
											formError.graveyardCity     = true;
											formError.graveyardProvince = true;
											formError.graveyardRegion   = true;
											formError.graveyardName     = true;

											this.setState({
												formError,
												form             : temp,
												editor           : "",
												choichedObituary : false,
												graveyardName    : "",
												graveyardAddress : "",
												filename         : "",
											});
										}
									}}
									activate
								/>
							)}
							{(!this.state.isObituary || this.state.choichedObituary) && (
								<TextField
									className="activate-field"
									label="Nome del Defunto *"
									name="deceased-name"
									value={this.state.form.nameTo || ""}
									onChange={(event) => this.setAndValidateForm(event.target.value, "nameTo")}
									error={this.state.formError.nameTo}
									helperText={this.state.formError.nameTo && "Campo obbligatorio"}
								/>
							)}
							{(!this.state.isObituary || this.state.choichedObituary) && (
								<TextField
									className="activate-field"
									label="Cognome del Defunto *"
									name="deceased-surname"
									value={this.state.form.surnameTo || ""}
									onChange={(event) => this.setAndValidateForm(event.target.value, "surnameTo")}
									error={this.state.formError.surnameTo}
									helperText={this.state.formError.surnameTo && "Campo obbligatorio"}
								/>
							)}
							<div className="isFlexRow justify-between">
								<TextField
									className="activate-field"
									label="Età del defunto"
									name="obituary-deceased-age"
									value={this.state.age}
									onChange={(event) => {
										this.setState({ age: event.target.value });
									}}
								/>
								<TextField
									className="activate-field"
									select
									onChange={(event) => {
										this.setState({ gender: event.target.value });
									}}
									label="Sesso *"
									name="gender"
									value={this.state.gender || ""}
									error={!this.state.gender && this.state.genderError}
									helperText={!this.state.gender && this.state.genderError && "Campo obbligatorio"}
								>
									<MenuItem value="M">M</MenuItem>
									<MenuItem value="F">F</MenuItem>
								</TextField>
							</div>
							{(!this.state.isObituary || this.state.choichedObituary) && (
								<div className="activate-date-field">
									<MuiPickersUtilsProvider locale={DateFnsIt} utils={DateFnsUtils}>
										<KeyboardDatePicker
											className=" ml--5 date "
											inputVariant="filled"
											format="dd/MM/yyyy"
											margin="normal"
											onChange={(event, asd) => {
												this.setAndValidateForm(event, "bornDate");
											}}
											style={{ backgroundColor: "white" }}
											id="date"
											name="born-date"
											label="Data di nascita"
											value={
												this.state.form.bornDate != "0000-00-00"
													? this.state.form.bornDate
													: null
											}
										/>
										<KeyboardDatePicker
											className=" m-12 date"
											inputVariant="filled"
											format="dd/MM/yyyy"
											margin="normal"
											name="death-date"
											onChange={(event, asd) => {
												this.setAndValidateForm(event, "deathDate");
											}}
											id="date"
											label="Data di morte"
											value={
												this.state.form.deathDate != "0000-00-00"
													? this.state.form.deathDate
													: null
											}
										/>
									</MuiPickersUtilsProvider>
								</div>
							)}
							{
								<div id="graveyardDataID" className="buy-form-paragraph">
									Dati del Cimitero
								</div>
							}
							{
								<div className="Partner-row-fields">
									<TextField
										className="partner-field "
										label="Nome"
										helperText="ad esempio Cimitero di Roma"
										value={this.state.graveyardName}
										onChange={(event) => this.setState({ graveyardName: event.target.value })}
										name="graveyard-name"
									/>
								</div>
							}
							{
								<AddressService
									state={this.state}
									names="graveyard"
									form={this.state.form}
									formError={this.state.formError}
									province={this.state.graveyardProvincie}
									comuni={this.state.graveyardComuni}
									disableCap={false}
									disableRoute={false}
									setChange={() => {
										change = false;
									}}
									change={change}
									graveyardData
								/>
							}
							{
								<div className="Partner-row-fields">
									<TextField
										className="partner-field "
										label="Coordinate di sepoltura"
										value={this.state.graveyardAddress}
										onChange={(event) => this.setState({ graveyardAddress: event.target.value })}
										name="graveyard-address"
									/>
								</div>
							}
							<CkEditorCustom
								ckeditor
								selectSentence={this.state.selectedSentence}
								withSelect
								title="Aforisma"
								onChange={(editor) => {
									this.setState({ aphorism: editor.getData() });
								}}
								data={this.state.aphorism}
							/>
							<TextField
								name="deceased-aphorism"
								className="text-area"
								style={{ marginBottom: 20, display: "none" }}
								id="outlined-multiline-static"
								label="Aforismi"
								value={this.state.aphorism}
								multiline
								rows={8}
								variant="outlined"
							/>
							{!this.state.isObituary && (
								<div className="buy-form-paragraph" style={{ display: "none", marginBottom: 20 }}>
									{" "}
									Dati del funerale
								</div>
							)}
							{!this.state.isObituary && (
								<MuiPickersUtilsProvider locale={DateFnsIt} utils={DateFnsUtils}>
									<KeyboardDatePicker
										inputVariant="filled"
										format="dd/MM/yyyy"
										onChange={(event) => this.setState({ funeralDate: event })}
										style={{
											display         : "none",
											backgroundColor : "white",
											width           : "100%",
											marginBottom    : 16,
										}}
										id="date"
										name="funeral-date"
										value={this.state.funeralDate || null}
										label="Data del funerale"
									/>
								</MuiPickersUtilsProvider>
							)}
							{!this.state.isObituary && (
								<div
									className="ckeditor-activate"
									style={{
										maxWidth  : window.innerWidth - 50,
										marginTop : 20,
										display   : "none",
									}}
								>
									<CKEditor
										onInit={(editor) => {
											// Insert the toolbar before the editable area.
											editor.ui
												.getEditableElement()
												.parentElement.insertBefore(
													editor.ui.view.toolbar.element,
													editor.ui.getEditableElement(),
												);
										}}
										onChange={(event, editor, data) => {
											this.setState({ editor: editor.getData() });
										}}
										editor={DecoupledEditor}
										data={this.state.editor || "<p></p>"}
										config={{
											toolbar: [
												"heading",
												"Cut",
												"Copy",
												"Paste",
												"|",
												"alignment",
												"bold",
												"italic",
												"FontSize",
												"Fontfamily",
												"TextColor",
												"link",
												"numberedList",
												"bulletedList",
												"|",
												"undo",
												"redo",
												"|",
												"underline",
												"subscript",
												"superscript",
											],
											heading: {
												options: [
													{
														model : "paragraph",
														title : "Paragraph",
														class : "ck-heading_paragraph",
													},
													{
														model : "heading1",
														view  : "h1",
														title : "Heading 1",
														class : "ck-heading_heading1",
													},
													{
														model : "heading2",
														view  : "h2",
														title : "Heading 2",
														class : "ck-heading_heading2",
													},
													{
														model : "heading3",
														view  : "h3",
														title : "Heading 3",
														class : "ck-heading_heading3",
													},
												],
											},
										}}
									/>
								</div>
							)}
							<PhotoInput
								name="lapide"
								setFileName={(name, photo) => {
									this.setState({ filename: name });
									readURL(photo).then((data) => this.setState({ photo: data }));
								}}
							/>
							<div className="file-upload">
								<label className="pointer" htmlFor="photo-lapide" color="blue" title="">
									<p className="pointer-label">
										{this.state.filename || (
											<>
												<b className="bold-underline" style={{ color: "black" }}>
													Carica foto del defunto
												</b>
												{" "}
												per la lapide digitale (Max 15MB per foto)'
											</>
										)}
									</p>
								</label>
							</div>
							{this.state.photo && (
								<div className="is-relative">
									<img
										style={{
											maxHeight : 300,
											margin    : "20px auto auto",
											maxWidth  : 208,
											minHeight : 300,
											minWidth  : 200,
										}}
										src={this.state.photo}
										className=" memorial-page-photo"
									/>
									<RemoveCircleOutline
										className="remove-photo"
										onClick={() => {
											this.setState({
												filename : "",
												photo    : null,
												erased   : true,
											});
											document.getElementById("photo-lapide").value = null;
										}}
									/>
								</div>
							)}
							&nbsp;
							<FormControlLabel
								className="activate-checkbox"
								value={this.state.checkBox}
								control={(
									<Checkbox
										id="term-box"
										onChange={(event, checked) => this.setState({ checkBox: checked })}
										color="primary"
										name="checkedC"
									/>
								)}
								label={FormLabel()}
							/>
							{!this.state.checkBox && this.state.termsError && (
								<label
									style={{
										color      : "red",
										textAlign  : "left",
										margin     : 10,
										marginLeft : 30,
									}}
								>
									Devi accettare i termini
								</label>
							)}
							<ContactPreferenceForm
								email={this.state.form.email}
								phone={this.state.form.phone}
								handler={(event, value) => {
									this.setState({ radioValue: value });
								}}
								logged={this.state.loggedUser !== null}
								value={this.state.radioValue}
								error={this.state.radioError && !this.state.radioValue}
								label="Per eventuali comunicazioni"
							/>
							<div className="bottom-activate-form">
								<label>
									L'ATTIVAZIONE DI QUESTO SERVIZIO AVVERRA' SOLO DOPO LA CONFERMA DEI DATI VIA
									<b> E-MAIL</b>
									{" "}
									O
									<b>WHATSAPP</b>
								</label>
								<button onClick={(event) => {}} className="activate-submit">
									Procedi
								</button>
							</div>
						</form>
					</div>
					&nbsp;
				</div>
			</>
		) : (
			<Redirect to="/products" />
		);
	}

	send(formdata) {
		let { formError } = this.state;
		let error         = false;
		for (const property in this.state.form) {
			if (this.state.loggedUser && property === "email") {
			} else if (
				(property !== "phone" || (this.state.form.phone && this.state.form.phone.length >= 9))
				&& (property !== "email" || this.state.form.email !== "")
				&& (property !== "CF" || this.state.form.nationality == "105")
			) {
				if (
					!(property === "address" || property === "location" || property === "complete_address")
					|| this.state.form.nationality != "105"
				) {
					if (property !== "graveyardName") {
						formError = validate(this.state.form[property], property, formError);

						error = error || formError[property];
					}
				}
			}
		}
		if (!this.state.gender) {
			error = true;
			this.setState({ genderError: true });
		}
		formError = locationValidation(this.state.form, formError);
		if (`${this.state.loggedUser?.platformAccess}` === "2") {
			formError.CF = this.state.form.CF !== "" && !(this.state.form.CF?.length === 16);
			error        = error || formError.CF;
		} else {
			formError.CF = this.state.form.CF?.length !== 16;
			error        = error || formError.CF;
		}
		this.setState({ formError });
		if (!this.state.radioValue) {
			this.setState({ radioError: true });
		} else this.setState({ radioError: false });

		if (!this.state.checkBox) {
			this.setState({ termsError: true });
		} else this.setState({ termsError: false });

		if (!this.state.filename) {
			this.setState({ fileError: true });
		} else this.setState({ fileError: false });

		if (!(error || !this.state.checkBox || !this.state.radioValue)) {
			if (!this.state.filename) {
				this.setState({ openModalPhoto: true });
			} else {
				this.sendData(formdata);
			}
		} else if (formError.graveyardCity || formError.graveyardProvince || formError.graveyardRegion) document.getElementById("graveyardDataID").scrollIntoView();
	}

	sendData(formdata) {
		if (!this.state.form.phone) {
			formdata.delete("phone");
			formdata.delete("owner-telephone");
		}
		if (this.state.form?.obituaryID) {
			formdata.set("obituaryID", this.state.form.obituaryID);
		}
		getCsrf().then((csrf) => {
			formdata.set("csrf_token", csrf);
			this.setState({ uploading: true });
			requests("sendform", "activate", formdata).then((data) => {
				this.setState({ uploading: false });
				if (data.result === "success") {
					this.props.history.push("/post-activate");
				} else if (data.message) {
					this.setState({
						feedbackModal : true,
						modalResult   : "Errore",
						modalText     : data.message,
					});
				} else {
 this.setState({
 	feedbackModal : true,
 	modalResult   : "Errore",
 	modalText     : "Errore imprevisto riprovare piu` tardi",
 });
				}
			});
		});
	}

	setAndValidateForm(data, attribute) {
		const { form }    = this.state;
		let { formError } = this.state;
		form[attribute]   = data;
		this.setState({ form });
		formError = validate(data, attribute, formError);
		this.setState({ formError });
	}

	photoLapideModal(formdata) {
		let close2 = true;
		return (
			<div
				className="modal-bg"
				onClick={() => {
					if (close2) {
						this.setState({ openModalPhoto: false });
						// this.props.history.push('admin-panel')
					} else close2 = !close2;
				}}
			>
				<div
					className="inner"
					onClick={() => {
						close2 = false;
					}}
				>
					<Typography style={{ fontSize: 17 }}>Attenzione!!</Typography>
					<Typography style={{ fontSize: 12, marginTop: 15 }}>
						Non hai caricato la foto della lapide, desideri continuare?
					</Typography>
					<div
						style={{
							flexDirection  : "row",
							display        : "flex",
							justifyContent : "space-between",
						}}
					>
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								this.setState({ openModalPhoto: false });
								this.sendData(globalData);
							}}
						>
							SI
						</Button>
						<Button
							color="primary"
							style={{ marginLeft: 20 }}
							onClick={() => {
								this.setState({ openModalPhoto: false });
								// this.props.history.push('admin-panel')
							}}
							variant="contained"
						>
							NO
						</Button>
					</div>
				</div>
			</div>
		);
	}
}

function getCsrf() {
	return new Promise((result, err) => Axios.get(`${baseURL}api/auth/securitytoken`)
			.then((resolve) => {
				if (resolve != null) {
					result(resolve.data._csrf);
				} else result({ DATA: "NO DATA" });
			})
			.catch((err) => result({ err })));
}

export default withContext(Activate);
