import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from '@material-ui/lab/Autocomplete';
import addressServiceGetData from './addressService';
import validate from '../../formValidationService';
import RegionData from '../../assets/RegionData';
import { regions } from '../../Services/RandomUtils';

const gettingRegion = false;

function AddressService(props) {
	const { names } = props;
	const { form } = props;
	const { formError } = props;
	const [regOpen, setRegOpen] = useState(true);
	const [prOpen, setPrOpen] = useState(true);
	const [locOpen, setLocOpen] = useState(true);
	const [regioni, setRegioni] = useState(null);
	const [province, setProvince] = useState(props.provincie);
	const [comuni, setComuni] = useState(props.comuni);
	const [loaded, setLoaded] = useState(false);
	const [regionOpen, setRegionOpen] = useState(false);
	const [provinceOpen, setProvinceOpen] = useState(false);
	const [locationOpen, setLocationOpen] = useState(false);
	const [removeFocus, setRemoveFocus] = useState(false);
	const [reload, setReload] = useState(false);
	const [cap, setCap] = useState('');
	const [multipleFix, setMultipleFix] = useState(false);
	const [caps, setCaps] = useState(props.caps || []);
	const [selectedLocation, setSelectedLocation] = useState(false);
	props.province && !province && setProvince(props.province);
	props.comuni && !comuni && setComuni(props.comuni);
	useEffect(() => {
		if (props.caps) {
			const temp = [];
			props.caps.forEach(e => {
				if (`${e}` !== '0') temp.push(`${e}`);
			});
			props.multiple && temp.length > 0 && temp.unshift('-1');
			setCaps(temp);
		}
		if (props.multiple && selectedLocation) {
			setCap(selectedLocation.cap);
			let arrCap = [];
			if (selectedLocation.capList) {
				const tempArr = buildCap(selectedLocation);
				if (tempArr?.length > 0) arrCap = tempArr;
			}
			arrCap.unshift(`${selectedLocation.cap}`);
			if (selectedLocation.capList && props.multiple) {
				arrCap.unshift('-1');
			}
			setCaps(arrCap);
		}
	}, [props.caps, props.caps?.length]);

	if (!regioni && !gettingRegion && !multipleFix) {
		setRegioni(regions);
	}
	useEffect(() => {
		props.multiple &&
			multipleOnChange({
				cap: !props.graveyardData ? form.cap : form.graveyardCap
			});
	}, [form.cap, form.graveyardCap]);

	function setAndValidateForm(data, attribute, isNumeric, form, formError, exactLength) {
		form[attribute] = data;
		formError = validate(data, attribute, formError, isNumeric);
		if (exactLength && form[attribute].length !== exactLength) formError[attribute] = true;
		setLoaded(!loaded);
	}

	useEffect(() => {
		if (
			((props.state && !props.state.loggedUser) || form.province === form.civic) &&
			form.nationality == '105' &&
			!props.multiple
		) {
			form.province = '';
			form.address = '';
			form.city = '';
			form.location = '';
			form.region = '';
			form.civic = '';
			form.route = '';
			form.cap = '';
			form.complete_address = '';
		}
		if (form.nationality != '105' && form.complete_address && !props.multiple) {
			setAndValidateForm(form.complete_address, 'route', false, form, formError);
			setAndValidateForm(form.complete_address, 'civic', false, form, formError);
			setAndValidateForm(form.complete_address, 'region', false, form, formError);
			setAndValidateForm(form.complete_address, 'location', false, form, formError);
			setAndValidateForm(form.complete_address, 'province', false, form, formError);
			setAndValidateForm(form.complete_address, 'city', false, form, formError);
			setAndValidateForm(form.complete_address, 'complete_address', false, form, formError);
			setAndValidateForm(form.complete_address, 'address', false, form, formError);
			setAndValidateForm(form.complete_address, 'cap', false, form, formError);
			if (!props.graveyardData) {
				setAndValidateForm(form.cap, 'cap', false, form, formError);
				setCap(form.cap);
			}
		}
		setReload(!reload);
	}, [form.nationality, props.nationality]);
	const handleRegionOpen = () => {
		setRegionOpen(true);
	};
	const handleRegionClose = () => {
		setRegionOpen(false);
	};
	const handleProvinceOpen = () => {
		setProvinceOpen(true);
	};
	const handleProvinceClose = () => {
		setProvinceOpen(false);
	};
	const handleLocationOpen = () => {
		setLocationOpen(true);
	};
	const handleLocationClose = () => {
		setLocationOpen(false);
	};
	function multipleOnChange() {
		if (!form.province) form.province = '';
		if (!form.location) form.location = '';
		if (!form.city) form.city = '';
		props.setToSend && props.setToSend();
	}
	const handleRegionChange = event => {
		setRegOpen(false);
		setRegionOpen(false);
		setAndValidateForm(
			event.target.value,
			props.graveyardData ? 'graveyardRegion' : 'region',
			false,
			form,
			formError
		);
		props.multiple && multipleOnChange({ region: event.target.value });
		if (props.form.region || props.form.graveyardRegion) {
			addressServiceGetData('province', event.target.value).then(data => {
				if (!props.graveyardData) {
					form.province = '';
					form.city = '';
					form.cap = '';
				} else {
					form.graveyardProvince = '';
					form.graveyardCity = '';
					form.graveyardCap = '';
				}
				setProvince(data);
				setComuni(null);
				setCap('');
				setCaps([]);
				setSelectedLocation(false);
				document.activeElement.blur();
				setPrOpen(true);
				// document.getElementById('regionField').blur()
				!props.graveyardData
					? !props.multiple && document.getElementById(`${names}-province`).focus()
					: !props.multiple && document.getElementById('graveyardprovince').focus();
			});
		} else setProvince(props.province);
	};
	const handleProvinceChange = event => {
		setAndValidateForm(
			event.target.value,
			props.graveyardData ? 'graveyardProvince' : 'province',
			false,
			form,
			formError
		);
		setPrOpen(false);
		setProvinceOpen(false);

		props.multiple && multipleOnChange({ province: event.target.value });
		if (props.form.province || props.form.graveyardProvince) {
			addressServiceGetData('location', event.target.value).then(data => {
				if (props.graveyardData) {
					form.graveyardCap = '';
					form.graveyardCity = '';
				} else {
					form.city = '';
					form.cap = '';
				}
				setComuni(data);
				setCap('');
				setCaps([]);
				setSelectedLocation(false);
				document.activeElement.blur();
				// document.getElementById('province').blur()
				!props.graveyardData
					? !props.multiple && document.getElementById(`${names}-location`).focus()
					: !props.multiple && document.getElementById('graveyardlocation').focus();
			});
		} else setComuni(props.comuni);
	};
	const handleComuniChange = event => {
		setCaps([]);
		setSelectedLocation(false);
		setLocOpen(false);
		setLocationOpen(false);
		setAndValidateForm(event.target.value, props.graveyardData ? 'graveyardCity' : 'city', false, form, formError);
		props.multiple && multipleOnChange({ location: event.target.value });
		let locationSelected;
		if (props.graveyardData) locationSelected = comuni.find(x => x.ID === form.graveyardCity);
		else locationSelected = comuni.find(x => x.ID === form.city);
		setSelectedLocation(locationSelected);
		setAndValidateForm(locationSelected.cap, props.graveyardData ? 'graveyardCap' : 'cap', true, form, formError);
		setCap(locationSelected.cap);
		let arrCap = [];
		if (locationSelected.capList) {
			const tempArr = buildCap(locationSelected);
			if (tempArr?.length > 0) arrCap = tempArr;
		}
		arrCap.unshift(`${locationSelected.cap}`);
		if (locationSelected.capList && props.multiple) {
			arrCap.unshift('-1');
		}
		if (props.index === 0 && props.addRow && props.length <= 1) props.addRow();
		setCaps(arrCap);
		document.activeElement.blur();
		!props.graveyardData
			? !props.multiple && document.getElementById(`${names}-location`).blur()
			: !props.multiple && document.getElementById('graveyardlocation').blur();
	};
	return (
		<div id="address" style={{ width: props.fullWidth && '100%' }}>
			<div
				className="Partner-row-fields"
				style={{ flexDirection: 'column' }}
				onClick={() => {
					setPrOpen(false);
					setRegOpen(false);
					setLocOpen(false);
				}}
			>
				{/* {!props.graveyardData && props.nationality == '105' && */}
				{/* <TextField name={'cf'} id={'cf'} label={'Codice fiscale'} */}
				{/*           helperText={formError.CF && 'Campo non valido'} */}
				{/*           error={formError.CF} value={form.CF} */}
				{/*           onChange={(event) => */}
				{/*               setAndValidateForm(event.target.value, 'CF', false, form, formError, 16) */}
				{/*           }> */}
				{/* </TextField>} */}
				{(props.nationality == '105' ||
					props.graveyardData ||
					!props.nationality ||
					props.nationality == '') && (
					<div
						className={`Partner-row-fields toColumn ${
							props.req && (props.index === 0 || !props.index) && 'req'
						}`}
					>
						<div className="region-city-fix w-full">
							<TextField
								className="partner-field field180"
								id="regionField"
								error={
									props.req && !props.forceErrorWithReq
										? props.error
										: props.graveyardData
										? formError.graveyardRegion
										: formError.region
								}
								label={props.index === 0 || (!props.req && !props.obituary) ? 'Regione *' : 'Regione'}
								onBlur={() => {
									setPrOpen(true);
									setRegOpen(true);
									setLocOpen(true);
								}}
								value={props.graveyardData ? form.graveyardRegion : form.region || ''}
								name={`${names}-region`}
								onFocus={(event, a) => {
									regOpen && setRegionOpen(true);
								}}
								SelectProps={{
									open: regionOpen,
									onOpen: handleRegionOpen,
									onClose: handleRegionClose,
									onChange: handleRegionChange
								}}
								helperText={
									props.graveyardData
										? formError.graveyardRegion
											? 'Campo obbligatorio'
											: ''
										: formError.region
										? 'Campo obbligatorio'
										: ''
								}
								disabled={props.forceDisable}
								select
								variant={props.variant || 'standard'}
							>
								{regioni ? (
									regioni.map(e => (
										<MenuItem
											key={e.ID}
											onClick={() => {
												setRegOpen(false);
											}}
											value={e.ID}
										>
											{e.name}
										</MenuItem>
									))
								) : (
									<MenuItem>No connection</MenuItem>
								)}
							</TextField>
							<TextField
								className="partner-field field180 ml-20"
								disabled={!province || props.forceDisable}
								error={
									props.req && !props.forceErrorWithReq
										? props.error
										: props.graveyardData
										? formError.graveyardProvince
										: formError.province
								}
								label={
									props.index === 0 || (!props.req && !props.obituary) ? 'Provincia *' : 'Provincia'
								}
								id={props.graveyardData ? 'graveyardprovince' : `${names}-province`}
								onBlur={() => {
									setPrOpen(true);
									setRegOpen(true);
									setLocOpen(true);
								}}
								SelectProps={{
									open: provinceOpen,
									onOpen: handleProvinceOpen,
									onClose: handleProvinceClose,
									onChange: handleProvinceChange
								}}
								onFocus={(event, a) => {
									prOpen && setProvinceOpen(true);
								}}
								value={props.graveyardData ? form.graveyardProvince : form.province || ''}
								name={`${names}-province`}
								helperText={
									props.graveyardData
										? formError.graveyardProvince
											? 'Campo obbligatorio'
											: ''
										: formError.province
										? 'Campo obbligatorio'
										: ''
								}
								select
								variant={props.variant || 'standard'}
							>
								{province?.length > 0 ? (
									province.map(e => (
										<MenuItem
											key={e.ID}
											onClick={() => {
												setPrOpen(false);
											}}
											value={e.ID}
										>
											{e.name}
										</MenuItem>
									))
								) : (
									<MenuItem>No connection</MenuItem>
								)}
							</TextField>
						</div>
						<div className="region-city-fix">
							<TextField
								className="partner-field small minField100"
								onBlur={() => {
									setPrOpen(true);
									setRegOpen(true);
									setLocOpen(true);
								}}
								SelectProps={{
									open: locationOpen,
									onOpen: handleLocationOpen,
									onClose: handleLocationClose,
									onChange: handleComuniChange
								}}
								onFocus={() => {
									locOpen && setLocationOpen(true);
								}}
								error={
									props.req && !props.forceErrorWithReq
										? props.error
										: props.graveyardData
										? formError.graveyardCity
										: formError.city
								}
								disabled={!comuni || props.forceDisable}
								name={`${names}-location`}
								value={props.graveyardData ? form.graveyardCity : form.city || ''}
								label={props.index === 0 || (!props.req && !props.obituary) ? 'Città *' : 'Città'}
								id={props.graveyardData ? 'graveyardlocation' : `${names}-location`}
								helperText={
									props.graveyardData
										? formError.graveyardCity
											? 'Campo obbligatorio'
											: ''
										: formError.city
										? 'Campo obbligatorio'
										: ''
								}
								select
								variant={props.variant || 'standard'}
							>
								{comuni ? (
									comuni.map(e => (
										<MenuItem key={e.ID} onClick={() => setLocOpen(false)} value={e.ID}>
											{e.name}
										</MenuItem>
									))
								) : (
									<MenuItem>No connection</MenuItem>
								)}
							</TextField>
							<Autocomplete
								style={{
									paddingRight: 0,
									display: 'flex',
									alignItems: 'flex-end',
									alignContent: 'flex-end',
									width: 100,
									minWidth: 80,
									marginLeft: 20
								}}
								onChange={(event, value, reason, details) => {
									if (!props.graveyardData) form.cap = value;
									if (props.graveyardData) form.graveyardCap = value;
									props.multiple && multipleOnChange({ cap: value });

									setCap(value);
								}}
								// debug
								disabled={props.forceDisable || !(caps?.length > 0)}
								debug
								name={`${names}-cap`}
								options={caps}
								value={(props.graveyardData ? form.graveyardCap : form.cap) || cap}
								autoHighlight
								getOptionLabel={option => (option !== '-1' ? option : 'Tutti')}
								renderOption={option => (
									<>
										<span>{option !== '-1' ? option : 'Tutti'}</span>
									</>
								)}
								renderInput={params => (
									<TextField
										style={{
											paddingRight: 0,
											display: 'flex',
											alignItems: 'flex-end',
											alignContent: 'flex-end'
										}}
										name={`${names}-cap`}
										error={
											formError.cap ||
											(props.req && !props.forceErrorWithReq
												? props.error
												: props.graveyardData
												? formError.graveyardCity
												: formError.city)
										}
										helperText={
											formError.cap ||
											(props.req && !props.forceErrorWithReq
												? props.error
												: props.graveyardData
												? formError.graveyardCity
												: formError.city)
												? 'Obbligatorio'
												: ''
										}
										{...params}
										label="Cap"
									/>
								)}
							/>
						</div>
					</div>
				)}
			</div>
			{!props.disableRoute && !props.graveyardData && props.nationality == '105' && !props.multiple && (
				<div className="Partner-row-fields">
					<TextField
						className="partner-field "
						id="addresss"
						onChange={event => {
							setAndValidateForm(event.target.value, 'route', false, form, formError);
						}}
						error={formError.route}
						label="Indirizzo *"
						value={form.route}
						helperText={formError.route ? 'Campo obbligatorio' : ''}
						name={`${names}-address`}
						disabled={props.forceDisable}
						focused={removeFocus}
						variant={props.variant || 'standard'}
					/>
					<TextField
						className="partner-field small"
						onChange={event => {
							setAndValidateForm(event.target.value, 'civic', false, form, formError);
						}}
						onFocus={() => {
							setPrOpen(true);
							setRegOpen(true);
							setLocOpen(true);
						}}
						value={form.civic}
						error={formError.civic}
						label="N *"
						helperText={formError.civic ? 'Campo obbligatorio' : ''}
						disabled={props.forceDisable}
						name={`${names}-number`}
						variant={props.variant || 'standard'}
					/>
				</div>
			)}
			{props.nationality != '105' && !props.multiple && !props.graveyardData && (
				<TextField
					fullWidth
					className="partner-field "
					id="complete_address"
					onChange={event => {
						setAndValidateForm(event.target.value, 'route', false, form, formError);
						setAndValidateForm(event.target.value, 'civic', false, form, formError);
						setAndValidateForm(event.target.value, 'region', false, form, formError);
						setAndValidateForm(event.target.value, 'location', false, form, formError);
						setAndValidateForm(event.target.value, 'province', false, form, formError);
						setAndValidateForm(event.target.value, 'city', false, form, formError);
						setAndValidateForm(event.target.value, 'complete_address', false, form, formError);
						setAndValidateForm(event.target.value, 'address', false, form, formError);
						setAndValidateForm(event.target.value, 'cap', false, form, formError);
					}}
					error={formError.complete_address}
					label="Complete address *"
					value={form.complete_address}
					helperText={formError.complete_address ? 'Campo obbligatorio' : ''}
					name={`${names}-complete_address`}
					disabled={props.forceDisable}
					focused={removeFocus}
					variant={props.variant || 'standard'}
				/>
			)}
		</div>
	);
}

export function buildCap(locationSelected) {
	const lowerBound = parseInt(locationSelected.capList[0].minCap);
	const upperBound = parseInt(locationSelected.capList[0].maxCap);
	const tempArr = Array.from(new Array(upperBound - lowerBound), (x, i) => `${i + lowerBound}`);
	return tempArr;
}
export function buildCapNoLocation(capList) {
	if (capList) {
		const lowerBound = parseInt(capList[0].minCap, 10);
		const upperBound = parseInt(capList[0].maxCap, 10);
		if (upperBound > lowerBound) {
			const tempArr = Array.from(new Array(upperBound - lowerBound), (x, i) => `${i + lowerBound}`);
			return tempArr;
		}
		return false;
	}
	return false;
}

export default AddressService;
