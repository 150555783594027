/* eslint-disable react/no-access-state-in-setstate */
import React, { Component, Fragment } from "react";
import "../../Activate/Activate.css";
import TextField from "@material-ui/core/TextField";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import DateFnsIt from "date-fns/locale/it";
import Axios from "axios";
import { Spinner } from "react-activity";
import { Button, MenuItem, Typography } from "@material-ui/core";
import CKEditor from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import { Clear, RemoveCircleOutline } from "@material-ui/icons";
import moment from "moment";
import CkEditorCustom from "../../FormComponents/CkEditor";
import FeedbackModal from "../../FormComponents/FeedbackModal";
import requests, { baseURL, getCsrf } from "../../../Services/HttpServices";
import PhotoInput from "../../FormComponents/PhotoInput";
import UserFormData from "../../FormComponents/userFormData";
import addressServiceGetData from "../../FormComponents/addressService";
import AddressService, { buildCap } from "../../FormComponents/AddressComponent";
import validate from "../../../formValidationService";
import ObituaryExistModal from "./obituaryExistModal";
import { readURL } from "../../FormComponents/FileNamesComponent";
import MultipleReferringPlace from "../../FormComponents/MultipleReferringPlace";

let globalData;
let editor;
let objrefplace;

class AddDeceasedPoster extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loggedUser : null,
			form       : {
				name      : "",
				surname   : "",
				CF        : "",
				email     : "",
				nameTo    : "",
				surnameTo : "",
				bornDate  : null,
				deathDate : null,
			},
			age              : "",
			editor           : "<p></p>",
			graveyardName    : "",
			graveyardAddress : "",
			formError        : {},
		};
	}

	componentDidMount() {
		this.props.modify && this.setModifyData(this.props.modify);
	}

	setModifyData(data) {
		const { form }         = this.state;
		form.nameTo            = data.deceasedName;
		form.surnameTo         = data.deceasedSurname;
		form.nameTo            = data.deceasedName;
		form.graveyardRegion   = data.graveyardRegion;
		form.graveyardProvince = data.graveyardProvince;
		form.graveyardCity     = data.graveyardCity;
		this.setReferringPlaceData(data);
		if (data.bornDate != "0000-00-00" && data.bornDate != "" && data.bornDate != false) {
			form.bornDate =				data.bornDate != "0000-00-00" && data.bornDate != "" && data.bornDate != false
					? new Date(data.bornDate).getTime()
					: null;
		}
		if (data.deathDate != "0000-00-00" && data.deathDate != "" && data.deathDate != false) {
			form.deathDate =				data.deathDate != "0000-00-00" && data.deathDate != "" && data.deathDate != false
					? new Date(data.deathDate).getTime()
					: null;
		}
		this.setState({
			age              : data.staticAge,
			aphorism         : data.aphorism,
			editor           : data.editorData.toString(),
			graveyardName    : data.graveyardName,
			graveyardAddress : data.graveyardAddress,
			gender           : data.gender,
			filename         : data.filename,
			photod           : data.fileurl,
			funeralDate      :
				data.funeralDate && data.funeralDate.indexOf("-00") < 0 ? moment(data.funeralDate)
					.toDate() : null,
		});
		this.setState({ load: !this.state.load });
		editor = data.editorData.toString();
	}

	setInitialData() {
		const temp = this.state.form;

		temp.name     = this.state.loggedUser.name;
		temp.username = this.state.loggedUser.username;
		temp.surname  = this.state.loggedUser.surname || "Cognome";
		temp.email    = this.state.loggedUser.email;
		temp.phone    = this.state.loggedUser.telephone;
		temp.CF       = this.state.loggedUser.cf;
		this.setState({ form: temp });
	}

	setReferringPlaceData(data) {
		this.setState({ referringPlace: data.referringPlace });
	}

	render() {
		if (this.state["referring-place"] && JSON.parse(this.state["referring-place"])) {
			objrefplace = JSON.parse(this.state["referring-place"]);
		}
		if (!this.state.loggedUser && sessionStorage.getItem("User")) {
			try {
				this.setState({
					loggedUser: JSON.parse(sessionStorage.getItem("User")).userdata,
				});
				this.setState({
					// userAddress: JSON.parse(sessionStorage.getItem("UserAddress")),
				});
			} catch (e) {

			}
		}
		let placeError = true;
		if (this.state["referring-place"] && JSON.parse(this.state["referring-place"])) {
			const objects = JSON.parse(this.state["referring-place"]);
			if (objects?.length > 0) {
				objects.forEach((e) => {
					if (e.region && e.region !== "") placeError = placeError && e.city;
				});
			}
		}
		if (this.state.loggedUser && this.state.form.name == "") {
			this.setInitialData();
			this.props.modify
			&& addressServiceGetData("province", this.props.modify.graveyardRegion)
				.then((resolve) => {
					this.setState({
						provincie : resolve,
						editor    : this.props.modify.editorData.toString(),
					});
					addressServiceGetData("location", this.props.modify.graveyardProvince)
						.then((resolve) => {
							if (resolve) {
								const locSelected = resolve.find(
									(x) => x.ID === (this.state.form.graveyardLocation || this.state.form.graveyardCity),
								);
								let capArr        = [];
								if (locSelected.capList) capArr = buildCap(locSelected);
								capArr.unshift(locSelected.cap);
								this.setState({
									comuni : resolve,
									caps   : capArr,
								});
								if (this.props.modify.graveyardCap) {
									const { form }    = this.state;
									form.graveyardCap = this.props.modify.graveyardCap;
									this.setState(form);
								}
							}
						});
				});
		}
		return (
			<>
				{this.state.uploading && (
					<div className="div-upload">
						<Spinner size={100} />
						<Typography className="label-upload">Attendi il caricamento dei files</Typography>
					</div>
				)}
				{this.state.successModal && this.successModal()}
				{this.state.openModalPhoto && this.photoLapideModal(this.state.formData)}
				{this.state.openModalExist && (
					<ObituaryExistModal
						close={() => this.setState({ openModalExist: false })}
						accept={() => {
							this.afterObituaryCheck(globalData);
						}}
					/>
				)}
				<div className="contact-container" style={{ height: this.props.modify && "inherit" }}>
					{this.state.feedbackModal && (
						<FeedbackModal
							result={this.state.modalResult}
							close={() => {
								this.setState({ feedbackModal: false });
								this.props.modify && this.state.modalResult === "Successo" && window.location.reload();
								// this.setState({name: '',number:'',selectedValue:''})
							}}
							label={this.state.modalText}
						/>
					)}
					<div
						className="activate-form-div"
						style={{
							marginTop : !this.props.modify && 30,
							minHeight : this.props.modify && "auto",
						}}
					>
						<form
							className="activate-form"
							onSubmit={(event) => {
								event.preventDefault();
								const formData = new FormData(event.target);
								const object   = {};
								formData.forEach((value, key) => {
									object[key] = value;
								});
								this.state.ereased && formData.set("notphoto", this.state.ereased);
								this.props.modify && formData.set("obituaryID", this.props.modify.ID);
								this.send(formData, !object["photo-lapide"], object);
							}}
						>
							<div style={{ display: "none" }}>
								<UserFormData
									names="obituary"
									form={this.state.form}
									formError={this.state.formError}
								/>
							</div>
							<label className="death-data">Dati Defunto</label>
							<input style={{ display: "none" }} name="csrf_token" value={this.state.csrf_token} />
							<TextField
								className="activate-field"
								label="Nome del Defunto *"
								name="obituary-deceased-name"
								value={this.state.form.nameTo}
								onChange={(event) => this.setAndValidateForm(event.target.value, "nameTo")}
								error={this.state.formError.nameTo}
								helperText={this.state.formError.nameTo && "Campo obbligatorio"}
							/>
							<TextField
								className="activate-field"
								label="Cognome del Defunto *"
								name="obituary-deceased-surname"
								value={this.state.form.surnameTo}
								onChange={(event) => this.setAndValidateForm(event.target.value, "surnameTo")}
								error={this.state.formError.surnameTo}
								helperText={this.state.formError.surnameTo && "Campo obbligatorio"}
							/>
							<div className="isFlexRow justify-between">
								<TextField
									className="activate-field"
									label="Età del defunto"
									name="obituary-deceased-age"
									value={this.state.age}
									onChange={(event) => {
										this.setState({ age: event.target.value });
									}}
								/>
								<TextField
									className="activate-field"
									select
									onChange={(event) => {
										this.setState({ gender: event.target.value });
									}}
									label="Sesso *"
									name="gender"
									value={this.state.gender || ""}
									error={!this.state.gender && this.state.genderError}
									helperText={!this.state.gender && this.state.genderError && "Campo obbligatorio"}
								>
									<MenuItem value="M">M</MenuItem>
									<MenuItem value="F">F</MenuItem>
								</TextField>
							</div>
							<div className="activate-date-field">
								<MuiPickersUtilsProvider locale={DateFnsIt} utils={DateFnsUtils}>
									<KeyboardDatePicker
										className=" ml--5 date "
										inputVariant="filled"
										format="dd/MM/yyyy"
										margin="normal"
										onChange={(event, asd) => {
											this.setAndValidateForm(event, "bornDate");
										}}
										style={{ backgroundColor: "white" }}
										id="date"
										name="obituary-born-date"
										label="Data di nascita"
										value={this.state.form.bornDate}
									/>
									<KeyboardDatePicker
										className=" m-12 date"
										inputVariant="filled"
										format="dd/MM/yyyy"
										margin="normal"
										name="obituary-death-date"
										onChange={(event, asd) => {
											this.setAndValidateForm(event, "deathDate");
										}}
										id="date"
										label="Data di morte"
										value={this.state.form.deathDate}
									/>
								</MuiPickersUtilsProvider>
							</div>
							<TextField
								name="obituary-aphorism"
								className="text-area"
								id="outlined-multiline-static"
								value={this.state.aphorism}
								onChange={(e) => {
									this.setState({ aphorism: e.target.value });
								}}
								label="Aforisma"
								multiline
								style={{ display: "none" }}
								rows={8}
								variant="outlined"
							/>
							<div className="buy-form-paragraph" id="multipleReferringPlace">
								Luogo del funerale
							</div>
							<div className="Partner-row-fields">
								<TextField
									className="partner-field "
									label="Nome"
									value={this.state.graveyardName}
									onChange={(event) => this.setState({ graveyardName: event.target.value })}
									style={{ display: "none" }}
									helperText="ad esempio Cimitero di Roma"
									name="obituary-graveyard-name"
								/>
							</div>
							<MultipleReferringPlace
								obituary
								partnerpanel
								referringPlace={this.state.referringPlace || []}
								onChange={(sendData) => {
									const objects = JSON.parse(sendData);

									let condition =										objects?.length > 0
										&& objects[objects.length - 1].province
										&& objects[objects.length - 1].city
										&& objects[objects.length - 1].region;
									condition     = condition && objects[objects.length - 1].region;
									this.setState({
										"referring-place" : sendData,
										refError          : !condition,
									});
								}}
								error={this.state.refError}
							/>
							{this.state.refSendError && !placeError && (
								<span style={{
									color     : "red",
									marginTop : 12,
								}}
								>
									Inserisci regione, provincia e città per ogni riga parzialmente compilata
								</span>
							)}
							<div className="Partner-row-fields">
								<TextField
									className="partner-field "
									style={{ display: "none" }}
									value={this.state.graveyardAddress}
									onChange={(event) => this.setState({ graveyardAddress: event.target.value })}
									label="Indirizzo"
									name="obituary-graveyard-address"
								/>
							</div>
							<div style={{ marginTop: 40 }} className="App">
								<input style={{ display: "none" }} name="csrf_token" value={this.state.csrf_token} />
								<div
									className="buy-form-paragraph"
									style={{
										marginBottom : 20,
										marginTop    : 0,
									}}
								>
									{" "}
									Dati del funerale
								</div>
								<MuiPickersUtilsProvider locale={DateFnsIt} utils={DateFnsUtils}>
									<KeyboardDatePicker
										inputVariant="filled"
										format="dd/MM/yyyy"
										onChange={(event) => this.setState({ funeralDate: event })}
										style={{
											backgroundColor : "white",
											width           : "100%",
											marginBottom    : 16,
										}}
										id="date"
										name="funeral-date"
										value={this.state.funeralDate || null}
										label="Data del funerale"
									/>
								</MuiPickersUtilsProvider>
								<div className="ckeditor-activate" style={{ maxWidth: window.innerWidth - 50 }}>
									<CKEditor
										onInit={(editor) => {
											editor.ui
												.getEditableElement()
												.parentElement
												.insertBefore(
													editor.ui.view.toolbar.element,
													editor.ui.getEditableElement(),
												);
										}}
										onChange={(event, editor, data) => {
											this.setState({ editor: editor.getData() });
										}}
										editor={DecoupledEditor}
										data={this.props.modify && this.props.modify.editorData}
										config={{
											toolbar: [
												"heading",
												"Cut",
												"Copy",
												"Paste",
												"|",
												"alignment",
												"bold",
												"italic",
												"FontSize",
												"Fontfamily",
												"TextColor",
												"link",
												"numberedList",
												"bulletedList",
												"|",
												"undo",
												"redo",
												"|",
												"underline",
												"subscript",
												"superscript",
											],
											heading: {
												options: [
													{
														model : "paragraph",
														title : "Paragraph",
														class : "ck-heading_paragraph",
													},
													{
														model : "heading1",
														view  : "h1",
														title : "Heading 1",
														class : "ck-heading_heading1",
													},
													{
														model : "heading2",
														view  : "h2",
														title : "Heading 2",
														class : "ck-heading_heading2",
													},
													{
														model : "heading3",
														view  : "h3",
														title : "Heading 3",
														class : "ck-heading_heading3",
													},
												],
											},
										}}
									/>
								</div>
							</div>
							<PhotoInput
								name="lapide"
								setFileName={(name, file) => {
									const url = URL.createObjectURL(file);
									readURL(file)
										.then((data) => this.setState({ photod: data }));
									this.setState({
										filename : name,
										ereased  : false,
									});
								}}
							/>
							<div className="file-upload">
								<label className="pointer" htmlFor="photo-lapide" color="blue" title="">
									<p className="pointer-label">
										{this.state.filename || "Carica foto del manifesto o altro(Max 15MB per foto)"}
									</p>
								</label>
							</div>
							{this.state.photod && (
								<div className="is-relative">
									<img
										alt="photod"
										style={{
											maxHeight   : 300,
											margin      : "auto",
											paddingLeft : 20,
											marginTop   : 20,
											position    : "relative",
										}}
										src={this.state.photod}
									/>
									<RemoveCircleOutline
										className="remove-photo"
										onClick={() => {
											this.setState({
												filename : "",
												photod   : null,
												erased   : true,
											});
											document.getElementById("photo-lapide").value = null;
										}}
									/>
								</div>
							)}
							&nbsp;
							<div className="bottom-activate-form">
								<button
									type="submit"
									onClick={(event) => {
									}}
									className="activate-submit"
								>
									Procedi
								</button>
							</div>
						</form>
					</div>
					&nbsp;
				</div>
			</>
		);
	}

	// eslint-disable-next-line react/sort-comp
	send(formdata, notLapide) {
		let { formError } = this.state;
		let error         = false;
		// eslint-disable-next-line no-restricted-syntax
		for (const property in this.state.form) {
			if (property !== "phone" && property !== "email" && property !== "CF") {
				formError = validate(this.state.form[property], property, formError);
				error     = error || formError[property];
			}
		}
		if (!this.state.gender) {
			error = true;
			this.setState({ genderError: true });
		}
		const fileError = notLapide;
		let refError    = false;
		this.setState({
			formError,
			fileError,
		});
		globalData             = formdata;
		let conditionReferring = true;
		const objects          = JSON.parse(this.state["referring-place"]);
		if (objects?.length > 0) {
			objects.forEach((e) => {
				if (e.region && e.region !== "") conditionReferring = conditionReferring && e.city;
			});
		}
		refError = !conditionReferring;
		this.setState({
			refError,
			refSendError: !conditionReferring,
		});
		const object = {};
		formdata.forEach((value, key) => {
			object[key] = value;
		});
		if (!error && !refError) {
			formdata.set("obituary-editor", this.state.editor);
			if (!this.props.modify) {
				const bodyData = {
					"obituary-deceased-name"    : this.state.form.nameTo,
					"obituary-deceased-surname" : this.state.form.surnameTo,
					"obituary-death-date"       : this.state.form.deathDate
						? moment(this.state.form.deathDate)
							.format("YYYY/MM/DD")
						: "",
					"obituary-born-date": this.state.form.bornDate
						? moment(this.state.form.bornDate)
							.format("YYYY/MM/DD")
						: "",
					"obituary-graveyard-location" : this.state.form.graveyardCity,
					"obituary-graveyard-province" : this.state.form.graveyardProvince,
					"obituary-graveyard-region"   : this.state.form.graveyardRegion,
				};
				requests("post", "obituaryCheck", bodyData)
					.then((result) => {
						if (result.response === "success") {
							this.afterObituaryCheck(formdata);
						} else {
							this.setState({ openModalExist: true });
						}
					});
			} else {
				this.afterObituaryCheck(formdata);
			}
		} else if (refError) {
			document.getElementById("multipleReferringPlace")
			&& document.getElementById("multipleReferringPlace")
				.scrollIntoView();
		}
	}

	afterObituaryCheck(formData) {
		if (!this.props.modify && (!this.state.filename || (this.state.filename && this.state.filename.length <= 0))) {
			this.setState({ openModalPhoto: true });
		} else {
			this.sendData(formData);
		}
	}

	sendData(formdata) {
		const object = {};
		getCsrf()
			.then((csrf) => {
				formdata.set("csrf_token", csrf);
				this.setState({ uploading: true });
				if (this.state["referring-place"]) formdata.set("referring-place", this.state["referring-place"]);
				formdata.forEach((value, key) => {
					object[key] = value;
				});
				!this.props.modify
					? requests("sendform", "addPoster", formdata)
						.then((data) => {
							this.setState({ uploading: false });
							if (data.response === "success") {
								this.setState({ successModal: true });
							} else if (data.message) {
								this.setState({
									feedbackModal : true,
									modalResult   : "Errore",
									modalText     : data.message,
								});
							} else {
								this.setState({
									feedbackModal : true,
									modalResult   : "Errore",
									modalText     : "Errore imprevisto riprovare piu` tardi",
								});
							}
						})
					: requests("sendform", "obituaryModify", formdata)
						.then((result) => {
							if (result.response === "success") {
								this.setState({ uploading: false });
								this.setState({
									feedbackModal : true,
									modalResult   : "Successo",
									modalText     : "Elemento modificato con successo",
								});
							} else if (result.message) {
								this.setState({
									feedbackModal : true,
									modalResult   : "Errore",
									modalText     : result.message,
								});
							}
						})
						.finally(() => this.setState({ uploading: false }));
			});
	}

	photoLapideModal(formdata) {
		let close2 = true;
		return (
			<div
				className="modal-bg"
				onClick={() => {
					if (close2) {
						this.setState({ openModalPhoto: false });
						// this.props.history.push('admin-panel')
					} else {
						close2 = !close2;
					}
				}}
			>
				<div
					className="inner"
					onClick={() => {
						close2 = false;
					}}
				>
					<Typography style={{ fontSize: 17 }}>Attenzione!!</Typography>
					<Typography style={{
						fontSize  : 12,
						marginTop : 15,
					}}
					>
						Non hai caricato la foto della lapide, desideri continuare?
					</Typography>
					<div
						style={{
							flexDirection  : "row",
							display        : "flex",
							justifyContent : "space-between",
						}}
					>
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								this.setState({ openModalPhoto: false });
								this.sendData(globalData);
							}}
						>
							SI
						</Button>
						<Button
							color="primary"
							style={{ marginLeft: 20 }}
							onClick={() => {
								this.setState({ openModalPhoto: false });
								// this.props.history.push('admin-panel')
							}}
							variant="contained"
						>
							NO
						</Button>
					</div>
				</div>
			</div>
		);
	}

	successModal() {
		let close = true;
		return (
			<div
				className="modal-bg"
				onClick={() => {
					if (close) {
						this.setState({ successModal: false });

						window.scrollTo(0, 0);
						window.location.reload();
						window.scrollTo(0, 0);
					} else {
						close = !close;
					}
				}}
			>
				<div
					className="inner"
					style={{ width: 350 }}
					onClick={() => {
						close = false;
					}}
				>
					<Typography style={{ fontSize: 19 }}>Successo</Typography>
					<Typography style={{
						fontSize  : 12,
						marginTop : 15,
					}}
					>
						Il necrologio verra' caricato al piu' presto
					</Typography>
					<div
						style={{
							flexDirection  : "row",
							display        : "flex",
							justifyContent : "space-between",
							marginTop      : 20,
						}}
					>
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								window.scrollTo(0, 0);
								window.location.reload();
								window.scrollTo(0, 0);
							}}
						>
							Aggiungine un altro
						</Button>
						<Button
							color="primary"
							style={{ marginLeft: 20 }}
							onClick={() => {
								this.setState({ successModal: false });

								window.scrollTo(0, 0);
								window.location.reload();
								window.scrollTo(0, 0);
							}}
							variant="contained"
						>
							Chiudi
						</Button>
					</div>
				</div>
			</div>
		);
	}

	setAndValidateForm(data, attribute) {
		const { form }    = this.state;
		let { formError } = this.state;
		form[attribute]   = data;
		this.setState({ form });
		formError = validate(data, attribute, formError);
		this.setState({ formError });
	}
}

export default AddDeceasedPoster;
