const defunto = {
	name: 'Demo',
	hasPhoto: true,
	surname: 'Rossi',
	mainPhoto:
		'https://static.wixstatic.com/media/d95383_8d06cec1b7de43529dacaec047ee1934~mv2_d_5400_4047_s_4_2.jpeg/v1/fill/w_689,h_516,fp_0.50_0.50,q_90/d95383_8d06cec1b7de43529dacaec047ee1934~mv2_d_5400_4047_s_4_2.png',
	bornDate: '15 gennaio 1946',
	deathDate: '1 febbraio 2018',
	region: 'molise',
	province: 'CB',
	city: 'campobasso',
	cimitery: 'Cimitero di roma',
	azienda: 'I.M.E.',
	firstPhrases: 'Più dolce sarebbe la morte\n' + 'se il mio sguardo avesse come ultimo orizzonte il tuo volto ',
	photos: [
		{
			file:
				'https://static.wixstatic.com/media/d95383_b4d052804cbc4691b6b22937aa62eee4~mv2.jpg/v1/fill/w_774,h_516,fp_0.50_0.50,q_90/d95383_b4d052804cbc4691b6b22937aa62eee4~mv2.png',
			type: 'photo',
			description: 'some image'
		},
		{
			file:
				'https://static.wixstatic.com/media/d95383_b4d052804cbc4691b6b22937aa62eee4~mv2.jpg/v1/fill/w_774,h_516,fp_0.50_0.50,q_90/d95383_b4d052804cbc4691b6b22937aa62eee4~mv2.png',
			type: 'photo',
			description: 'some image'
		},
		{
			file:
				'https://static.wixstatic.com/media/d95383_b4d052804cbc4691b6b22937aa62eee4~mv2.jpg/v1/fill/w_774,h_516,fp_0.50_0.50,q_90/d95383_b4d052804cbc4691b6b22937aa62eee4~mv2.png',
			type: 'photo',
			description: 'some image'
		},
		{
			file:
				'https://static.wixstatic.com/media/d95383_dc40cc701c2f40d8864dc60510a2c266~mv2.jpg/v1/fill/w_774,h_516,fp_0.50_0.50,q_90/d95383_dc40cc701c2f40d8864dc60510a2c266~mv2.png',
			type: 'photo',
			description: 'some image'
		},
		{
			file:
				'https://static.wixstatic.com/media/d95383_a704b3e110c54602af3627dd69638658~mv2_d_5400_3807_s_4_2.jpeg/v1/fill/w_732,h_516,fp_0.50_0.50,q_90/d95383_a704b3e110c54602af3627dd69638658~mv2_d_5400_3807_s_4_2.png',
			type: 'photo',
			description: 'some image'
		},
		{
			file:
				'https://static.wixstatic.com/media/d95383_7875113316854710a7cbaea7778ea015~mv2.jpg/v1/fill/w_774,h_516,fp_0.50_0.50,q_90/d95383_7875113316854710a7cbaea7778ea015~mv2.png',
			type: 'photo',
			description: 'some image'
		},
		{
			file: 'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
			type: 'video',
			description: 'some image'
		},
		{
			file:
				'https://static.wixstatic.com/media/d95383_c7204f0f48fb44ff8b8ef0f708d6bc19~mv2.jpg/v1/fill/w_764,h_516,fp_0.50_0.50,q_90/d95383_c7204f0f48fb44ff8b8ef0f708d6bc19~mv2.png',
			type: 'photo',
			description: 'some image'
		},
		{
			file:
				'https://static.wixstatic.com/media/d95383_e2b7f9c68e144b80a5755af021079ef4~mv2.jpg/v1/fill/w_732,h_516,fp_0.50_0.50,q_90/d95383_e2b7f9c68e144b80a5755af021079ef4~mv2.png',
			type: 'photo',
			description: 'some image'
		},
		{
			file:
				'https://static.wixstatic.com/media/d95383_8d06cec1b7de43529dacaec047ee1934~mv2_d_5400_4047_s_4_2.jpeg/v1/fill/w_689,h_516,fp_0.50_0.50,q_90/d95383_8d06cec1b7de43529dacaec047ee1934~mv2_d_5400_4047_s_4_2.png',
			type: 'photo',
			description: 'some image'
		},
		{
			file:
				'https://static.wixstatic.com/media/d95383_e7a16dc1e41e4997b44f4e3be13f4bec~mv2.jpg/v1/fill/w_792,h_516,fp_0.50_0.50,q_90/d95383_e7a16dc1e41e4997b44f4e3be13f4bec~mv2.png\n',
			type: 'photo',
			description: 'some image'
		}
	],
	bio:
		'Mario Rossi, soprannominato il camminatore, nato a Roma il 16 settembre 1980, era conosciuto da tutti nel suo quartiere come uomo sensibile e generoso, sempre positivo e con il sorriso, ma soprattutto perché ...',
	dedics: [
		{
			date: '10 Marzo 2018',
			type: 0,
			label:
				'“La prima volta che lo incontrai fu ad una festa. Io e la mia amica andavamo spesso a ballare il sabato sera e fu in una di quelle occasioni che conobbi Mario. Venivano sempre i maschi a chiederci di ballare e ad un certo punto mi stufai e dissi: adesso veniamo noi donne a prendervi, ...”',
			sign: 'Maria'
		},
		{
			date: '10 Marzo 2018',
			type: 1,
			label:
				'“La prima volta che lo incontrai fu ad una festa. Io e la mia amica andavamo spesso a ballare il sabato sera e fu in una di quelle occasioni che conobbi Mario. Venivano sempre i maschi a chiederci di ballare e ad un certo punto mi stufai e dissi: adesso veniamo noi donne a prendervi, ...”',
			sign: 'Maria'
		},
		{
			date: '10 Marzo 2018',
			type: 3,
			label:
				'“La prima volta che lo incontrai fu ad una festa. Io e la mia amica andavamo spesso a ballare il sabato sera e fu in una di quelle occasioni che conobbi Mario. Venivano sempre i maschi a chiederci di ballare e ad un certo punto mi stufai e dissi: adesso veniamo noi donne a prendervi, ...”',
			sign: 'Maria',
			src: 'https://media.w3.org/2010/05/sintel/trailer_hd.mp4'
		},
		{
			date: '10 Marzo 2018',
			type: 2,
			label:
				'“La prima volta che lo incontrai fu ad una festa. Io e la mia amica andavamo spesso a ballare il sabato sera e fu in una di quelle occasioni che conobbi Mario. Venivano sempre i maschi a chiederci di ballare e ad un certo punto mi stufai e dissi: adesso veniamo noi donne a prendervi, ...”',
			sign: 'Maria',
			src: 'https://i.pinimg.com/originals/70/b8/fa/70b8fa9f3482f361d0c4ba708fdabe5b.jpg'
		},
		{
			date: '10 Marzo 2018',
			type: 2,
			label:
				'<strong> La prima volta che lo incontrai fu ad una festa. </strong> &raquo; &lt;em&gt;paragraph text example:&lt;/em&gt; Io e la mia amica andavamo spesso a ballare il sabato sera e fu in una di quelle occasioni che conobbi Mario. Venivano sempre i maschi a chiederci di ballare e ad un certo punto mi stufai e dissi: adesso veniamo noi donne a prendervi, ...”',
			sign: 'Maria',
			src: 'https://i.pinimg.com/originals/70/b8/fa/70b8fa9f3482f361d0c4ba708fdabe5b.jpg'
		}
	],
	thereIsBook: true
};
export default defunto;
