import HttpServices from '../../Services/HttpServices';
import regioni from '../memorial-page/regioni';
import { provinces, regions } from '../../Services/RandomUtils';

const names = null;

function getRegion() {
	return regions;
}
export function getLabelledAddress(e) {
	const address = {};
	address.address = e.address;
	address.number = e.number;
	address.region = e.region;
	address.regionLabel = regioni.find(el => el.ID === e.region)?.name;
	address.city = e.location;
	address.province = e.province;
	address.cap = e.cap;
	address.nationality = e.nationID;
	address.complete_address = e.completeAddress;
	return new Promise(resolve => {
		const provinces = getProvince(address.region);
		let locations;
		address.provinceLabel = provinces.find(el => el.ID === address.province)?.name;
		getLocation(address.province).then(comuni => {
			locations = comuni;
			address.locationLabel = locations.find(el => el.ID === address.province)?.name;
			resolve(address);
		});
	});
}
function getProvince(data) {
	console.log(provinces, data, 'ecco');
	return provinces.filter(e => `${e.idregione}` === `${data}`);
}

function getLocation(data) {
	let location = {};
	return new Promise(resolve => {
		HttpServices('get', 'location', data)
			.then(result => {
				location = result.data.comuni;
				resolve(location);
			})
			.catch(err => {
				resolve(names);
			});
	});
}

export default function addressServiceGetData(type, data) {
	return new Promise(resolve => {
		switch (type.toLowerCase()) {
			case 'region':
				resolve(getRegion());
				break;
			case 'province':
				resolve(getProvince(data));
				break;
			case 'location':
				getLocation(data).then(result => {
					resolve(result);
				});
				break;
		}
	});
}
