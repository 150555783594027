/* eslint-disable no-shadow */
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Paper, Popover, Typography } from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TablePagination from '@material-ui/core/TablePagination';
import moment from 'moment';
import { useLocation } from 'react-router';
import CustomTableBody from './TableComponents/TableBody';
import CustomTableHead from './TableComponents/TableHead';

export default function GeneralTable(props) {
	const [page, setPage] = useState(0);
	const [anchorEl, setAnchorEl] = useState(null);
	const [popoverData, setPopoverData] = useState(null);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [filter, setFilter] = useState(props.filter);
	const [initFilter, setInitFilter] = useState(props.filter);
	const [update, setUpdate] = useState(false);
	const [order, setOrder] = React.useState('desc');
	const [orderBy, setOrderBy] = React.useState(props.initOrder || 'id');
	const [checkedElements, setCheckedElements] = useState([]);
	const columns = [...props.headData];

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};
	useEffect(() => {
		const temp = { ...props.filter };
		setInitFilter(temp);
	}, [props.filter]);
	const resetFilter = () => {
		props.setFilter(initFilter);
		setFilter(initFilter);
	};
	const rows = [...props.data];
	useEffect(() => {
		props.setAllNumber(rows.length);
	}, []);
	useEffect(() => {
		!props.deceasedAdminTable && setPage(0);
	}, [props.data?.length, props.data]);
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
		if (props.reload) props.reload();
	};

	const handleChangeRowsPerPage = event => {
		setRowsPerPage(event.target.value);
		setPage(0);
	};

	function descendingComparator(a, b, orderBy, order) {
		const isDate = moment(a[orderBy], 'DD/MM/YYYY HH:mm').isValid();
		const isDate2 = moment(a[orderBy], 'DD/MM/YYYY HH:mm:ss').isValid();
		if (orderBy === 'book') {
			return parseInt(a[orderBy], 10) < parseInt(b[orderBy], 10) ? -1 : 1;
		}
		if (a[orderBy] === 'Non disponibile') {
			return order === 'desc' ? 1 : -1;
		}
		if (b[orderBy] === 'Non disponibile') return order === 'desc' ? -1 : 1;
		if (isDate) {
			const data1 = moment(a[orderBy], 'DD/MM/YYYY HH:mm').format('YYYY/MM/DD');
			const data2 = moment(b[orderBy], 'DD/MM/YYYY HH:mm').format('YYYY/MM/DD');
			if (data2 < data1) return -1;
			return 1;
		}
		if (isDate2) {
			const data1 = moment(a[orderBy], 'DD/MM/YYYY HH:mm:ss').format('YYYY/MM/DD');
			const data2 = moment(b[orderBy], 'DD/MM/YYYY HH:mm:ss').format('YYYY/MM/DD');
			if (data2 < data1) return -1;
			return 1;
		}
		try {
			const aStr = JSON.stringify(a[orderBy] || '');
			const bStr = JSON.stringify(b[orderBy] || '');

			if (bStr?.toLowerCase() < aStr?.toLowerCase()) {
				return -1;
			}
			if (bStr?.toLowerCase() > aStr?.toLowerCase()) {
				return 1;
			}
		} catch (e) {
			console.log('catch');
			return 0;
		}
		return 0;
	}

	function getComparator(order, orderBy) {
		return order === 'desc'
			? (a, b) => descendingComparator(a, b, orderBy, order)
			: (a, b) => -descendingComparator(a, b, orderBy, order);
	}
	function filterData() {
		const arr = [...rows];
		const filtered = arr.filter(el => {
			let find = true;
			Object.keys(filter).forEach(key => {
				if (el === '0') find = false;
				if (typeof el[key] === 'object' && !props.client) {
					find =
						find &&
						el[key]?.props?.children?.props?.children?.toLowerCase().includes(filter[key].toLowerCase());
				} else if (el[key]) find = find && `${el[key]}`.toLowerCase().includes(filter[key].toLowerCase());
			});
			return find;
		});
		props.setNumber(filtered.length);
		return filtered;
	}

	const open = Boolean(anchorEl);
	useEffect(() => {
		if (props.setChecked) props.setChecked(checkedElements);
	}, [checkedElements]);

	const createSortHandler = property => event => {
		handleRequestSort(event, property);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const dataToShow = filterData().sort(getComparator(order, orderBy));
	return (
		<Paper className="radius-16 card-shadow" sx={{ width: '100%', overflow: 'hidden', height: 'max-content' }}>
			<Popover
				id="popover"
				open={open}
				anchorEl={anchorEl}
				style={{ borderRadius: 16 }}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
			>
				<div style={{ borderRadius: 16 }} onMouseLeave={handleClose}>
					{popoverData || <div />}
				</div>
			</Popover>
			<TableContainer>
				<Table size="small" stickyHeader aria-label="sticky table">
					<CustomTableHead
						type={props.type}
						orderBy={orderBy}
						data={dataToShow}
						deleteAllOperation={props.deleteAllOperation}
						createSortHandler={createSortHandler}
						checkedElements={checkedElements}
						order={order}
						columns={columns}
						resetOrder={() => {
							setOrder('desc');
							setOrderBy(props.initOrder || 'id');
						}}
						unSelectAll={() => setCheckedElements([])}
						selectAll={() => {
							const temp = [];
							dataToShow.forEach(e => {
								temp.push(e.id);
							});

							setCheckedElements(temp);
						}}
						initFilter={initFilter}
						isFilter={false}
					/>
					<CustomTableHead
						type={props.type}
						filter={filter}
						isFilter
						partner={props.partner}
						deleteAllOperation={props.deleteAllOperation}
						data={dataToShow}
						checkedElements={checkedElements}
						resetFilter={() => {
							setFilter(initFilter);
						}}
						initFilter={initFilter}
						setFilter={value => {
							setFilter(value);
							props.setFilter(value);
							setUpdate(!update);
						}}
						columns={columns}
					/>
					<CustomTableBody
						extradata={props.extradata}
						clickable={props.clickable}
						onRowClick={props.onRowClick}
						setPopoverData={setPopoverData}
						setAnchorEl={setAnchorEl}
						page={page}
						checkedElements={checkedElements}
						setCheckedElements={setCheckedElements}
						rowsPerPage={rowsPerPage}
						rows={dataToShow}
						columns={columns}
					/>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[5, 10, 25, 100]}
				component="div"
				count={rows.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onChangePage={handleChangePage}
				onChangeRowsPerPage={handleChangeRowsPerPage}
			/>
			<Button id="reset-filter" style={{ display: 'none' }} onClick={resetFilter}>
				reset
			</Button>
		</Paper>
	);
}
