import React, { useEffect, useState } from 'react';
import { CircularProgress, Modal, Typography } from '@material-ui/core';
import { InfoRounded } from '@material-ui/icons';
import logo from '../../assets/logocompact.jpg';
import InfoModal from './InfoModal';
import PhoneNumber from './PhoneNumber';
import requests from '../../Services/HttpServices';

export default function DMCard(props) {
	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
		<div className="dm-card" onClick={() => props.onClick()}>
			<img className="dm-card-logo" style={{ height: 56 }} src={logo} alt="logo" />
			<Typography className="dm-card-text">Clicca per ricevere info via Whatsapp</Typography>
			<InfoRounded
				color="primary"
				onClick={e => {
					props.setModal(true);
					e.stopPropagation();
				}}
				style={{ cursor: 'pointer', position: 'absolute', right: 12, top: 12 }}
				className="dm-card-info"
			/>
		</div>
	);
}
export function DMModal(props) {
	const [disabled, setDisabled] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [value, setValue] = useState('');
	const [showMessage, setShowMessage] = useState(false);
	const [success, setSuccess] = useState(false);
	const [errorMessage, setErrorMessage] = useState(
		'Errore riprova tra poco Errore riprova tra poco Errore riprova tra poco'
	);

	function send(event) {
		event.preventDefault();
		const formData = new FormData(event.target);
		const object = {};
		formData.forEach(function (value, key) {
			object[key] = value;
		});
		setIsLoading(true);
		requests('post', 'sendCard', object).then(data => {
			setIsLoading(false);
			if (data.response === 'success') {
				setSuccess(true);
				setShowMessage(false);
			} else {
				setErrorMessage(data.message);
				setShowMessage(true);
				setSuccess(false);
			}
		});
	}
	function handleClose() {
		props.close();
		setSuccess(false);
		setShowMessage(false);
		setValue('');
	}
	useEffect(() => {
		setDisabled(value.length <= 9);
	}, [value, value?.length]);
	return (
		<Modal
			open={props.open}
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center'
			}}
			onClose={handleClose}
		>
			{isLoading ? (
				<div className="modalInputCard" style={{ alignItems: 'center', justifyContent: 'center' }}>
					<CircularProgress color="primary" />
				</div>
			) : success ? (
				<div className="modalInputCard">
					<Typography
						className="buy-form-paragraph"
						style={{ fontWeight: 'normal', fontSize: 24, textAlign: 'center' }}
					>
						Successo
					</Typography>
					<Typography style={{ fontSize: 13, textAlign: 'center' }}>
						Messaggio con il contatto di whatsapp inviato con successo
					</Typography>
					<button type="button" onClick={handleClose} className={`activate-submit button-send-card `}>
						Chiudi
					</button>
				</div>
			) : (
				<div className="modalInputCard">
					<Typography style={{ marginBottom: 28 }}>Inserisci il tuo numero di telefono</Typography>
					<form className="flex-column relative" onSubmit={send}>
						<PhoneNumber name="phone" setValue={setValue} />
						{showMessage && (
							<Typography
								style={{
									position: 'absolute',
									left: 0,
									bottom: 0,
									fontSize: 12,
									maxWidth: 235,
									textAlign: 'center',
									color: 'red'
								}}
							>
								{errorMessage}
							</Typography>
						)}
						<button
							type="submit"
							className={`activate-submit button-send-card ${disabled && 'disable'}`}
							disabled={disabled}
						>
							Invia
						</button>
					</form>
				</div>
			)}
		</Modal>
	);
}
