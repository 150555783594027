import CKEditor from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import React, { useEffect, useState } from 'react';

export default function CkEditorCustom(props) {
	const [value, setValue] = useState(null);
	const [valueSetted, setValueSetted] = useState(null);
	if (!value && value !== props.initData && !valueSetted && props.initData !== undefined) {
		setValue(props.initData);
		setValueSetted(true);
	}
	// props.initData&& value === props.initData && !valueSetted&&setValueSetted(true)
	// !props.initData&& !valueSetted&& valueSetted(true);

	// !valueSetted && !props.initData && setValueSetted(true)
	return (
		<div
			className="sono io"
			style={{ marginTop: 20, textAlign: 'left', maxWidth: window.innerWidth - 40 }} /* className="App" */
		>
			<label style={{ textAlign: 'left', fontSize: 20, alignSelf: 'flex-start', marginBottom: 10 }}>
				{props.title}
			</label>
			<p>&nbsp;</p>
			{valueSetted && (
				<CKEditor
					onInit={editor => {
						// Insert the toolbar before the editable area.
						editor.ui
							.getEditableElement()
							.parentElement.insertBefore(editor.ui.view.toolbar.element, editor.ui.getEditableElement());
					}}
					onChange={(event, editor, data) => {
						// this.setAndValidateForm(editor.getData(),'dedic')
						props.onChange(editor);

						// this.setState({editor: editor.getData()})
					}}
					editor={DecoupledEditor}
					data={value}
					config={{
						fontFamily: {
							options: [
								'Cookie,sans-serif',
								'default',
								'Ubuntu, Arial, sans-serif',
								'Ubuntu Mono, Courier New, Courier, monospace'
							]
						},
						toolbar: [
							'heading',
							'Cut',
							'Copy',
							'Paste',
							'|',
							'alignment',
							'bold',
							'italic',
							'FontSize',
							'Fontfamily',
							'TextColor',
							'link',
							'numberedList',
							'bulletedList',
							'|',
							'undo',
							'redo',
							'|',
							'underline',
							'subscript',
							'superscript'
						],
						heading: {
							options: [
								{ model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
								{
									model: 'heading1',
									view: 'h1',
									title: 'Heading 1',
									class: 'ck-heading_heading1'
								},
								{
									model: 'heading2',
									view: 'h2',
									title: 'Heading 2',
									class: 'ck-heading_heading2'
								},
								{
									model: 'heading3',
									view: 'h3',
									title: 'Heading 3',
									class: 'ck-heading_heading3'
								}
							]
						}
					}}
				/>
			)}
			{props.error && <label style={{ color: 'red' }}>Campo obbligatorio</label>}
		</div>
	);
}
