/* eslint-disable react/no-access-state-in-setstate */
import React, { Component, Fragment } from "react";
import TextField from "@material-ui/core/TextField";
import Axios from "axios";
import { Spinner } from "react-activity";
import { Button, Typography } from "@material-ui/core";
import listner from "reactjs-eventemitter";
import MenuItem from "@material-ui/core/MenuItem";
import "./AddGiftAdmin.scss";
import {
	MuiPickersUtilsProvider,
	KeyboardDateTimePicker,
} from "@material-ui/pickers";
import DateFnsIt from "date-fns/locale/it";
import DateFnsUtils from "@date-io/date-fns";
import KeyboardDateInput from "@material-ui/pickers/_shared/KeyboardDateInput";

import { RemoveCircleOutline } from "@material-ui/icons";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import PhoneNumber from "../../FormComponents/PhoneNumber";
import CkEditorCustom from "../../FormComponents/CkEditor";
import { readURL } from "../../FormComponents/FileNamesComponent";
import validate from "../../../formValidationService";
import requests, { baseURL } from "../../../Services/HttpServices";
import PhotoInput from "../../FormComponents/PhotoInput";
import VideoInput from "../../FormComponents/VideoInput";
import AddressService from "../../FormComponents/AddressComponent";
import UserFormData from "../../FormComponents/userFormData";
import addressServiceGetData from "../../FormComponents/addressService";
import FeedbackModal from "../../FormComponents/FeedbackModal";

let deceased     = null;
let formclass    = "";
let deceasedID   = null;
let purchaseType = null;
let label        = null;

class AddGiftAdmin extends Component {
	constructor(props) {
		super(props);
		this.state = {
			_csrf         : "",
			modal         : false,
			selected      : "",
			radioValue    : "",
			loggedUser    : null,
			productPrice  : "",
			feedbackModal : false,
			deceasedData  : {},
			form          : {
				// dedic: '',
				nationality: "105",
			},
			formError         : {},
			openPicker        : true,
			dateAndTimePicker : null,
		};
	}

	componentDidMount() {
		listner.events.upload2 = [];
		listner.events.upload2.length < 1
		&& listner.subscribe("upload2", (callback) => {
			this.setState({ percentage: callback });
		});
		getCsrf()
			.then((csrf) => {
				this.setState({ csrf_token: csrf });
			});
		deceased = this.props.history.location.search
			&& this.props.history.location.search.slice(
				1,
				this.props.history.location.search.length,
			);
		requests("get", "deceasedData", deceased)
			.then((data) => {
				if (data.data.result === "success") {
					this.setState({
						photo        : data.data["photo-lapide"],
						deceasedData : data.data.deceasedData,
					});
				} else {
					alert(`Errore ${data.data.message}`);
					this.props.history.push("./admin-panel");
				}
			});
	}

	setPurchaseType(type) {
		purchaseType = type;
		this.setState({ type });
		if (purchaseType) {
			label = purchaseType.replace("-", " e ");
			requests("get", "getProductPrice", purchaseType)
				.then((data) => {
					if (data.data.product_details) {
						this.setState({ productPrice: data.data.product_details.price });
					}
				});
		}
	}

	render() {
		if (!purchaseType) {
			formclass = "admin-gift-form-blur";
		} else {
			formclass = "admin-gift-form-focus";
		}
		try {
			if (!this.state.loggedUser && sessionStorage.getItem("User")) {
				this.setState({
					loggedUser: JSON.parse(sessionStorage.getItem("User")).userdata,
				});
				this.setState({
					userAddress: JSON.parse(sessionStorage.getItem("UserAddress")),
				});
			}
		} catch (e) {

		}

		if (this.state.loggedUser && this.state.form.cf == "") {
			this.setInitialData();
			this.state.userAddress
			&& addressServiceGetData("province", this.state.userAddress.region)
				.then(
					(resolve) => {
						this.setState({ provincie: resolve });
						addressServiceGetData(
							"location",
							this.state.userAddress.province,
						)
							.then((resolve) => {
								this.setState({ comuni: resolve });
							});
					},
				);
		}
		if (
			this.props.history.location.query
			&& this.props.history.location.query.region
			&& !deceased
		) {
			deceased = this.props.history.location.query
				&& this.props.history.location.query.region;
		}
		if (
			this.props.history.location.query
			&& this.props.history.location.query.deceasedID
			&& !deceasedID
		) {
			deceasedID = this.props.history.location.query
				&& this.props.history.location.query.deceasedID;
		}
		return (
			<>
				{this.state.uploading && (
					<div className="div-upload">
						<Spinner size={100} />
						<Typography className="label-upload">
							Attendi il caricamento dei files
							{" "}
							{parseInt(parseFloat(this.state.percentage) * 100)}
							%
						</Typography>
					</div>
				)}
				{this.state.successModal && this.successModal()}
				{this.state.feedbackModal && (
					<FeedbackModal
						result="Errore"
						label={this.state.feedbackModalText}
						close={() => this.setState({ feedbackModal: false })}
					/>
				)}
				<div className="contact-container ">
					<div className="memorial-container memorial-container-activate">
						&nbsp;
						<div className="activate-form-div fix-height">
							<label className="contact-title">
								Aggiungi
								{" "}
								{(purchaseType && label) || "dono"}
							</label>

							<form
								style={{ position: "relative" }}
								className="activate-form "
								onSubmit={(event) => {
									event.preventDefault();
									const formData   = new FormData(event.target);
									const object     = {};
									const insertDate = new Date(this.state.dateAndTimePicker);
									formData.set(
										"insert-time",
										insertDate && insertDate.getTime(),
									);
									formData.forEach((value, key) => {
										object[key] = value;
									});
									formData.set("deceased-id", deceased);
									this.send(formData);
								}}
							>
								<div className="search-gift-type">
									<TextField
										onChange={(event) => {
											this.setPurchaseType(event.target.value);
										}}
										style={{ width: "100%" }}
										name="dedic-type"
										select
										variant="outlined"
										label="Tipologia di dedica"
									>
										<MenuItem value="dedica-cero">Dedica e cero</MenuItem>
										<MenuItem value="dedica-fiori">Dedica e fiori</MenuItem>
										<MenuItem value="dedica-foto">Dedica e foto</MenuItem>
										<MenuItem value="dedica-video">Dedica e video</MenuItem>
									</TextField>
								</div>
								<div className={formclass}>
									<label>Scegli la tipologia di dedica per continuare</label>
								</div>
								{this.state.loggedUser && (
									<div style={{ display: "none" }}>
										<UserFormData
											nationalityChange={() => {
												this.setState({ reload: true });
											}}
											names="buy"
											form={this.state.form}
											formError={this.state.formError}
										/>
									</div>
								)}
								<div style={{ display: "none" }}>
									<AddressService
										names="buy"
										form={this.state.form}
										formError={this.state.formError}
										province={this.state.provincie}
										comuni={this.state.comuni}
									/>
								</div>
								<label className="death-data">Dati proprietario dedica</label>
								<TextField
									className="activate-field"
									label="Nome"
									name="name"
									value={this.state.form.name}
								/>
								<TextField
									className="activate-field"
									label="Cognome"
									name="surname"
									value={this.state.form.surname}
								/>
								<TextField
									className="activate-field"
									label="Email"
									name="email"
									value={this.state.form.email}
									style={{ marginBottom: 25 }}
								/>
								<PhoneNumber name="telephone" />
								<MuiPickersUtilsProvider
									locale={DateFnsIt}
									utils={DateFnsUtils}
								>
									<KeyboardDateTimePicker
										style={{ marginTop: 10 }}
										name="insert-time"
										format="dd/MM/yyyy HH:mm"
										label="Inserisci la data di creazione"
										value={this.state.dateAndTimePicker}
										onChange={(value) => {
											this.setState({ dateAndTimePicker: value });
										}}
										openPicker={this.state.openPicker}
									/>
								</MuiPickersUtilsProvider>
								<label className="death-data">Dati Defunto</label>
								<div className="admin-add-gift-deceased">
									<img
										alt="photo-lapide"
										style={{
											maxWidth  : 100,
											maxHeight : 100,
										}}
										src={this.state.photo && this.state.photo}
									/>
									<div id="deceased">
										<label>
											{this.state.deceasedData.name}
											{" "}
											{this.state.deceasedData.surname}
										</label>
										<label
											dangerouslySetInnerHTML={{
												__html: this.state.deceasedData.aphorism,
											}}
										/>
										<label>
											{this.state.deceasedData.dateOfBirth}
											-
											{this.state.deceasedData.dateOfDeath}
										</label>
									</div>
								</div>
								{this.state.formError.nameTo && !this.state.form.nameTo && (
									<label style={{ color: "red" }}>
										Scegli un defunto per proseguire
									</label>
								)}
								<TextField
									className="activate-field"
									style={{ display: "none" }}
									name="deceased-name"
									value={this.state.form.nameTo}
									onChange={(event) => {
										event.preventDefault();
										// this.setAndValidateForm(event.target.value, 'nameTo')
									}}
									helperText={
										this.state.formError.nameTo ? "Campo obbligatorio" : ""
									}
									error={this.state.formError.nameTo}
								/>
								<input
									style={{ display: "none" }}
									name="csrf_token"
									value={this.state.csrf_token}
								/>
								<TextField
									className="activate-field"
									style={{ display: "none" }}
									value={this.state.form.surnameTo}
									name="deceased-surname"
									onChange={(event) => {
										event.preventDefault();
										// this.setAndValidateForm(event.target.value, 'surnameTo')
										// this.setAndValidateForm(event.target.value, 'surnameTo')
									}}
									helperText={
										this.state.formError.surnameTo ? "Campo obbligatorio" : ""
									}
									error={this.state.formError.surnameTo}
								/>
								<TextField
									className="activate-field"
									style={{ display: "none" }}
									value={this.state.form.qrCodeN}
									name="deceased-id"
								/>
								<CkEditorCustom
									ckeditor
									title="Dedica da lasciare"
									onChange={(editor) => {
										const temp = this.state.form;
										temp.dedic = editor.getData();
										this.setState({ form: temp });
									}}
									data={this.state.form.dedic}
									error={this.state.formError.dedic}
								/>
								<TextField
									className="text-area"
									id="outlined-multiline-static"
									label="Dedica da lascire"
									style={{ display: "none" }}
									value={this.state.form.dedic}
									multiline
									name="dedic"
									rows={8}
									variant="outlined"
									onChange={(event) => {
										this.setAndValidateForm(event.target.value, "dedic");
									}}
									helperText={
										this.state.formError.dedic ? "Campo obbligatorio" : ""
									}
									error={this.state.formError.dedic}
								/>
								<input
									style={{ display: "none" }}
									name="dedic-type"
									value={purchaseType}
								/>
								<TextField
									className="activate-field"
									name="dedic-sign"
									label="Firma la tua dedica (Facoltativo)"
								/>
								{purchaseType
								&& (purchaseType.indexOf("foto") > 0
									|| purchaseType.indexOf("video") > 0) && (
									<div>
										{purchaseType.indexOf("foto") > 0 ? (
											<div className="file-upload">
												<label
													className="pointer"
													htmlFor="photo-dedic"
													color="blue"
													title=""
												>
													<p className="pointer-label">
														{" "}
														{this.state.fileName
														|| "Carica foto (Max 15MB per foto)"}
													</p>
												</label>
											</div>
										) : (
											<div className="file-upload">
												<label
													className="pointer"
													htmlFor="dedic-video-upload"
													color="blue"
													title=""
												>
													<p className="pointer-label">
														{" "}
														{this.state.videoName
														|| "Carica video (Max 15MB)"}
													</p>
												</label>
											</div>
										)}
										{this.state.photod && (
											<div className="is-relative">
												<img
													style={{
														maxHeight   : 300,
														margin      : "auto",
														marginTop   : 20,
														paddingLeft : 20,
														position    : "relative",
													}}
													src={this.state.photod}
												/>
												<RemoveCircleOutline
													className="remove-photo"
													onClick={() => {
														this.setState({
															fileName : "",
															photod   : null,
															erased   : true,
														});
														document.getElementById(
															"photo-dedic",
														).value = null;
													}}
												/>
											</div>
										)}
										{(this.state.photoError || this.state.videoError) && (
											<label style={{ color: "red" }}>
												Carica almeno un file
											</label>
										)}
									</div>
								)}
								<FormControlLabel
									style={{ marginTop: 20 }}
									value={this.state.checkBox}
									className="activate-checkbox"
									control={(
										<Checkbox
											color="primary"
											name="notification-flag"
											id="notification-flag"
											value={this.state.checkBox}
											onChange={(event, checked) => this.setState({ checkBox: checked })}
										/>
									)}
									label="Spunta per inviare una notifica agli utenti"
								/>
								&nbsp;
								<div className="bottom-activate-form">
									<button
										className="activate-submit"
										type="submit"
										onClick={(event) => {
										}}
									>
										Procedi
									</button>
								</div>
								{purchaseType && purchaseType.indexOf("video") > 0 && (
									<VideoInput
										name="dedic"
										setVideoName={(name) => {
											this.setState({ videoName: name });
										}}
									/>
								)}
								{purchaseType && purchaseType.indexOf("foto") > 0 && (
									<PhotoInput
										name="dedic"
										setFileName={(name, photo) => {
											this.setState({ fileName: name });
											readURL(photo)
												.then((data) => this.setState({ photod: data }));
										}}
									/>
								)}
							</form>
						</div>
					</div>
				</div>
			</>
		);
	}

	send(formdata) {
		let { formError } = this.state;
		let error         = false;
		// eslint-disable-next-line no-restricted-syntax
		for (const property in this.state.form) {
			if (
				property !== "province"
				&& property !== "address"
				&& property !== "city"
				&& property !== "location"
				&& property !== "region"
				&& property !== "civic"
				&& property !== "route"
				&& property !== "cap"
				&& property !== "complete_address"
				&& property !== "CF"
			) {
				formError = validate(this.state.form[property], property, formError);
				error     = error || formError[property];
			}
		}
		if (purchaseType.indexOf("foto") > 0) {
			error = error || !this.state.fileName;
			this.setState({ photoError: !this.state.fileName });
		}
		if (purchaseType.indexOf("video") > 0) {
			error = error || !this.state.videoName;
			this.setState({ videoError: !this.state.videoName });
		}
		if (!error) {
			getCsrf()
				.then((csrf) => {
					this.setState({ csrf_token: csrf });
					formdata.set("csrf_token", csrf);
					this.setState({ uploading: true });
					this.state.checkBox && formdata.set("notification-flag", true);
					requests("sendform", "dedicAdmin", formdata)
						.then((data) => {
							this.setState({ uploading: false });
							if (data.response === "success") {
								// alert('success')
								this.setState({ successModal: true });
								// this.setState({orderId: data.orderid})
							} else {
								this.setState({
									feedbackModal     : true,
									feedbackModalText : data.message,
								});
							}
						});
				});
		}
		// this.setState({modal: true})
	}

	setAndValidateForm(data, attribute) {
		const { form }    = this.state;
		let { formError } = this.state;
		form[attribute]   = data;
		this.setState({ form });
		formError = validate(data, attribute, formError);
		this.setState({ formError });
	}

	successModal() {
		let close = true;
		return (
			<div
				className="modal-bg"
				onClick={() => {
					if (close) {
						this.setState({ successModal: false });
						this.props.history.push("admin-panel");
					} else {
						close = !close;
					}
				}}
			>
				<div
					className="inner"
					onClick={() => {
						close = false;
					}}
				>
					<Typography style={{ fontSize: 17 }}>Successo</Typography>
					<Typography style={{
						fontSize  : 12,
						marginTop : 15,
					}}
					>
						Il dono verra' caricato al piu' presto
					</Typography>
					<div
						style={{
							flexDirection  : "row",
							display        : "flex",
							justifyContent : "space-between",
						}}
					>
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								window.location.reload();
							}}
						>
							Aggiungine un altro
						</Button>
						<Button
							color="primary"
							style={{ marginLeft: 20 }}
							onClick={() => {
								this.setState({ successModal: false });
								this.props.history.push("admin-panel");
							}}
							variant="contained"
						>
							Chiudi
						</Button>
					</div>
				</div>
			</div>
		);
	}
}

function getCsrf() {
	return new Promise((result, err) => Axios.get(`${baseURL}api/auth/securitytoken`)
		.then((resolve) => {
			if (resolve != null) {
				result(resolve.data._csrf);
			} else {
				result({ DATA: "NO DATA" });
			}
		})
		.catch((err) => result({ err })));
}

export default AddGiftAdmin;
