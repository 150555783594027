import { Button, CircularProgress, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import HttpServices, { baseURL } from '../../Services/HttpServices';

function FeedbackModal(props) {
	let close = true;
	const [image, setImage] = useState(null);
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		setLoading(true);
		if (props.ID && props.deceasedObituaryCard) {
			HttpServices('get', 'deceasedObituaryCard', props.ID).then(data => {
				setLoading(false);
				if (data.data?.indexOf('<div style=') < 0) setImage(data.data);
				else setImage(null);
			});
		} else setLoading(false);
	}, [props.ID, props.deceasedObituaryCard]);
	return (
		<div
			className="modal-bg"
			style={{ zIndex: 40000 }}
			onClick={() => {
				if (close) {
					props.close();
				} else close = !close;
			}}
		>
			<div
				className="inner"
				style={{ width: props.deceasedObituaryCard && 'auto' }}
				onClick={() => {
					close = false;
				}}
			>
				<Typography style={{ fontSize: 18, marginBottom: 0 }}>{props.result}</Typography>
				<Typography style={{ fontSize: 14, marginTop: 15, marginBottom: 10 }}>{props.label}</Typography>
				{loading ? (
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						<CircularProgress />
					</div>
				) : (
					<div
						style={{
							flexDirection: 'row',
							display: 'flex',
							justifyContent: 'center',
							flexWrap: props.condolenceIndication && 'wrap'
						}}
					>
						{props.cardModal && (
							<button
								className="modal-button  flex-1-mobile"
								style={{ minWidth: 'max-content', color: 'black' }}
								onClick={e => {
									e.preventDefault();
									props.cardModal();
									props.close();
								}}
							>
								QR Formato A4
							</button>
						)}
						&nbsp;
						{props.cardGridModal && (
							<button
								className="modal-button  flex-1-mobile"
								style={{ minWidth: 'max-content', color: 'black' }}
								onClick={e => {
									e.preventDefault();
									props.cardGridModal();
									props.close();
								}}
							>
								QR in Griglia
							</button>
						)}
						{props.deceasedObituaryCard && (
							<>
								&nbsp;
								<a
									href={image ? `${baseURL}api/obituaryCard/create/${props.ID}` : '#'}
									download={`Manifesto+Cartoncino QR-${props.deceasedName}-${props.ID}.jpg`}
									className="modal-button  flex-1-mobile"
									style={{
										minWidth: 'max-content',
										background: !image && 'grey',
										fontSize: '0.85em',
										paddingTop: 7,
										paddingLeft: 8,
										color: 'black',
										paddingRight: 8
									}}
									target="_blank"
								>
									Manifesto + Cartoncino Qr
								</a>
							</>
						)}
						{props.condolenceIndication && (
							<>
								&nbsp;
								<button
									className="modal-button"
									style={{ minWidth: 'max-content', color: 'black' }}
									onClick={e => {
										e.preventDefault();
										const link = document.createElement('a');
										link.href = `${baseURL}api/obituaryCard/indication/${props.ID}`;

										link.setAttribute('download', `Indicazioni-${props.ID}.pdf`); // or any other extension
										link.setAttribute('target', `_blank`); // or any other extension
										document.body.appendChild(link);
										link.click();
										// props.cardGridModal();
										props.close();
									}}
								>
									Indicazioni per le condoglianze
								</button>
								&nbsp;
							</>
						)}
						<button
							className="modal-button flex-1-mobile"
							style={{ color: props.deceasedObituaryCard && 'black' }}
							onClick={e => {
								e.preventDefault();
								props.close();
							}}
						>
							Chiudi
						</button>
						{props.isPartnerAdd && (
							<>
								&nbsp;
								<button
									className="modal-button  flex-1-mobile"
									onClick={e => {
										e.preventDefault();
										props.isPartnerAdd();
									}}
								>
									Vai al defunto
								</button>
							</>
						)}
					</div>
				)}
			</div>
		</div>
	);
}

FeedbackModal.propTypes = {
	result: PropTypes.string.isRequired,
	label: PropTypes.string,
	close: PropTypes.func.isRequired
};
export default FeedbackModal;
