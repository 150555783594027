import React, { Fragment, useState } from 'react';
import { Switch } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { useHistory } from 'react-router';
import PhoneNumber from '../../FormComponents/PhoneNumber';
import requests from '../../../Services/HttpServices';
import FeedbackModal from '../../FormComponents/FeedbackModal';
import validate, { emailValidation } from '../../../formValidationService';

export default function ForgotUsername() {
	const [recoverType, setRecoverType] = useState('telephone');
	const [email, setEmail] = useState(null);
	const [phone, setPhone] = useState(null);
	const [phoneError, setPhoneError] = useState(false);
	const [emailError, setEmailError] = useState(false);
	const [feedbackModal, setFeedbackModal] = useState(false);
	const [modalText, setModalText] = useState(false);
	const [modalResult, setModalResult] = useState(false);
	const history = useHistory();

	function sendData() {
		let error;
		const bodyData = {
			'recover-email': email,
			'recover-telephone': phone,
			'recover-type': recoverType
		};
		if (recoverType === 'email' && emailValidation(email)) {
			setEmailError(true);
			error = true;
		}
		if (recoverType !== 'email' && (phone ? phone.length < 12 : false)) {
			setPhoneError(true);
			error = true;
		}
		!error &&
			requests('post', 'recoverUserData', bodyData).then(result => {
				if (result.response === 'success') {
					setModalResult('Successo');
					setModalText(
						"Abbiamo inviato l'username collegato al vostro account attraverso il metodo di contatto da lei scelto"
					);
					setFeedbackModal(true);
				} else {
					setModalResult('Errore');
					setModalText(result.message);
					setFeedbackModal(true);
				}
			});
	}

	return (
		<>
			<div id="recupera-account" className="login-page columns is-mobile is-centered">
				{feedbackModal && (
					<FeedbackModal
						result={modalResult}
						close={() => {
							setFeedbackModal(false);
							modalResult === 'Successo' && history.push('/login');
						}}
						label={modalText}
					/>
				)}
				<form
					onSubmit={e => {
						e.preventDefault();
						sendData();
					}}
					className="column media-fix"
				>
					<label style={{ marginBottom: 12 }} className="death-data">
						Recupera nome utente
					</label>
					<p style={{ marginBottom: 0 }}>Inserisci un metodo di contatto utilizzato dal vostro account</p>
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							width: ' 100%',
							marginBottom: 12,
							alignItems: 'center'
						}}
					>
						<span>Telefono</span>
						<Switch
							color="primary"
							onChange={(event, checked) => {
								if (!checked) setRecoverType('telephone');
								else setRecoverType('email');
							}}
						/>
						<span>Email</span>
					</div>
					{recoverType === 'email' ? (
						<TextField
							fullWidth
							name={'recover-' + 'email'}
							label="Inserisci la tua mail"
							error={emailError}
							onChange={e => {
								setEmailError(emailValidation(e.target.value));
								setEmail(e.target.value);
							}}
							variant="outlined"
						/>
					) : (
						<PhoneNumber
							notError
							name="telephone"
							setValue={data => {
								setPhone(data);
								if (data && data.length > 6 && data.length < 10) {
									setPhoneError(true);
								} else setPhoneError(false);
								// this.setState({form});
							}}
							error={phoneError}
						/>
					)}
					{emailError && recoverType === 'email' && <label style={{ color: 'red' }}>Email non valida</label>}
					{phoneError && recoverType !== 'email' && (
						<label style={{ color: 'red' }}>Telefono non valido</label>
					)}

					<div className="field is-clearfix" style={{ marginTop: 15 }}>
						<button
							type="submit"
							className="button is-primary is-outlined is-pulled-right"
							// onClick={this.login}
						>
							Procedi
						</button>
					</div>
				</form>
			</div>
		</>
	);
}
