import React, { Component, Fragment } from 'react';
import './Login.scss';
import { Redirect } from 'react-router';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { VisibilityOff } from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';
import withContext from '../../../WithContextFile';
import users from '../../../assets/Stab/Users';
import HttpService from '../../../Services/HttpServices';
import requests from '../../../Services/HttpServices';
import FeedbackModal from '../../FormComponents/FeedbackModal';

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			username: '',
			password: '',
			feedbackModal: false,
			modalResult: '',
			modalText: ''
		};
	}

	componentDidMount() {}

	handleChange = e => {
		this.setState({ [e.target.name]: e.target.value, error: '' });
	};

	onSuccessLogin(data) {
		const address = {};
		sessionStorage.setItem('user', JSON.stringify(data.session));
		sessionStorage.setItem('User', JSON.stringify(data.session));
		sessionStorage.setItem('ExpiringMemorial', JSON.stringify(data.expiring_memorial || false));
		let userId = null;
		try {
			userId = data.session.userdata.user_id;
		} catch (e) {
			userId = null;
		}
		let time;
		try {
			time = data.session.userdata.notificationPreference;
		} catch (e) {
			time = null;
		}
		if (userId) {
			requests('get', 'contactData', userId).then(result => {
				const { data } = result;
				if (data.response === 'success') {
					sessionStorage.setItem(
						'userContactPreference',
						JSON.stringify({
							zone: data.data,
							time
						})
					);
				}
				// sessionStorage.setItem('userContactPreference', JSON.stringify({
				//     zone: [{region: 12}, {
				//         region: 3,
				//         province: 5
				//     }, {region: 5, province: 13, location: 1447}],
				//     time:'weekly'
				// }))
			});
		}
		if (data.session.userdata.addresses && data.session.userdata.addresses.length > 0) {
			address.address = data.session.userdata.addresses[data.session.userdata.addresses.length - 1].address;
			address.number = data.session.userdata.addresses[data.session.userdata.addresses.length - 1].number;
			address.region = data.session.userdata.addresses[data.session.userdata.addresses.length - 1].region;
			address.city = data.session.userdata.addresses[data.session.userdata.addresses.length - 1].location;
			address.province = data.session.userdata.addresses[data.session.userdata.addresses.length - 1].province;
			address.cap = data.session.userdata.addresses[data.session.userdata.addresses.length - 1].cap;
			address.nationality = data.session.userdata.addresses[data.session.userdata.addresses.length - 1].nationID;
			address.complete_address =
				data.session.userdata.addresses[data.session.userdata.addresses.length - 1].completeAddress;
		}
		sessionStorage.setItem('UserAddress', JSON.stringify(address));
		// let route = ''

		// if (data.session.userdata.permissions.platformAccess == 1) route = 'admin-panel'
		// if (data.session.userdata.permissions.platformAccess == 0) route = 'client-panel'
		// if (data.session.userdata.permissions.platformAccess == 2) route = 'partner-panel'
		window.scrollTo(0, 0);
		this.props.history.push('/home?name=&region=&province=&location=&cap=&page=0#newLogin');
	}

	onErrLogin(data) {
		requests('get', 'logout').then(data2 => {
			sessionStorage.removeItem('user');
			sessionStorage.removeItem('User');
			sessionStorage.removeItem('UserAddress');
			sessionStorage.removeItem('ExpiringMemorial');
			alert(
				'Ci sono problemi a reperire le informazioni del vostro account, si prega di contattare gli amministratori di sistema, verra` reinderizzato al form per i contatti'
			);
			this.props.history.push('contact');
		});
	}

	login = e => {
		e.preventDefault();
		const loggedIn = false;
		const { username, password } = this.state;
		if (!username || !password) {
			return this.setState({ error: 'Riempi tutti i campi' });
		}
		this.setState({ loading: true });

		// eslint-disable-next-line array-callback-return
		const bodyData = {
			email: username,
			password,
			_submitLogin: 1
		};
		HttpService('POst', 'login', bodyData)
			.then(data => {
				if (data.result !== 'fail') {
					if (data.session.userdata.permissions) {
						this.onSuccessLogin(data);
					} else {
						this.onErrLogin(data);
					}
				} else {
					this.setState({
						feedbackModal: true,
						modalResult: 'Errore',
						modalText: data.message
					});
				}
			})
			.finally(() => this.setState({ loading: false }))
			.catch(err => {
				this.onErrLogin();
			});
	};

	render() {
		return !this.props.context.user ? (
			<>
				<div
					style={{ minHeight: window.innerHeight - 100 }}
					className="login-page columns is-mobile is-centered login-media-fix"
				>
					{this.state.feedbackModal && (
						<FeedbackModal
							result={this.state.modalResult}
							close={() => {
								this.setState({ feedbackModal: false });
								// this.setState({name: '',number:'',selectedValue:''})
							}}
							label={this.state.modalText}
						/>
					)}
					<form
						onSubmit={e => {
							e.preventDefault();
							this.login(e);
						}}
						className="column media-fix"
					>
						<label className="death-data">Benvenuto</label>
						<p style={{ marginBottom: 8 }}>
							Accedi al tuo account per velocizzare le funzioni base e avere la possibilita' di gestire
							facilmente tutti i dati
						</p>
						{!this.state.loading ? (
							<>
								<TextField
									onChange={this.handleChange}
									name="username"
									className="login-form"
									label="Nome utente"
									variant="outlined"
								/>
								<div
									id="bottom-login"
									style={{
										marginTop: -8
									}}
								>
									<Link to="/recover-user">
										<label style={{ textAlign: 'center', paddingTop: 12 }} id="click-here">
											Non ricordi il nome utente?
										</label>
									</Link>
								</div>
								<TextField
									onChange={this.handleChange}
									type={!this.state.visible && 'password'}
									InputProps={{
										endAdornment: this.state.visible ? (
											<VisibilityIcon
												style={{ cursor: 'pointer', color: 'black' }}
												onClick={() => this.setState({ visible: false })}
											/>
										) : (
											<VisibilityOff
												style={{ cursor: 'pointer', color: 'black' }}
												onClick={() => this.setState({ visible: true })}
											/>
										)
									}}
									name="password"
									className="login-form "
									label="Password"
									variant="outlined"
								/>
								<div style={{ marginTop: -7 }} id="bottom-login">
									<Link to="/forgot-password">
										<label style={{ textAlign: 'center' }} id="click-here">
											Password dimenticata?
										</label>
									</Link>
								</div>
							</>
						) : (
							<div
								style={{
									height: 80,
									width: '100%',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center'
								}}
							>
								<CircularProgress />
							</div>
						)}
						{this.state.error && (
							<div className="has-text-danger" style={{ marginBottom: 13 }}>
								{this.state.error}
							</div>
						)}
						{/* <div id={'bottom-login'} style={{justifyContent: "start"}}>
                            <Link to={'/registrazione'}>
                                <label style={{textAlign: "left", display: "flex", color: "#e1802b"}} id={'click-here'}>
                                    <p
                                        style={{color: "black", margin: 0, width: "auto"}}>Non sei
                                        iscritto? </p>&nbsp; Clicca qui</label>
                            </Link>
                        </div> */}
						<div className="field is-clearfix" style={{ marginTop: 0 }}>
							<button
								className="button is-primary is-outlined is-pulled-right flex-1"
								// onClick={this.login}
							>
								Accedi
							</button>
						</div>
					</form>
				</div>
			</>
		) : (
			<Redirect to="/products" />
		);
	}
}

export default withContext(Login);
