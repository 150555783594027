/* eslint-disable no-shadow */
import PhoneInput, { getCountryCallingCode, parsePhoneNumber } from "react-phone-number-input";
import React, { useEffect, useState } from "react";
import { InfoRounded } from "@material-ui/icons";
import InfoModal from "./InfoModal";

export default function PhoneNumber(props) {
	const [value, setValue]             = useState("+105-");
	const [phoneValue, setPhoneValue]   = useState("");
	const [firstClick, setFirstClick]   = useState(null);
	const [country, setCountry]         = useState(39);
	const [countryCode, setCountryCode] = useState(null);
	const [showInfo, setShowInfo]       = useState(null);
	const [classOrange, setClassOrange] = useState(null);
	const [init, setInit]               = useState(null);
	useEffect(() => {
		if (props.initValue && props.initValue !== value && !init) {
			const data = props.initValue;
			if (props.initValue[0] === "+" && props.initValue.indexOf("-") === -1) {
				setCountry(props.initValue.slice(1, 3));
				setPhoneValue(props.initValue.slice(4, props.initValue.length - 1));
			} else {
				const split = props.initValue.split("-");
				setCountry(split[0].replace("+", ""));
				setPhoneValue(split[1].replace("-", ""));
			}
			const phoneNumber = parsePhoneNumber(props.initValue.replace("-", ""));
			if (phoneNumber && phoneNumber.country) setCountryCode(phoneNumber.country);
			else {
				try {
					setCountryCode(phoneNumber.metadata.country_calling_codes[phoneNumber.countryCallingCode][0]);
				} catch (e) {
					console.log({ e });
				}
			}
			// setValue(data);
			setInit(true);
		}
	});
	useEffect(() => {
		if (props.bookValue === "") {
			setPhoneValue("");
		}
	}, [props.bookValue]);
	useEffect(() => {
		setValue(`+${country}-${phoneValue}`);
		props.setValue && props.setValue(`+${country}-${phoneValue}`);
	}, [phoneValue, country]);
	window.onclick = (event) => {
		if (!firstClick) {
			if (classOrange === "orange") setClassOrange("a");
		} else {
			setFirstClick(false);
		}
	};
	useEffect(() => {
		if (classOrange === "a") {
			if (props.onBlur) props.onBlur(phoneValue.length);
		}
	}, [classOrange]);
	return (
		<div
			onClick={() => {
				setFirstClick(true);
				setClassOrange("orange");
			}}
			className="phoneNumberfixmemorial"
			style={{
				alignSelf    : "center",
				marginBottom : props.modify ? 8 : 20,
				width        : props.width || "100%",
				position     : "relative",
			}}
		>
			<PhoneInput
				placeholder="Enter phone number"
				defaultCountry={countryCode || "IT"}
				country={countryCode || "IT"}
				international
				withCountryCallingCode
				// countryCallingCodeEditable={false}
				disabled={props.disabled}
				onCountryChange={(country) => {
					setCountry(getCountryCallingCode(country));
				}}
				onChange={(event, value) => {}}
			/>
			{props.infoLabel && (
				<InfoRounded
					color="primary"
					style={{
						position        : "absolute",
						top             : -11,
						right           : 10,
						backgroundColor : "white",
					}}
					onClick={() => setShowInfo(true)}
				/>
			)}
			<label id="phone-label" className={`${classOrange}  ${props.error && props.memorialBook && "force-red"}`}>
				Numero di telefono
			</label>

			<div className={`reg-phone ${classOrange} ${props.error && props.memorialBook && "force-red"}`}>
				<label className="label-country">
					+
					{country}
				</label>
				<input
					style={{ float: "right", marginTop: 1 }}
					onBlur={() => {
						if (classOrange === "orange") setClassOrange("a");
						if (props.onBlur) props.onBlur(phoneValue.length);
					}}
					value={phoneValue}
					onChange={(e) => {
						if (e.target.value.length < 13) {
							if (e.target.value.match(/[0-9]*$/)) setPhoneValue(e.target.value || "");
							else if (e.target.value[0] === "-") {
								setPhoneValue(e.target.value.replace("-", ""));
							}
						} else e.preventDefault();
					}}
					className="PhoneInputInput2"
				/>
			</div>
			{props.error && props.memorialBook && (
				<label id="phone-error" className={classOrange}>
					Numero di telefono non valido!
				</label>
			)}
			<input value={value} name={props.name} style={{ display: "none" }} id={props.name} />
			{showInfo && <InfoModal label={props.infoLabel} handleClose={() => setShowInfo(false)} />}
		</div>
	);
}
