import React, { Component, Fragment } from 'react';

class PostActivate extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<>
				<div style={{ height: window.innerHeight - 250 }}>
					<p
						className="death-data"
						style={{
							alignSelf: 'center',
							marginTop: 20,
							marginBottom: 20,
							textAlign: 'center'
						}}
					>
						Verifica informazioni contatto
					</p>
					<p style={{ textAlign: 'center' }}>
						Le abbiamo inviato le istruzioni necessarie per procedere al caricamento del defunto attraverso
						il contatto predefinito da lei segnalato
					</p>
				</div>
			</>
		);
	}
}

export default PostActivate;
