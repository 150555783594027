import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Spinner } from 'react-activity';
import { Button, Chip, Collapse, Tooltip, Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { useHistory } from 'react-router';
import Axios from 'axios';
import listner from 'reactjs-eventemitter';
import { ArrowDropDown, ArrowDropUp, RemoveCircleOutline, Repeat } from '@material-ui/icons';
import { BigPlayButton, Player, LoadingSpinner } from 'video-react';
import moment from 'moment';
import FileUpload10 from '../../FormComponents/FileUpload10';
import FileNamesComponent, { readURL } from '../../FormComponents/FileNamesComponent';
import VideoInput from '../../FormComponents/VideoInput';
import requests, { baseURL } from '../../../Services/HttpServices';
import CkEditorCustom from '../../FormComponents/CkEditor2';
import FeedbackModal from '../../FormComponents/FeedbackModal';
import AreYouSureModalGeneral from '../../FormComponents/AreYouSureModalGeneral';

let globalData = {};
let playerState = -1;
const handlesubscribePlayer = (state, setPlayerState) => {
	if (state.readyState < 4) {
		playerState = parseInt(state.readyState);
		setPlayerState(parseInt(state.readyState));
	} else setPlayerState(4);
};
const fileIndex = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
export default function ModifyMemorialAdmin(props) {
	const [uploading, setUploading] = useState(null);
	const [load, setLoad] = useState(false);
	const [bio, setBio] = useState('');
	const [deceasedId, setdeceasedId] = useState('');
	const [memorialData, setMemorialData] = useState(null);
	const [bioError, setBioError] = useState('');
	const [removedVideo, setRemovedVideo] = useState(null);
	const [files, setFiles] = useState([]);
	const [expired, setExpired] = useState(false);
	const [fileVideoName, setFileVideoName] = useState('');
	const [openModalFile, setOpenModalFile] = useState('');
	const [percentage, setPercentage] = useState('');
	const [video, setVideo] = useState({});
	const [removeVideo, setRemoveVideo] = useState(false);
	const [removeSection, setRemoveSection] = useState(false);
	const [fileError, setFileError] = useState('');
	const [settedPlayer, setSettedPlayer] = useState(false);
	const [modalResult, setModalResult] = useState('');
	const [modalText, setModalText] = useState('');
	const [loaded, setLoaded] = useState(false);
	const [imageToRemove, setImageToRemove] = useState(false);
	const [feedbackModal, setFeedbackModal] = useState('');
	const [playerState, setPlayerState] = useState(-1);
	const [choiceModal, setChoiceModal] = useState(false);
	const [opFeedback, setOpFeedBack] = useState(false);
	const [opFeedbackText, setOpFeedBackText] = useState(false);
	const [opFeedbackResult, setOpFeedBackResuly] = useState(false);
	const history = useHistory();
	const player = useRef();

	useEffect(() => {
		listner.events.upload6 = [];
		listner.events.upload6.length < 1 &&
			listner.subscribe('upload6', callback => {
				setPercentage(callback);
			});
	});
	/*
  * requests("post", "removeMemorial", {
                      deceasedID: deceasedId,
                    }); */
	useEffect(() => {
		if (!settedPlayer || playerState === -2) {
			player.current &&
				player.current.subscribeToStateChange(state => handlesubscribePlayer(state, setPlayerState));
			setSettedPlayer(true);
		}
		if (!video) {
			setSettedPlayer(false);
			setPlayerState(-1);
		}
	}, [video]);

	function setInitData(memorialData) {
		setBio(memorialData?.bio || '');
		props.forceLoad();
	}

	useEffect(() => {
		setdeceasedId(props.deceasedID || history.location.search?.split('=')[1]);
	}, [history.location.search, props.deceasedID]);
	useEffect(() => {
		memorialGetData();
	}, [deceasedId]);
	function memorialGetData() {
		requests('get', 'deceasedData', deceasedId || -1)
			.then(data => {
				if (data.data.response === 'success' || data.data.result === 'success') {
					setMemorialData(data.data.memorial);
					const media = [];
					if (data.data?.memorial?.expirationDate)
						if (
							moment(data.data?.memorial?.expirationDate).toDate().getTime() <
							moment(new Date()).toDate().getTime()
						)
							setExpired(true);
					data.data.memorialMedia?.length > 0 &&
						data.data.memorialMedia.map((e, i) => {
							let tempFile = {};
							if (e.type === 'photo') {
								tempFile = {
									ID: e.ID,
									url: e.file,
									image: e.file,
									type: e.type,
									id: fileIndex.shift()
								};
								media.push(tempFile);
							} else {
								setVideo(e);
							}
						});
					setInitData(data.data.memorial);
					setLoaded(true);
					setFiles(media);
				}
			})
			.finally(() => {
				props.forceLoad && props.forceLoad();
			});
	}
	return (
		<>
			{openModalFile && fileMissingModal(globalData)}
			{uploading && (
				<div className="div-upload">
					<Spinner size={100} />
					<Typography className="label-upload">
						Attendi il caricamento dei files {parseInt(parseFloat(percentage) * 100)}%
					</Typography>
				</div>
			)}
			<div className="contact-container " style={{ padding: 20 }}>
				{feedbackModal && (
					<FeedbackModal
						result={modalResult}
						close={() => {
							setFeedbackModal(false);
							modalResult === 'Successo' && window.location.reload();
						}}
						label={modalText}
					/>
				)}
				{choiceModal && (
					<AreYouSureModalGeneral
						text="Sei sicuro di voler eliminare il memorial?"
						closeHandler={() => setChoiceModal(false)}
						accept={() => {
							requests('post', 'removeMemorial', {
								deceasedID: deceasedId
							}).then(data => {
								if (data.response === 'success') {
									setOpFeedBack(true);
									setOpFeedBackResuly('Successo');
									setOpFeedBackText('Memorial eliminato con successo');
								} else {
									setOpFeedBack(true);
									setOpFeedBackResuly('Errore');
									setOpFeedBackText(data.message);
								}
							});
						}}
					/>
				)}
				{opFeedback && (
					<FeedbackModal
						label={opFeedbackText}
						close={() => {
							if (opFeedbackResult === 'Successo') history.push('/admin-panel#pages');
							setOpFeedBack(false);
						}}
						result={opFeedbackResult}
					/>
				)}
				<div className="memorial-container memorial-container-activate">
					<div className="activate-form-div" style={{ minHeight: 'auto', position: 'relative' }}>
						{expired && memorialData?.expirationDate && (
							<Chip
								className="memorial-chip"
								label={`Memorial scaduto il ${moment(memorialData.expirationDate).format(
									'DD/MM/YYYY'
								)}`}
								style={{
									position: 'absolute',
									right: 20,
									top: 20,
									color: 'white',
									backgroundColor: 'black'
								}}
							/>
						)}
						{!expired && memorialData?.expirationDate && (
							<Chip
								className="memorial-chip"
								label={`Scade il ${moment(memorialData.expirationDate).format('DD/MM/YYYY')}`}
								style={{
									position: 'absolute',
									right: 20,
									top: 20,
									color: 'white',
									backgroundColor: 'green'
								}}
							/>
						)}
						<label className="contact-title">Modifica memorial</label>
						<form
							id="#form"
							className="activate-form"
							onSubmit={event => {
								event.preventDefault();
								const formData = new FormData(event.target);
								const formKeys = formData.keys();
								const object = {};
								imageToRemove &&
									imageToRemove.length > 0 &&
									formData.set('image-to-remove', JSON.stringify(imageToRemove));
								removeVideo && formData.set('remove-video', removeVideo);
								formData.set('deceased-id', deceasedId);
								formData.set('memorial-id', memorialData.ID);
								formData.forEach(function (value, key) {
									object[key] = value;
								});
								send(formData);
							}}
						>
							{loaded && (
								<CkEditorCustom
									title="Biografia defunto"
									onChange={editor => {
										setBio(editor.getData());
									}}
									initData={bio || ''}
									error={bioError && !bio}
								/>
							)}
							<TextField
								className="text-area"
								value={bio}
								id="outlined-multiline-static"
								name="deceased-bio"
								label="Biografia"
								multiline
								style={{ display: 'none' }}
								rows={8}
								variant="outlined"
								onChange={event => {
									setBio(event.target.value);
								}}
								helperText={!bio && bioError ? 'Campo obbligatorio' : ''}
								error={bioError && !bio}
							/>
							<div>
								<FileUpload10
									setFileIndex={() => {
										fileIndex.shift();
										setLoad(!load);
									}}
									setFile={temp => setFiles(temp)}
									name="memorial"
									files={files}
								/>
								<div className="file-upload">
									<label
										className="pointer"
										htmlFor={`memorial-file${fileIndex[0]}`}
										color="blue"
										title=""
									>
										<p className="pointer-label">
											{files.length + 1 > 10
												? 'Tutti i file caricati'
												: `Carica immagine ${files.length + 1} di 10` + ` (Max 15MB per foto)`}
										</p>
									</label>
								</div>
								<FileNamesComponent
									setRemoved={DATA => setImageToRemove(DATA)}
									modifyMemorial
									name="memorial"
									files={files}
									setFile={temp => setFiles(temp)}
									addFileIndex={data => {
										if (files.length < 10) {
											const media = files;
											data.id = fileIndex.shift();
											media.push(data);
											setFiles(media);
											setLoad(!load);
										} else {
											alert('hai già caricato 10 immagini');
										}
									}}
									setFileIndex={(temp, i) => {
										fileIndex.unshift(temp[i].id);
										setLoad(!load);
									}}
								/>
							</div>
							<div>
								<div
									className="file-upload"
									style={{
										display: 'flex',
										alignItems: 'center',
										flexDirection: 'row'
									}}
								>
									<label className="pointer" htmlFor="memorial-video-upload" color="blue" title="">
										<p className="pointer-label">
											{video?.file ? 'Cambia video(Max 15MB)' : 'Carica Video (Max 15MB)'}
										</p>
									</label>
								</div>
								{video?.file && (
									<div
										style={{
											margin: '10px auto',
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center'
										}}
									>
										<RemoveCircleOutline
											style={{ color: 'red', cursor: 'pointer' }}
											onClick={() => {
												if (video.file?.indexOf('data:video') < 0) {
													setRemoveSection(false);
													setRemovedVideo(video);
												}
												setVideo(null);
												try {
													document.getElementById('memorial-video-upload').value = null;
												} catch (e) {
													console.log(e);
												}
												setRemoveVideo(true);
											}}
										/>
										<Player
											fluid={false}
											width="90%"
											ref={player}
											style={{ margin: 'auto' }}
											height={300}
											playsInline
											poster="/assets/poster.png"
											src={video.file}
										>
											<LoadingSpinner />
											<BigPlayButton position="center" />
										</Player>
									</div>
								)}
								{fileError && !fileVideoName && files.length < 1 && (
									<label style={{ color: 'red' }}> Carica almeno un file</label>
								)}
							</div>
							&nbsp;
							<VideoInput
								name="memorial"
								setVideoName={(videoName, videos) => {
									const temp = videos;
									setPlayerState(-2);

									readURL(videos).then(result => {
										temp.file = result;
										setVideo(temp);
										setRemoveVideo(false);
										if (video.file?.indexOf('data:video') < 0) {
											setRemoveSection(false);
											setRemovedVideo(video);
										}
										setFileVideoName(videoName);
									});
									// eslint-disable-next-line no-unused-expressions
								}}
							/>
							{removedVideo?.file && removedVideo.file.indexOf('data:video') < 0 && (
								<label
									className="is-flex"
									style={{
										alignItems: 'center',
										textAlign: 'center',
										width: '100%',
										cursor: 'pointer',
										justifyContent: 'center',
										marginTop: 16
									}}
									onClick={() => setRemoveSection(!removeSection)}
								>
									Video rimosso
									{removeSection ? <ArrowDropUp /> : <ArrowDropDown />}
								</label>
							)}
							<Collapse in={removeSection}>
								{removedVideo && (
									<div
										style={{
											margin: '10px auto',
											width: 'min-content',
											position: 'relative'
										}}
									>
										<Tooltip title="Ripristina video">
											<Repeat
												style={{ color: 'green' }}
												onClick={event => {
													if (!video) {
														setVideo(removedVideo);
														setRemovedVideo(null);
														setRemoveVideo(false);
													} else alert('Devi rimuovere il video corrente per procedere');
												}}
												className="remove-file-icon"
											/>
										</Tooltip>
										<Player
											fluid={false}
											style={{ margin: 'auto' }}
											height={280}
											playsInline
											poster="/assets/poster.png"
											src={removedVideo?.file}
										>
											<LoadingSpinner />
											<BigPlayButton position="center" />
										</Player>
									</div>
								)}
							</Collapse>
							<div
								style={{
									flexDirection: 'row-reverse',
									justifyContent: 'space-between'
								}}
								className="bottom-activate-form memorial-modify-fix-media"
							>
								<button
									style={{
										backgroundColor: playerState !== -1 && playerState < 4 && 'lightgrey',
										cursor: playerState !== -1 && playerState < 4 && 'unset'
									}}
									disabled={playerState !== -1 && playerState < 4}
									type="submit"
									onClick={e => {
										// e.preventDefault()
										// this.send()
									}}
									className="activate-submit"
								>
									{playerState !== -1 && playerState < 4 ? <Spinner /> : 'Procedi'}
								</button>
								<button
									style={{
										backgroundColor: playerState !== -1 && playerState < 4 ? 'lightgrey' : 'red',
										marginLeft: 0,
										cursor: playerState !== -1 && playerState < 4 && 'unset'
									}}
									disabled={playerState !== -1 && playerState < 4}
									onClick={e => {
										e.preventDefault();
										setChoiceModal(true);
									}}
									className="activate-submit"
								>
									{playerState !== -1 && playerState < 4 ? <Spinner /> : 'Elimina memorial'}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);

	function send(formData) {
		const error = bio === '';
		let fileerror;
		setBioError(error);
		if (!fileVideoName && files.length < 1) {
			fileerror = true;
		}
		globalData = formData;
		if (!error) {
			if (fileerror) {
				setOpenModalFile(true);
			} else getData(formData);
		}
	}

	function fileMissingModal(formdata) {
		let close2 = true;
		return (
			<div
				className="modal-bg"
				onClick={() => {
					if (close2) {
						setOpenModalFile(false);
						// this.props.history.push('admin-panel')
					} else close2 = !close2;
				}}
			>
				<div
					className="inner"
					onClick={() => {
						close2 = false;
					}}
				>
					<Typography style={{ fontSize: 17 }}>Attenzione!!</Typography>
					<Typography style={{ fontSize: 12, marginTop: 15 }}>
						Non hai caricato nessun media, desideri continuare?
					</Typography>
					<div
						style={{
							flexDirection: 'row',
							display: 'flex',
							justifyContent: 'space-between'
						}}
					>
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								setOpenModalFile(false);

								getData(globalData);
							}}
						>
							SI
						</Button>
						<Button
							color="primary"
							style={{ marginLeft: 20 }}
							onClick={() => {
								setOpenModalFile(false);

								// this.props.history.push('admin-panel')
							}}
							variant="contained"
						>
							NO
						</Button>
					</div>
				</div>
			</div>
		);
	}

	function getData(formdata) {
		const formData = new FormData();
		formdata.forEach(function (value, key) {
			if (value.length <= 0) {
			} else if (value.size === 0) {
			} else {
				formData.set(key, value);
			}
		});
		getCsrf().then(data => {
			setUploading(true);
			formData.set('csrf_token', data);
			requests('sendform', 'editMemorial', formData).then(result => {
				setUploading(false);
				if (result.response !== 'fail') {
					setModalResult('Successo');
					setFeedbackModal(true);
					setModalText('Memorial modificato con successo');
				} else if (result.response === 'fail') {
					setModalResult('Errore');
					setFeedbackModal(true);
					setModalText(result.message);
				}
			});
		});
	}

	function getCsrf() {
		return new Promise((result, err) =>
			Axios.get(`${baseURL}api/auth/securitytoken`)
				.then(resolve => {
					if (resolve != null) {
						result(resolve.data._csrf);
					} else result({ DATA: 'NO DATA' });
				})
				.catch(err => {
					return result({ err });
				})
		);
	}
}
