import React, { useState } from "react";
import "./Modal.scss";
import { Typography } from "@material-ui/core";
import requests from "../../Services/HttpServices";
import FeedbackModal from "./FeedbackModal";

export default function AreYouSureGeneral(props) {
	let close = true;
	return (
		<div
			className="modal-bg"
			onClick={() => {
				close ? props.closeHandler() : (close = !close);
			}}
		>
			<div
				className="inner"
				onClick={() => {
					close = false;
				}}
			>
				<Typography style={{ fontSize: 17 }}> Attenzione</Typography>
				<Typography style={{ fontSize: 12, marginTop: 15 }}>{props.text}</Typography>
				<div className="submit-container">
					<div
						onClick={() => {
							props.closeHandler();
						}}
					>
						No
					</div>
					<div
						onClick={() => {
							props.accept && props.accept();
						}}
					>
						Si
					</div>
				</div>
			</div>
		</div>
	);
}
