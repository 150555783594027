import React from "react";
import requests from "./HttpServices";

export function suggestUsername(name, surname) {
	const userName = name.replace(/[^a-zA-Z0-9]/g, "") + surname.replace(/[^a-zA-Z0-9]/g, "");
	return new Promise((resolve) => exist(userName).then((response) => {
		resolve(response);
	}));
}

function exist(value, counter) {
	const userName = value + (counter || "");
	return new Promise((resolve) => {
		requests("get", "userExist", userName).then((data) => {
			if (data.data.response === "success") {
				if (!counter) counter = 1;

				exist(value, counter + 1).then((result) => resolve(result));
			} else resolve(userName);
		});
	});
}
export const MEMORIAL_BOOK_DELAY = 365;
